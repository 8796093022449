
import { mixins } from "vue-class-component";
import { AllocationErrorsMixin } from "@/mixins/allocation-errors-mixin";
import { State, Getter } from 'vuex-class';
import PageTop from '@/components/shared/PageTop.vue';
import { Allocation, OfferOutcomeContext } from '@/store/allocations/types';
import { OrganCodeValue } from '@/store/lookups/types';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { DeceasedDonor } from '@/store/deceasedDonors/types';
import DonorSummary from '@/components/deceasedDonors/DonorSummary.vue';
import DonorStickySummary from '@/components/deceasedDonors/DonorStickySummary.vue';
import LoadingSideNav from '@/components/shared/side-nav/LoadingSideNav.vue';
import LoadingDonorPage from '@/components/shared/LoadingDonorPage.vue';
import SideNavDonorOrgan from '@/components/deceasedDonors/side-nav/SideNavDonorOrgan.vue';
import DeceasedDonorOffers from '@/components/allocations/DeceasedDonorOffers.vue';
import CoordinatorOffers from '@/components/allocations/CoordinatorOffers.vue';
import { EP } from '@/api-endpoints';
import OfferOutcomeModal from '@/components/allocations/_OfferOutcomeModal.vue';
import NationalOrganWaitlistModal from '@/components/shared/NationalOrganWaitlistModal.vue';

@Component({
  components: {
    PageTop,
    DonorSummary,
    SideNavDonorOrgan,
    DonorStickySummary,
    LoadingSideNav,
    LoadingDonorPage,
    DeceasedDonorOffers,
    OfferOutcomeModal,
    CoordinatorOffers,
    NationalOrganWaitlistModal
  },
})
export default class EditDeceasedDonorAllocations extends mixins(AllocationErrorsMixin) {
  // State
  @State(state => state.allocations.selected) private allocation!: Allocation;
  @State(state => state.deceasedDonors.selected) private donor!: DeceasedDonor;

  @Getter('isTransplantCoordinator', { namespace: 'users' }) private isTransplantCoordinator!: boolean;
  @Getter('isSurgicalRecoveryCoordinator', { namespace: 'users' }) private isSurgicalRecoveryCoordinator!: boolean;
  @Getter('clientId', { namespace: 'deceasedDonors' }) private clientId!: string|undefined;
  @Getter('organName', { namespace: 'lookups' }) organNameLookup!: (organCode?: number) => string;
  @Getter('deceasedDonorPageName', { namespace: 'deceasedDonors' }) private deceasedDonorPageName!: string;
  @Getter('getDonorsUrl', { namespace: 'users' }) private getDonorsUrl!: string; // only used for donors, not living donors
  @Getter('checkAllowed', { namespace: 'users' }) private checkAllowed!: (url: string, method?: string) => boolean;
  @Getter('canAccessNowList', { namespace: 'users' }) private canAccessNowList!: boolean;

  private dispatchEventsComplete = false;
  private sectionsLoaded = new Set();
  private allSectionsLoaded = false;

  private openNowModal(): void {
    const nationalOrganWaitlistModal = this.$refs.nationalOrganWaitlistModal as NationalOrganWaitlistModal;
    nationalOrganWaitlistModal.initialize();
  }

  /**
   * determines whether to show combined offers view
   *
   * @returns {boolean} true/false
   */
  get showCombinedOffersView(): boolean {
    return this.isTransplantCoordinator || this.isSurgicalRecoveryCoordinator;
  }

  // Return true if all sections and their associated data has been loaded
  get isLoaded(): boolean {
    return this.allSectionsLoaded;
  }

  // Return true is the section reference supplied has been loaded
  public isSectionLoaded(ref: string): boolean {
    if (!ref) return false;
    return this.sectionsLoaded.has(ref);
  }

  public loaded(ref: string): void {
    if (!ref) return;
    // Create a set of all the sections to load filtering out validations and the save button
    const sectionsToLoad = new Set(Object.keys(this.$refs).filter((ref: string) => !ref.match(/validations|saveDonor/)));

    // Add the ref we just loaded
    this.sectionsLoaded.add(ref);
    // if page loaded, scroll to section
    if (this.sectionsLoaded.size === sectionsToLoad.size) {
      this.$store.dispatch('utilities/scrollBehavior');
      this.allSectionsLoaded = true;
    }
  }

  /**
   * Vue lifecyle hook, for when the reactivity system has taken control of the Document Object Model.
   *
   * @listens #mounted
   */
  public mounted(): void {
    this.loadPage();
  }

  // Update title if organ or allocation region changes
  @Watch('$route.params.organ_code')
  onOrganCodeChange() {
    if(!this.$route.hash){
      this.loadPage();
    }
  }

  @Watch('$route.params.option')
  onAllocationRegionChange() {
    if(!this.$route.hash){
      this.loadPage();
    }
  }

  loadPage() {
    Promise.all([
      this.$store.commit('tasks/resetTask'),
      this.$store.commit('setPageTitle', `Donors / ${this.deceasedDonorPageName} / ${this.donorOrganName}`),
      this.$store.dispatch('hospitals/load'),
      this.$store.dispatch('validations/loadEdit', { view: 'donors', action: 'edit', clientId: this.clientId }),
      this.$store.dispatch('allocations/getAllAllocations', { clientId: this.clientId, clearSelectedAllocation: true }),
    ]).finally(() => {
      this.dispatchEventsComplete = true;
    });
  }

  /**
   * Return the organ name as a string
   *
   * Using the organ_code param, find the matching consented organ for the selected donor.
   *
   * @returns {string} organ name
   */
  get donorOrganName(): string {
    // Prepare organ name for display
    const rawOrganCode = this.$route.params.organ_code || '';
    const numericOrganCode = rawOrganCode != null ? parseFloat(rawOrganCode) : undefined;
    const rawOrganName = this.organNameLookup(numericOrganCode);
    const prettyOrganName = numericOrganCode ? rawOrganName : 'Unknown';
    // Show 'local' or 'provincial' for Kidney
    // TODO: TECH_DEBT If the param is 'double' show 'local' for the name
    const rawAllocationOption = (this.$route.params.option === 'double' ? 'local' : this.$route.params.option) || '';
    const prettyAllocationOption = rawAllocationOption ? ` (${rawAllocationOption})` : '';
    // Show 'double' for Kidney if needed
    const donorIndicators = this.donor.indicators || {};
    const organCount = OrganCodeValue.Kidney == parseFloat(rawOrganCode) && donorIndicators.double_kidney ? 'Double ' : '';
    return `${organCount}${prettyOrganName}${prettyAllocationOption}`;
  }

  // Reload underlying Donor document and reinitialize as needed
  public reload(): void {
    const clientId = this.clientId;
    this.$store.commit('deceasedDonors/clearDeceasedDonor');
    this.$store.dispatch('deceasedDonors/get', clientId).then((result) => {
      const donorSummary = this.$refs.donorSummary as DonorSummary;
      if (donorSummary) donorSummary.initializeForm();
    });
  }

  // Check if we need to display an outcome notification
  private displayOutcomeNotification(context: OfferOutcomeContext) {
    const modal = this.$refs.offerOutcome as OfferOutcomeModal;
    if (!modal) {
      console.warn('Unable to display Offer Outcome Modal', { context });
      return;
    }
    modal.initialize(context);
  }
}
