
import { State, Getter } from 'vuex-class';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { organCodeLookup } from '@/types';
import { Recipient } from '@/store/recipients/types';
import { RecipientJourney } from '@/store/recipientJourney/types';
import { OrganCodeValue } from '@/store/lookups/types';
import { SmallBowelDetails } from '@/store/organSpecificDetails/types';
import { SystemModules } from '@/store/features/types';
import SelectedRecipientOfferLinks from '@/components/prototypes/offers/SelectedRecipientOfferLinks.vue';



@Component({
  components: {
    SelectedRecipientOfferLinks
  }
})
export default class SelectedRecipientJourneyLinks extends Vue {
  @State(state => state.recipients.selectedRecipient) private recipient!: Recipient;
  @State(state => state.journeyState.selectedJourney) private journey!: RecipientJourney;

  @Getter("recipientAge", { namespace: "recipients" }) recipientAge!: number;
  @Getter("includeStomach", { namespace: "recipients" }) includeStomach!: (journeyId?: string) => boolean;
  @Getter("journeyName", { namespace: "recipients" }) journeyNameWithStomach!: (organCode?: number, journeyId?: string) => string;
  @Getter('isGroupWriteable', { namespace: 'validations' }) private isGroupWriteable!: (groupName: string) => boolean;
  @Getter('groupExists', { namespace: 'validations' }) private groupExists!: (groupName: string) => boolean;
  @Getter("moduleEnabled", { namespace: "features" }) private moduleEnabled!: (module: string) => boolean;
  @Getter('prototypeFeatureEnabled', { namespace: 'features' }) private prototypeFeatureEnabled!: (featureName: string) => boolean;

  @Prop() journeyId!: string;
  @Prop() journeyName!: string;
  @Prop() organCode!: number;
  @Prop({ default: false }) offerPage!: boolean;

  show = false;

  public toggleNav() {
    this.show = !this.show;
  }

  public mounted() {
    this.show = this.isActive;
  }

  public toggleSideNav(): void {
    if (window.innerWidth < 992) { 
       this.$emit("toggle");
    }
  }

  @Watch('$route', { immediate: true, deep: true })
  public onUrlChange() {
    this.show = this.isActive;
  }

  get isActive(): boolean {
    const organId = this.$route.params.organ_id;
    if (!!organId) {
      return this.journeyId == organId;
    }
    return false;
  }
  
  /**
   * Return organ name based on the organ code, with + Stomach if needed
   * 
   * @returns {string} organ name 
   */
  get organName(): string {
    return this.journeyNameWithStomach(this.organCode, this.journeyId);
  }

  // Is the Consultation Section module enabled?
  get isConsultationSectionEnabled(): boolean {
    return this.moduleEnabled(SystemModules.CONSULTATION_SECTION);
  }

  // Is the Medical Assessment Section module enabled?
  get isMedicalAssessmentSectionEnabled(): boolean {
    return this.moduleEnabled(SystemModules.MEDICAL_ASSESSMENT_SECTION);
  }

  // Is the Policy Exemptions module enabled?
  get isPolicyExemptionsEnabled(): boolean {
    return this.moduleEnabled(SystemModules.POLICY_EXEMPTIONS);
  }

  // Is the Clinical Transaction module enabled?
  get isClinicalTransactionEnabled(): boolean {
    return this.moduleEnabled(SystemModules.CLINICAL_TRANSACTIONS);
  }

  /**
   * Return organ route from the organ code using organCodeLookup
   * 
   * @returns {string} organ name 
   */
  get organRoute(): string {
    if (!this.organCode) {
      return '';
    }
    return organCodeLookup[this.organCode] || '';
  }

  public getUrl(hash: string): string {
    hash = `#${hash}`;
    
    const currentRoute = this.$router.currentRoute;
    if (currentRoute.name == 'edit-organ-policy_exemptions') {
      const location = this.$router.resolve({ name: 'edit-organ', params: { organ_id: this.journeyId }, hash: hash });
      return location.href;
    }

    if (this.isActive && !this.offerPage) {
      return hash;
    } else {
      // We are fine not checking for the existence of client_id as for new recipients we only ever show this in active mode
      const location = this.$router.resolve({ name: 'edit-organ', params: { organ_id: this.journeyId }, hash: hash });
      return location.href;
    }
  }

  public getSubPage(subPage: string): string {
    const location = this.$router.resolve({ name: 'edit-organ-policy_exemptions', params: { organ_id: this.journeyId } });
    return location.href;
  }
}
