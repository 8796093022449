

import { Component, Vue } from 'vue-property-decorator';

import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import SubSection from '@/components/shared/SubSection.vue';

@Component({
  components: {
    SubSection,
    SaveToolbar,
  }
})
export default class Comorbidities extends Vue {  
  title = 'Medical Procedures';
  
}
