
import { SaveProvider } from '@/types';
import { mixins } from "vue-class-component";
import { State, Getter }  from 'vuex-class';
import { Component, Vue } from "vue-property-decorator";
import DateInput from "@/components/shared/DateInput.vue";
import TimeInput from "@/components/shared/TimeInput.vue";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import SubSection from "@/components/shared/SubSection.vue";
import SelectInput from "@/components/shared/SelectInput.vue";
import TextAreaInput from "@/components/shared/TextAreaInput.vue";
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import { RecipientJourney } from '@/store/recipientJourney/types';
import { GenericCodeValue, NumericCodeValue } from "@/store/types";
import BooleanRadioInput from '@/components/shared/BooleanRadioInput.vue';

export interface LivingDonorPostOperativeCareForm {
  dateOfHospitalDischarge:       string|null;
  timeOfHospitalDischarge:       string|null;
  donationComments:              string|null;

  surgicalComplications:         boolean;
  surgicalComplicationCategory:  number|null;
  surgicalComplicationType:      number|null;
  clavienDindoClassification:    string|null;

  deathRelatedToDonationSurgery: boolean;
  intraOperativeDeath:           boolean|null;
  deathInHospital:               boolean|null;
  causeOfDeathCategory:          string|null;
  causeOfDeathType:              number|null;
  deathDate:                     string|null;
  estimated:                     boolean|null;
}

@Component({
  components: {
    SubSection,
    DateInput,
    TimeInput,
    TextAreaInput,
    CheckboxInput,
    SelectInput,
    BooleanRadioInput,
  }
})
export default class LivingDonorPostOperativeCare extends mixins(DateUtilsMixin) {
  @State(state => state.pageState.currentPage.postOperativeCare) editState!: LivingDonorPostOperativeCareForm;
  @State(state => state.lookups.complication_categories) private surgicalComplicationCategoryOptions!: NumericCodeValue[];
  @State(state => state.lookups.cause_of_death_category) private causeOfDeathCategoryOptions!: GenericCodeValue[];
  @State(state => state.journeyState.selectedJourney) journey!: RecipientJourney;

  @Getter("causeOfDeathTypeLookup", { namespace: "lookups" }) causeOfDeathTypeByCategory!: (deathCode: string) => NumericCodeValue[];
  @Getter('optionsFor', { namespace: 'lookups' }) optionsFor!: (items: string[]) => GenericCodeValue[];

  lookupsToLoad = [
    'complication_categories',
    'cause_of_death_category',
  ];

  get clavienDindoClassificationOptions(): GenericCodeValue[] {
    return this.optionsFor([
      "Grade I",
      "Grade II",
      "Grade IIIa",
      "Grade IIIb",
      "Grade IVa",
      "Grade IVb",
      "Grade V", 
    ]);
  }

  get causeOfDeathTypeOptions(): NumericCodeValue[] {
    const causeOfDeathCategory = this.editState?.causeOfDeathCategory;
    if (causeOfDeathCategory == null) return [];

    return this.causeOfDeathTypeByCategory(causeOfDeathCategory);
  }

  get surgicalComplicationTypeOptions(): NumericCodeValue[] {
    const surgicalComplicationCategory = this.editState?.surgicalComplicationCategory;
    if (surgicalComplicationCategory == null) return [];

    const surgicalComplicationCategoryLookup = this.surgicalComplicationCategoryOptions.find((lookup: NumericCodeValue) => {
      return lookup.code == surgicalComplicationCategory;
    });
    if (!surgicalComplicationCategoryLookup || !surgicalComplicationCategoryLookup.sub_tables) return [];

    return surgicalComplicationCategoryLookup.sub_tables?.complication_types || [];
  }

  get confirmationText(): string|undefined {
    if (!this.editState || !this.editState.deathRelatedToDonationSurgery) return undefined;

    const deathDate = this.editState.deathDate;
    return this.$t('death_confirmation', { death_date: this.parseDisplayDateUi(deathDate) || 'TODAY' }).toString();
  }

  private mounted(): void {
    this.initializeEditState();
  }

  private initializeEditState(): void {
    this.$store.commit('pageState/set', {
      pageKey: 'postOperativeCare',
      value: this.buildEditState(),
    });
  }

  private buildEditState(): LivingDonorPostOperativeCareForm {
    return {
      dateOfHospitalDischarge:       null,
      timeOfHospitalDischarge:       null,
      donationComments:              null,

      surgicalComplications:         false,
      clavienDindoClassification:    null,
      surgicalComplicationCategory:  null,
      surgicalComplicationType:      null,

      deathRelatedToDonationSurgery: false,
      intraOperativeDeath:           null,
      deathInHospital:               null,
      causeOfDeathCategory:          null,
      causeOfDeathType:              null,
      deathDate:                     null,
      estimated:                     null,
    };
  }
  
  private handleSurgicalComplcationsChanged(): void {
    if (!this.editState) return;

    Vue.set(this.editState, 'clavienDindoClassification', null);
    Vue.set(this.editState, 'surgicalComplicationCategory', null);
    Vue.set(this.editState, 'surgicalComplicationType', null);
  }

  private handleSurgicalComplicationCategoryChanged(): void {
    if (!this.editState) return;

    Vue.set(this.editState, 'surgicalComplicationType', null);
  }

  private handleSave(): void {
    const saveProvider = this.$refs.saveLivingDonorPostOperativeCare as unknown as SaveProvider;
    if (!saveProvider) return;

    this.$emit('saving', 'postOperativeCare');
    setTimeout(() => {
      saveProvider.registerSaveResult({ success: true });
    }, 500);
  }

}
