
import { Getter, State } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';
import CardSection from "@/components/shared/CardSection.vue";
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import WorksheetDetailsSection from './WorksheetDetailsSection.vue';

@Component({
  components: {
    CardSection,
    SaveToolbar,
    WorksheetDetailsSection
  }
})
export default class WorksheetAndTasklists extends Vue {  
  title = 'Worksheets / Tasklists';
  @Getter('prototypeFeatureEnabled', { namespace: 'features' }) private prototypeFeatureEnabled!: (featureName: string) => boolean;
}
