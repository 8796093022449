import { render, staticRenderFns } from "./InfusionSection.vue?vue&type=template&id=38f4bb47"
import script from "./InfusionSection.vue?vue&type=script&lang=ts"
export * from "./InfusionSection.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/components/organs/pancreas/_locales/InfusionSection.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Forgans%2Fpancreas%2FInfusionSection.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports