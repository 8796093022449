import { render, staticRenderFns } from "./WorksheetDetailsSection.vue?vue&type=template&id=88fc6a2a"
import script from "./WorksheetDetailsSection.vue?vue&type=script&lang=ts"
export * from "./WorksheetDetailsSection.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/components/organs/shared/_locales/ConsultationDetialsSection.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Fprototypes%2Forgans%2FWorksheetDetailsSection.vue&external"
if (typeof block0 === 'function') block0(component)
import block1 from "@/components/organs/shared/_locales/common.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Fprototypes%2Forgans%2FWorksheetDetailsSection.vue&external"
if (typeof block1 === 'function') block1(component)

export default component.exports