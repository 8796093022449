import { render, staticRenderFns } from "./PancreasWholeTransplantDetails.vue?vue&type=template&id=5a1a25e1"
import script from "./PancreasWholeTransplantDetails.vue?vue&type=script&lang=ts"
export * from "./PancreasWholeTransplantDetails.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./../shared/_locales/common.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Forgans%2Fpancreas%2FPancreasWholeTransplantDetails.vue&external"
if (typeof block0 === 'function') block0(component)
import block1 from "@/components/organs/pancreas/_locales/PancreasWholeTransplantDetails.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Forgans%2Fpancreas%2FPancreasWholeTransplantDetails.vue&external"
if (typeof block1 === 'function') block1(component)

export default component.exports