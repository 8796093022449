
import DateInput from '@/components/shared/DateInput.vue';
import TextInput from '@/components/shared/TextInput.vue';
import SubSection from '@/components/shared/SubSection.vue';
import { Component, Vue, Prop } from 'vue-property-decorator';


@Component({
  components: {
    TextInput,
    DateInput,
    SubSection
  }
})
export default class EncountersDetailsSection  extends Vue {  
  
tableConfig() {
  return {
    data: [
      {
        date: '23/07/2023',
        col2: '1'        
      },
      {
        date: '26/07/2023',
        col2: '2'        
      }
    ],
    columns: [
      { label: "Date of Encounter", field: 'date', width: '15%' },
      { label: 'col 2', field: 'col2' },
    ],
    empty: 'no records',
    createButton: true,
    createText: 'Create Note / Encounter'
  };

}



}

