
  import {
    Getter,
    State
  } from 'vuex-class';
  import {
    Component,
    Vue, Watch, Prop
  } from 'vue-property-decorator';
  import CardSection from "@/components/shared/CardSection.vue";
  import SaveToolbar from '@/components/shared/SaveToolbar.vue';
  import SelectInput from '@/components/shared/SelectInput.vue';
  import TextAreaInput from '@/components/shared/TextAreaInput.vue';

  @Component({
    components: {
      CardSection,
      SaveToolbar,
      SelectInput,
      TextAreaInput
    }
  })
  export default class JourneyStatus extends Vue {

    @State(state => state.pageState.currentPage.taskList) editState!: any;

    @Prop({ default: null }) columns!: any;
    @Prop({ default: null }) tableData!: any;
    @Prop({ default: null }) title!: string;
    @Prop({ default: null }) localStorageRef!: string;

    private comment: any = "";
    private tasks: any = [];

    private categoryOptions = [{
      code: 0,
      value: 'Not done'
    }, {
      code: 1,
      value: 'To be scheduled'
    }, {
      code: 2,
      value: 'Scheduled'
    }, {
      code: 3,
      value: 'Complete'
    }];

    @Getter('prototypeFeatureEnabled', {
      namespace: 'features'
    }) private prototypeFeatureEnabled!: (featureName: string) => boolean;

    @Watch('$route')
    loadFromStorage() {
      this.loaded();
    }

    loaded() {
      // Parse tasks from local storage if found, otherwise default to JSON file contents
      let formState;
      const defaultState = { tasks: this.tableData.rows };
      const items: any = localStorage.getItem(this.localStorageRef);
      if (items) {
        try {
          formState = JSON.parse(items);
        } catch (error) {
          console.warn('Warning: Cannot parse tasklist from local storage, setting default tasks.', error);
          formState = defaultState;
        }
      } else {
        formState = defaultState;
      }
      this.$store.commit("pageState/set", {
        pageKey: "taskList",
        value: formState,
      });
      
      this.tasks = formState.tasks || [];
    }

    mounted() {
      this.loaded();
    }


    translateCategoryOption(code: any) {
      const found: any = this.categoryOptions.find((item: any) => {
        return item.code == code;
      });
      return found.value;
    }

    public switchTaskList(id: any): void {
      if (id == "referral_screening" || id == "waitlist_tasks") {
        // do nothing
      } else {
        // reset actives
        this.editState.tasks.map((item: any) => {
          item.active = false;
        });

        // enable this active
        this.editState.tasks.map((item: any) => {
          if (item.task_id === id) {
            item.active = true;
          }
        });
      }
    }

    public showAddAction(taskId: any, listId: any, subTaskId: any): void {
      if (subTaskId) {
        this.editState.tasks.map((task: any) => {

          if (task.task_id === taskId) {
            task.list_rows.map((item: any) => {
              if (item.list_id === listId) {
                item.sub_tasks.map((subTask: any) => {
                  if (subTask.sub_task_id == subTaskId) {
                    subTask.active = !subTask.active;
                    this.comment = subTask.comment;
                  } else {
                    subTask.active = false;
                  }
                });

              }
            });
          }

        });
      }
    }


    public setStatus(taskId: any, listId: any, subTaskId: any, event: any): void {
      if (subTaskId) {
        this.editState.tasks.map((task: any) => {

          if (task.task_id === taskId) {
            task.list_rows.map((item: any) => {
              if (item.list_id === listId) {
                item.sub_tasks.map((subTask: any) => {
                  if (subTask.sub_task_id == subTaskId) {
                    subTask.state = event;
                  }
                });

              }
            });
          }

        });
      }
    }



    public toggleSubList(taskId: any, listId: any): void {
      if (listId) {
        this.editState.tasks.map((task: any) => {

          if (task.task_id === taskId) {
            task.list_rows.map((item: any) => {
              if (item.list_id === listId) {
                item.active = !item.active;
              }
            });



          }

        });
      }
    }

    public saveAndClose(taskId: any, listId: any, subTaskId: any): void {
      this.handleSubmit();
      this.showAddAction(taskId,listId, subTaskId);
    }

    public handleSubmit(): void {
      localStorage.setItem(this.localStorageRef, JSON.stringify(this.editState));
    }
  }

