import { render, staticRenderFns } from "./SelectedRecipientJourneyLinksOop.vue?vue&type=template&id=5c6bce33"
import script from "./SelectedRecipientJourneyLinksOop.vue?vue&type=script&lang=ts"
export * from "./SelectedRecipientJourneyLinksOop.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/components/_locales/Organs.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2FrecipientsOop%2Fside-nav%2FSelectedRecipientJourneyLinksOop.vue&external"
if (typeof block0 === 'function') block0(component)
import block1 from "@/components/recipientsOop/_locales/side-nav.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2FrecipientsOop%2Fside-nav%2FSelectedRecipientJourneyLinksOop.vue&external"
if (typeof block1 === 'function') block1(component)

export default component.exports