import { render, staticRenderFns } from "./CardSection.vue?vue&type=template&id=8cc586c2&scoped=true"
import script from "./CardSection.vue?vue&type=script&lang=ts"
export * from "./CardSection.vue?vue&type=script&lang=ts"
import style0 from "./CardSection.vue?vue&type=style&index=0&id=8cc586c2&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8cc586c2",
  null
  
)

/* custom blocks */
import block0 from "@/components/shared/_locales/CardSection.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Fshared%2FCardSection.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports