
  import {
    Getter,
    State
  } from 'vuex-class';
  import {
    mixins
  } from "vue-class-component";
  import {
    Component,
    Vue, Watch, Prop
  } from 'vue-property-decorator';
  import CardSection from "@/components/shared/CardSection.vue";
  import SaveToolbar from '@/components/shared/SaveToolbar.vue';
  import {
    VueGoodTable
  } from 'vue-good-table';
  import SelectInput from '@/components/shared/SelectInput.vue';
  import CheckboxInput from '@/components/shared/CheckboxInput.vue';
  import TextInput from '@/components/shared/TextInput.vue';
  import TextAreaInput from '@/components/shared/TextAreaInput.vue';
  import SubSection from '@/components/shared/SubSection.vue';
  import DateInput from '@/components/shared/DateInput.vue';
 import SelectOtherInput from "@/components/shared/SelectOtherInput.vue";
  import {
    TableConfig
  } from '@/types';
  import {
    Recipient
  } from "@/store/recipients/types";
  import {
    UserDetails
  } from '@/store/userAccounts/types';
  import {
    DateUtilsMixin
  } from "@/mixins/date-utils-mixin";

  interface TableRow {
    id: string;
    category ? : string;

    date ? : string;
    fileName ? : string;

    fileType ? : string;
    description ? : string;
    uploadedBy ? : string;

  }

  @Component({
    components: {
      CardSection,
      SaveToolbar,
      SubSection,
      SelectInput,
      TextInput,
      CheckboxInput,
      TextAreaInput,
      VueGoodTable,
      DateInput,
    }
  })
  export default class RecipientDocuments extends mixins(DateUtilsMixin) {
    @State(state => state.recipients.selectedRecipient) recipient!: Recipient;
    @State(state => state.userAccounts.selected) private selected!: UserDetails;
    @State(state => state.pageState.currentPage.Notes) editState!: any;


    @Prop({ default: null }) columns!: any;
    @Prop({ default: null }) tableData!: any;
    @Prop({ default: null }) title!: string;
    @Prop({ default: null }) localStorageRef!: string;

    @Prop({ default: null }) actionData!: any;
    @Prop({ default: null }) localStorageActionsRef!: string;

    @Getter('getUser', {
      namespace: 'users'
    }) private getUser!: any;
    @Getter('prototypeFeatureEnabled', {
      namespace: 'features'
    }) private prototypeFeatureEnabled!: (featureName: string) => boolean;
    
    @Watch('$route')
      loadFromStorage() {
      this.loaded();
    }

    loaded() {
      // Parse tasks from local storage if found, otherwise default to JSON file contents
      let formState;
      let actions;
      const defaultNotesState = {
        notes: this.tableData.rows
      };

      const defaultActionState = {
        actions: this.actionData.rows
      };
      const itemsActions: any = localStorage.getItem(this.localStorageActionsRef);
      const itemsNotes: any = localStorage.getItem(this.localStorageRef);
      if (itemsNotes) {
        try {
          formState = JSON.parse(itemsNotes);
        } catch (error) {
          console.warn('Warning: Cannot parse Notes from local storage, setting default tasks.', error);
          formState = defaultNotesState;
        }
      } else {
        formState = defaultNotesState;
      }

      if (itemsActions) {
        try {
          actions = JSON.parse(itemsActions);
        } catch (error) {
          console.warn('Warning: Cannot parse Actions from local storage, setting default tasks.', error);
        }
      } else {
        actions = defaultActionState;
      }


      this.$store.commit("pageState/set", {
        pageKey: "Notes",
        value: formState,
      });

      this.notes = formState.notes || [];
      this.actions = actions.actions || [];
    }

    mounted() {
      this.loaded();
    }

    private id = null;
    private subject = '';
    private type = "";
    private tasklistItem = '';
    private description = '';
    private actionSubject = '';
    private follow_up_date = '';
    private status = '';
    private writer = '';
    private assigned_to = '';
    private actionDescription = '';
    private isTableUpdating = false;
    private taskInProgress = false;
    public perPage = 10;
    public currentPageTable = 1;
    private notes: any = [];
    private actions: any = [];
    private addAction = false;
    private message = '';


    private editstate = false


    private statusOptions = [{
        code: 0,
        value: 'Pending'
      }, {
        code: 1,
        value: 'Pending -No Response'
      },
    ];

    private userOptions = [{
      code: 0,
      value: 'S. Wood'
    }, {
      code: 1,
      value: 'S. McPhee'
    }, {
      code: 2,
      value: 'M. Couch'
    }, {
      code: 3,
      value: 'A. Johnson'
    }];


    private taskOptions = [{
      code: '1',
      value: 'Social Work Consult'
    }, {
      code: '2',
      value: 'Drug Screen'
    }, {
      code: '3',
      value: 'Labs'
    }, {
      code: '4',
      value: 'Education'
    }, {
      code: '5',
      value: 'Chest X-Ray'
    }, {
      code: '6',
      value: 'Cardiac Cath'
    }, {
      code: "7",
      value: 'Cardiology Consult'
    }, {
      code: "8",
      value: 'Echocardiogram'
    }, {
      code: '9',
      value: 'Stress test'
    }, {
      code: '11',
      value: 'Donor Evaluation'
    }, {
      code: '12',
      value: 'Lab Panel'
    }, {
      code: '13',
      value: 'Med/Soc Evaluation'
    }, {
      code: '14',
      value: 'Incoming Living Donor Referral'
    }, {
      code: '15',
      value: 'Inquiry about Living donor'
    }, {
      code: '10',
      value: 'N/A'
    }];

    private typeOptions = [{
        code: 0,
        value: 'Outgoing Phone Call'
      },
      {
        code: 1,
        value: 'Outgoing Phone Call	- Referring Provider'
      },
      {
        code: 2,
        value: 'Incoming Phone Call'
      },
      {
        code: 3,
        value: 'In House Meeting'
      }, {
        code: 4,
        value: 'Letter Sent'
      },
      {
        code: 5,
        value: 'Informative materials Sent'
      },
      {
        code: 6,
        value: 'In Home Visit'
      },
      {
        code: 7,
        value: 'workshops'
      },
      {
        code: 8,
        value: 'Other / General'
      },
      {
        code: 9,
        value: 'Outgoing Fax',
      },
      {
        code: 10,
        value: 'Incoming Fax',
      }
    ];

    get tableRows() {
      const notes = this.notes || [];
      return notes.map((item: any) => {
        return {
          id: item.id,
          date: item.date ? this.prepareDateWriter(item) : null,
          writer: item.writer,
          subject: item.subject,
          tasklistItem: item.tasklistItem,
          tasklistItemValue: item.tasklistItem ? this.getMultiipleOptionValue(item.tasklistItem, this
            .taskOptions) : null,
          type: item.type,
          typeValue: item.type ? this.getOptionValue(item.type, this.typeOptions) : null,
          description: item.description ? item.description : "None"
        };
      });
    }

    get tableConfig(): TableConfig {

      return {
        data: this.tableRows,
        columns: this.columns,
        createButton: true,
        createText: `${this.$t('attach_new_document')}`,
        sortOptions: {
          enabled: false,
        },
        paginationOptions: {
          enabled: true,
          mode: 'records',
          perPage: this.perPage,
          perPageDropdown: [10, 25, 50],
          setCurrentPage: this.currentPageTable,
          dropdownAllowAll: true,
          nextLabel: this.$t('table.older'),
          prevLabel: this.$t('table.newer'),
          rowsPerPageLabel: this.$t('table.results'),
        },
      };
    }

    private openAction() {
      this.addAction = true;
    }

    private discardAction() {
      this.addAction = false;
      this.message = '';
    }

    private clearMessage() {
      this.message = '';
    }

    private editSelected(row: any) {
      if (row) {
        this.id = row.row.id;
        this.editstate = true;
        this.subject = row.row.subject;
        this.writer = row.row.writer;
        this.tasklistItem = row.row.tasklistItem;
        this.type = row.row.type;
        this.description = row.row.comment !== "None" ? row.row.description : "";
      }
    }

    get emptyMessage(): string {
      if (!this.notes) {
        return this.$t('loading').toString();
      } else {
        return this.$t('use_form_below').toString();
      }
    }

    public resetSaveToolbar(): void {
      // Refer to the save toolbar that handles this page
      const saveToolbar = (this.$refs.uploadDocument as SubSection).$refs[
        'save-recipient-upload-document'] as SaveToolbar;
      saveToolbar.reset();
    }

    get getUserFullName(): string | undefined {
      let user = this.getUser;

      return user.name;
    }

    public prepareDateWriter(item: any) {
      return item.date && item.writer ?
        `${this.parseDisplayDateUi(item.date)}<br/>${this.getOptionValue(item.writer, this.userOptions)}` : "-";
    }

    public getOptionValue(item: any, options: any) {
      return options[item as any].value;
    }

    public getMultiipleOptionValue(items: any, options: any) {
      let arr: any = [];
        items.forEach((element: any) => {
         if (this.isNumber(element)) {
          const found = options.find((item: any) => { return item.code == element; });
          if (found) { 
            arr.push(found.value);
          }
         } else {
           arr.push(element);
         }
        });
      return arr.join(' / ');
    }

    isNumber(value: any) {
      return !isNaN(value);
    }

    public checkRequired() {
      return this.actionSubject == "" || this.follow_up_date == "" || this.assigned_to == '' || this.status == '';
    }

    public clearForm(): any {
      this.id = null;
      this.subject = '';
      this.type = "";
      this.writer = '';
      this.tasklistItem = '';
      this.description = '';
      this.actionSubject = '';
      this.actionDescription = '';
      this.follow_up_date = '';
      this.status = '';
      this.assigned_to = '';
      this.$emit('clear');
      this.editstate = false;
      this.resetSaveToolbar();
    }

    public uploadDocument(): any {
      if (!this.addAction || this.addAction && !this.checkRequired()) {

        this.isTableUpdating = true;
        let notes = this.notes || [];
        let actions = this.actions || [];
        let notesID = Math.random().toString(16).slice(2);
        let actionID = this.addAction ? Math.random().toString(16).slice(2) : null;

        // if has linked Action send action to actions storage

        if (this.addAction) {
          const actionsPayload: any = {
            id: actionID,
            scheduled_date: new Date().toISOString().substring(0, 10),
            subject: this.actionSubject,
            linkedNote: notesID,
            follow_up_date: this.follow_up_date,
            status: this.status,
            assigned_to: this.assigned_to,
            description: this.actionDescription
          };

          actions.push(actionsPayload);
          this.actions = actions;

          const defaultActionsState = {
            actions: actions
          };

          localStorage.setItem(this.localStorageActionsRef, JSON.stringify(defaultActionsState));
        }

        if (!this.editstate) {
          const notePayload: any = {
            id: notesID,
            date: new Date().toISOString().substring(0, 10),
            subject: this.subject,
            writer: this.writer,
            linkedAction: this.addAction ? [actionID] : [],
            tasklistItem: this.tasklistItem,
            type: this.type,
            description: this.description
          };

          notes.push(notePayload);

        } else {
          notes.map((item: any) => {
            if (item.id === this.id) {
              item.subject = this.subject;
              item.writer = this.writer,
              item.linkedAction = this.addAction && actionID ? item.linkedAction.concat([actionID]) : item.linkedAction,
              item.tasklistItem = this.tasklistItem;
              item.type = this.type;
              item.description = this.description;
            }
          });
        }

        this.notes = notes;

        const defaultNotesState = {
          notes: notes
        };

        localStorage.setItem(this.localStorageRef, JSON.stringify(defaultNotesState));
        this.isTableUpdating = false;
        this.editstate = false;
        this.addAction = false;
        this.clearForm();
      } else {
        this.message = "Please fill in required fields in the linked action field or discard linked Action";
        this.resetSaveToolbar();
      }

    }
  }

