var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('card-section',{attrs:{"section-id":"monitor-recovery-outcomes","lookups-to-load":_vm.lookupsToLoad,"isAccordion":_vm.prototypeFeatureEnabled('proto_ability_to_expand_collapse')},on:{"loaded":function($event){return _vm.loaded()}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('monitor_recovery_outcomes'))+" ")]},proxy:true},{key:"body",fn:function(){return [_c('sub-section',{ref:"recoveryOutcomes",attrs:{"sub-section-id":"recovery-outcomes","table-config":_vm.recoveryOutcomesTableConfig},scopedSlots:_vm._u([{key:"table-cell",fn:function(props){return [(props.column.field == 'recipientId')?[(props.row.recipientId)?_c('router-link',{staticClass:"table-link",attrs:{"to":{
            name: props.row.isOutOfProvince ? 'edit-recipient-oop' : 'edit-recipient',
            params: { id: props.row.recipientId }
          },"title":_vm.$t('link_to_recipient_page'),"alt":props.row.recipientId}},[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")]):_c('span',[_vm._v("-")])]:(props.column.field == 'recipientName')?[(props.row.recipientName)?_c('router-link',{staticClass:"table-link",attrs:{"to":{
            name: props.row.isOutOfProvince ? 'edit-recipient-oop' : 'edit-recipient',
            params: { id: props.row.recipientId }
          },"title":props.row.recipientName,"alt":props.row.recipientName}},[_vm._v(" "+_vm._s(props.row.recipientNameShort)+" ")]):_c('span',[_vm._v("-")])]:(props.column.field == 'lastUpdate')?[_c('span',{attrs:{"title":props.row.lastUpdateTime}},[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]:_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field] != null ? props.formattedRow[props.column.field] : '-')+" ")])]}}])})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }