
import { State, Getter }  from 'vuex-class';
import { GenericCodeValue } from "@/store/types";
import { Component, Vue } from 'vue-property-decorator';
import DateInput from "@/components/shared/DateInput.vue";
import SelectInput from "@/components/shared/SelectInput.vue";
import BooleanRadioInput from "@/components/shared/BooleanRadioInput.vue";
import CountryInput from '@/components/shared/CountryInput.vue';
import { Country, CountryValue, Province } from '@/store/lookups/types';

@Component({
  components: {
    DateInput,
    SelectInput,
    BooleanRadioInput,
    CountryInput,
  },
})
export default class DonorAssessment extends Vue {
  @State(state => state.pageState.currentPage.livingDonorAssessment) editState!: any;
  @State(state => state.lookups.country) countryLookup!: Country[];
  @State(state => state.lookups.us_state) usStateLookup!: Province[];
  @State(state => state.lookups.province) provinceLookup!: Province[];

  @Getter('optionsFor', { namespace: 'lookups' }) optionsFor!: (items: string[]) => GenericCodeValue[];

  private mounted(): void {
    this.initializeEditState();
  }

  private initializeEditState(): void {
    this.$store.commit('pageState/set', {
      pageKey: 'livingDonorAssessment',
      value: {},
    });
  }

  get livingDonorStatusOptions(): GenericCodeValue[] {
    return this.optionsFor([
      'New',
      'Active',
      'On Hold',
      'Inactive',
    ]);
  }

  get reasonCategoryOptions(): GenericCodeValue[] {
    return this.optionsFor([
      'Recipient',
      'Living Donor',
    ]);
  }

  get showReasonForNotProceeding(): boolean {
    if (!this.editState) return false;

    return this.editState.statusOfDecisionToConductOrganDonationAssessment === false; // Only show if explicitly 'No'
  }

  get showReasonForNotDonating(): boolean {
    if (!this.editState) return false;

    return this.editState.donorCleared === false; // Only show if explicitly 'No'
  }

  get showStatusReason(): boolean {
    if (!this.editState) return false;

    const selectedStatus = this.editState.livingDonorStatus;
    if (selectedStatus == 'On Hold') return true;
    if (selectedStatus == 'Inactive') return true;

    return false;
  }
}
