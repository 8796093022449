
import { State, Getter } from 'vuex-class';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { IdLookup } from '@/store/validations/types';
import { GenericCodeValue, ObjectId, NumericCodeValue } from '@/store/types';
import { SaveableSection, SaveProvider, SaveResult } from '@/types';
import { CoordinatorOptions } from '@/store/coordinators/types';
import { Organ, OrganSpecification, OrganCodeValue } from '@/store/lookups/types';
import { Hospital, ACTIVE_REGION_TRANSPLANT_PROGRAM } from '@/store/hospitals/types';
import { RecipientAddress, Recipient, RecipientProfileDetails } from '@/store/recipients/types';
import DateInput from '@/components/shared/DateInput.vue';
import SubSection from '@/components/shared/SubSection.vue';
import CardSection from '@/components/shared/CardSection.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import TextInput from "@/components/shared/TextInput.vue";
import { RecipientJourney, RecipientDonorAcceptability, ReferredWithOption } from '@/store/recipientJourney/types';
import { SystemModules } from '@/store/features/types';

interface ReferralDetailsForm {
  organCode: string|null;
  mrn: string|undefined;
  urgent: boolean;
  transplant: {
    transplantProgram?: string;
    recipientCoordinator?: string;
  };
  referralPackage: {
    referredWith: number|null,
  };
}

const URGENT_LISTING_EXCLUDED_ORGANS = [
  OrganCodeValue.VCA,
  OrganCodeValue.PancreasIslets
];

@Component({
  components: {
    DateInput,
    SubSection,
    SaveToolbar,
    CardSection,
    SelectInput,
    CheckboxInput,
    TextInput
  }
})
export default class OrganDetailsSection extends Vue implements SaveableSection {
  // State
  @State(state => state.recipients.selectedRecipient) recipient!: Recipient;
  @State(state => state.journeyState.selectedJourney) journey!: RecipientJourney;
  @State(state => state.pageState.currentPage.organDetails) editState!: ReferralDetailsForm;

  // Getters
  @Getter('clientId', { namespace: 'recipients' }) recipientId!: string;
  @Getter('isReferredWith', { namespace: 'journeyState' }) isReferredWith!: boolean;
  @Getter('getUserHospitalIds', { namespace: 'users' }) getUserHospitalIds!: string[];
  @Getter('referredWithOptions', { namespace: 'journeyState' }) referredWithOptions!: (transplantProgram: string) => ReferredWithOption[];
  @Getter('organName', { namespace: 'lookups' }) describeOrganName!: (organCode?: number) => string|undefined;
  @Getter('regionTransplantOptionsByOrgan', { namespace: 'hospitals' }) regionTransplantOptionsByOrgan!: (organCode?: number) => GenericCodeValue[];
  @Getter('journeyId', { namespace: 'journeyState', }) journeyId!: string|undefined;
  @Getter('lookupValue', { namespace: 'lookups' }) lookupValue!: (code: string|undefined, lookupId: string) => any;
  @Getter('coordinatorOptions', { namespace: 'coordinators' }) coordinatorOptions!: CoordinatorOptions[]; 
  @Getter('filterByHospitalIds', { namespace: 'journeyState', }) filterByHospitalIds!: (journeys: RecipientJourney[], hospitalIds: string[]) => RecipientJourney[];
  @Getter('getDefaultDonorCriteria', { namespace: 'hospitals' }) defaultDonorCriteria!: (hospitalId: string|undefined, organCode: string) => RecipientDonorAcceptability|undefined;
  @Getter('isUrgentListingLocked', { namespace: 'journeyState' }) isUrgentListingLocked!: boolean;
  @Getter('filterByCancellationStatus', { namespace: 'journeyState' }) filterByCancellationStatus!: (journeys: RecipientJourney[], cancellationStatus: boolean) => RecipientJourney[];
  @Getter('isCoordinator', { namespace: 'users' }) isCoordinator!: boolean;
  @Getter('getUserCoordinatorId', { namespace: 'users' }) userCoordinatorId!: ObjectId;
  @Getter("moduleEnabled", { namespace: "features" }) private moduleEnabled!: (module: string) => boolean;
  @Getter('checkPropExists', { namespace: 'validations' }) checkPropExists!: (ruleKey: string) => boolean;
  @Getter('organOptions', { namespace: 'lookups' }) organOptions!: (type?: string) => NumericCodeValue[];

  // Props
  @Prop({ default: false }) newJourney!: boolean;
  @Prop({ default: false }) canSave!: boolean;
  @Prop({ default: false }) creatingRecipient!: boolean;

  @Getter('prototypeFeatureEnabled', { namespace: 'features' }) private prototypeFeatureEnabled!: (featureName: string) => boolean;

  get coordinatorAllowedOptions() {
    return this.editState.transplant.transplantProgram ? this.coordinatorOptions : [];
  }

  /**
   * Clear transplant program on change of organ
   *
   */
  onOrganChange(): void {
    this.$store.commit('pageState/set', {
      pageKey: 'organCode',
      value: this.editState.organCode
    });
    Vue.set(this.editState.transplant, 'transplantProgram', null);
  }

  /**
   * Returns organ code
   *
   * @returns {string|null}
   */
  get organCode(): string|null {
    return this.editState ? this.editState.organCode : null;
  }

  /**
   * Return true if organ details section section can be edited
   * 
   * cannot be edited if new journey
   * cannot be edited if journey is completed
   *
   * @returns {boolean} true if we can edit
   */
  get canEdit(): boolean{
    if (this.newJourney) {
      return true;
    }
    if (this.journey.completed) {
      return false;
    }
    return true;
  }

  /**
   * Get list of organs for the journey organ select input
   *
   * NOTE: this includes expired and non-expired organ entries, because select input needs to handle the
   * expiry-based filtering itself so it can still support previously-saved-but-now-expired data as well.
   *
   * @returns {NumericCodeValue[]} filtered list of organs
   */
  get buildSingleTypeOrgans(): NumericCodeValue[] {
    return this.organOptions('single');
  }

  hospitalOptions() {
    const organCode = this.editState.organCode ? Number(this.editState.organCode) : null;
    return organCode ? this.regionTransplantOptionsByOrgan(organCode) : [];
  }

  get isUrgentListingAllowed(): boolean{
    const organCode = Number(this.organCode);
    return !URGENT_LISTING_EXCLUDED_ORGANS.includes(organCode);
  }

  /**
   * Return text for the save button
   * 
   * @returns {string} text of the save button
   */
  get saveButtonText(): string {
    const organName = this.organName || undefined;
    let humanOrganName = "";
    
    if (organName !== undefined) {
      switch(organName.toLowerCase()) {
        case ("vca"): {
          humanOrganName = this.$t('vca').toString();
          break;
        }
        case ("small bowel"): {
          humanOrganName = this.$t('small_bowel').toString();
          break;
        }
        default: {
          humanOrganName = this.organName;
        }
      }
    }
   
    return this.newJourney ? `${this.$t('proceed_with')} ${humanOrganName} ${this.$t('referral')}` : this.$t('save_referral_details').toString();
  }

  /**
   * Return titlized organ name from lookup value
   * 
   * @returns {string} organ name
   */
  get organName(): string {
    if (!this.organCode) return '';
    if (this.creatingRecipient) {
      if (!this.organCode) { return ''; }
      const organ = this.lookupValue(this.organCode, 'organ');
      return organ ? organ : '';
    }

    if (this.newJourney) {
      const organ = this.lookupValue(this.organCode, 'organ');
      return organ ? organ : '';
    }
    if (this.journey && this.journey.organ_code) {
      const organ = this.lookupValue(this.journey.organ_code.toString(), 'organ');
      return organ ? organ : '';
    }
    return '';
  }

  /**
   * Get a string representation the organ_code from url
   * 
   * @returns {string} organ_code as a string
   */
  get getOrganCodeFromUrl(): string {
    if (this.newJourney) {
      return this.$route.params.organ_code.toString();
    }
    return this.journey.organ_code ? this.journey.organ_code.toString() : '';
  }

  // Is the Urgent Listing system module enabled?
  get isUrgentListingEnabled(): boolean {
    return this.moduleEnabled(SystemModules.URGENT_LISTING);
  }

  // Is the Referred With system module enabled?
  get isReferredWithEnabled(): boolean {
    return this.moduleEnabled(SystemModules.REFERRED_WITH);
  }

  get referredWithOptionsFilteredByProgram(): ReferredWithOption[] {
    if (!this.editState || !this.editState.transplant) return [];

    return this.referredWithOptions(this.editState.transplant!.transplantProgram!);
  }

  // Text displayed in Referred With dropdown
  get referralPackageDescription(): string|null {
    if (!this.referredWithOptions || !this.editState || !this.editState.referralPackage) return null;

    const selected = this.referredWithOptionsFilteredByProgram.find((option: ReferredWithOption) => {
      return option.code == this.editState.referralPackage.referredWith;
    });

    return selected?.value || null;
  }

  // Text for secondary confirmation prompt if applicable
  get confirmationText(): string|undefined {
    if (!this.editState || !this.editState.referralPackage) return undefined;

    // Prompt applies only if not yet Referred With but an option is about to be saved
    if (this.isReferredWith || !this.editState.referralPackage.referredWith) return undefined;

    // Add current organ name to popup
    const referralPackage = [this.organName, this.referralPackageDescription].join(' / ');
    return this.$t('referred_with_confirmation', { referralPackage }).toString();
  }

  // Given a hospital, load all coordinators for that hospital
  public filterCoordinators(hospitalId: string) {
    // clear referredWith when changing Transplant Program
    Vue.set(this.editState.referralPackage, 'referredWith', undefined);
    if (hospitalId) {
      this.$store.dispatch('coordinators/load', { hospitalId });
    } else {
      // reset coordinator if no transplant program is selected
      this.editState.transplant.recipientCoordinator = undefined;
    }
  }

  /**
   * Populates the Referral Details form state with data from the selected journey
   */
  public initializeForm(): void {
    this.$store.commit('pageState/set', {
      pageKey: 'organDetails',
      value: this.buildReferralDetailsForm()
    });
  }

  /**
   * Gets changes from the edit state as a patch for the journey
   * 
   * If the edit state doesn't exist return an empty object
   * 
   * @returns {any} object containing field changes
   */
  public extractPatch(): RecipientJourney {
    if (this.creatingRecipient) {
      return this.extractNewRecipientOrganPatch(this.editState);
    }
    else if (this.editState && this.editState.transplant) {
      return this.extractReferralDetailsPatch(this.editState);
    } else {
      return {};
    }
  }

  // PRIVATE

  /**
   * Emits a loaded event after all lookup tables are loaded
   * 
   * @listens rd#loaded
   * @emits loaded
   */
  private loaded(): void {
    this.$store.dispatch('hospitals/load', ACTIVE_REGION_TRANSPLANT_PROGRAM).then(() => {
      // need to build form first because filterCoordinators() tries setting value in editState which is undefined until we build the form (initializeForm())
      this.initializeForm();
      if(this.isCoordinator){
        this.filterCoordinators(this.getUserHospitalIds[0]);
      }
    });
    this.$emit('loaded', 'organDetailsSection');
  }

  /**
   * Generates Referral Details form state based on the selected journey
   *
   * @returns {ReferralDetailsForm} Referral Details form state
   */
  private buildReferralDetailsForm(): ReferralDetailsForm {
    const organCode = this.creatingRecipient ? '' : this.getOrganCodeFromUrl;

    if (!this.journey) {
      return {
        organCode: organCode,
        mrn: undefined,
        urgent: false, // All new journeys have urgent false by default
        transplant: {
          transplantProgram: this.isCoordinator ? this.getUserHospitalIds[0] : undefined,
          recipientCoordinator: this.isCoordinator ? this.userCoordinatorId.$oid : undefined,
        },
        referralPackage: {
          referredWith: null,
        },
      };
    }
    const transplantProgram = this.journey.transplant_program || {};
    const hospitalId = transplantProgram.transplant_hospital_id ? transplantProgram.transplant_hospital_id.$oid : undefined;
    const coordinatorId = transplantProgram.transplant_coordinator_id ? transplantProgram.transplant_coordinator_id.$oid : undefined;
    const organSpecificDetails = this.journey.organ_specific_details ? this.journey.organ_specific_details : {};

    // Load recipient coordinators
    this.$store.dispatch('coordinators/load', { hospitalId });
    
    return {
      organCode: organCode,
      mrn: undefined,
      urgent: !!this.journey.urgent,
      transplant: {
        transplantProgram: hospitalId,
        recipientCoordinator: coordinatorId,
      },
      referralPackage: {
        referredWith: this.isReferredWith ? this.journey.stage_attributes?.referral?.referral_number || null : null,
      },
    };
  }

  /**
   * Returns a journey patch object containing changes from a Referral Details form
   * 
   * @returns {RecipientJourney}
   */
  private extractReferralDetailsPatch(organDetails: ReferralDetailsForm): RecipientJourney {
    const transplant = organDetails.transplant || {};
    const organCode = this.organCode || null;
    if (!this.journey) {
      const result: RecipientJourney = {
        urgent: organDetails.urgent,
        organ_code: organCode ? Number(organCode) : undefined,
        transplant_program: {
          transplant_hospital_id: (transplant.transplantProgram ? { $oid: transplant.transplantProgram } : undefined),
          transplant_coordinator_id: (transplant.recipientCoordinator ? { $oid: transplant.recipientCoordinator } : undefined),
        },
        referred_with_referral_number: organDetails?.referralPackage?.referredWith || null,
      };
      // DIAG: Some hospitals have specific donor acceptability criteria defaults needed on journey create only

      const donorAcceptability = this.defaultDonorCriteria(this.editState.transplant.transplantProgram, organCode as string);
      // Add default donor_acceptability criteria if any
      if (donorAcceptability) {
        result.donor_acceptability = donorAcceptability;
      }
      return result;
    }
    return {
      urgent: organDetails.urgent,
      transplant_program: {
        transplant_hospital_id: (transplant.transplantProgram ? { $oid: transplant.transplantProgram } : null ),
        transplant_coordinator_id: (transplant.recipientCoordinator ? { $oid: transplant.recipientCoordinator } : null ),
      },
      referred_with_referral_number: organDetails?.referralPackage?.referredWith || null,
    };
  }

  /**
   * Returns a journey patch object containing changes for organ creation on the new recipient form
   * 
   * @returns {RecipientJourney}
   */
  private extractNewRecipientOrganPatch(organDetails: ReferralDetailsForm): RecipientJourney {
    const transplant = organDetails.transplant || {};

    const result: RecipientJourney = {
      organ_code: this.editState.organCode ? Number(this.editState.organCode) : undefined,
      transplant_program: {
        transplant_hospital_mrn: this.editState.mrn,
        transplant_hospital_id: (transplant.transplantProgram ? { $oid: transplant.transplantProgram } : undefined),
      }
    };

    return result;
  }

  /**
   * Saves the form edit state.
   * 
   * Prepares a payload for transplant details, recipient coordinator and urgent.
   * Dispatches a save action, and registers the save result.
   *
   * @emits save
   */
  public savePatch(): void {
    // Report to parent that saving has began
    this.$emit('save', 'organDetails');
    // Refer to the save provider that handles this form area
    const saveProvider = this.$refs.organDetails as unknown as SaveProvider;
    // Generate payload from the current editState
    const payload = {
      recipientId: this.recipientId,
      journeyId: this.journeyId,
      journey: this.extractPatch(),
    };
    // Attempt to save
    this.$store.dispatch('journeyState/saveJourney', payload).then((success: SaveResult) => {
      // For new organs, we must fetch the new ID from the successful response data
      const journeyId = this.newJourney ? success.responseData.journey._id.$oid : this.journeyId;
      // If successful then reload recipient to get updated journey details
      this.$store.dispatch('recipients/get', this.recipientId).then(() => {
        this.$store.dispatch('journeyState/getJourney', journeyId).then(() => {
          // For new organs, navigate to the edit organ page
          if (this.newJourney) {
            // navigate to edit organ page
            this.$router.push({name: 'edit-organ', params: { organ_id: journeyId }});
          } else {
            // Re-initialize if viewing an existing journey
            this.initializeForm();
            // If organ is liver then reload exception diseases (adds liver/kidney exception disease)
            if (this.organCode == OrganCodeValue.Liver.toString()) {
              this.$emit('reloadDiseasesSection');
            }
            // Register save result
            saveProvider.registerSaveResult(success);
            // Clear any errors
            this.$emit('clear');
            // Referred With can affect Referral ID, so we need to reload the Referral section here
            this.$emit('reloadReferralSection');
          }
        });
      });
    }).catch((error: SaveResult) => {
      this.$emit('handleErrors', error);
      saveProvider.registerSaveResult(error);
    });
  }

  /**
   * Resets the save toolbar
   */
  public resetSaveToolbar(): void {
    // Refer to the save provider that handle the areas present on this form component
    const saveProvider = this.$refs.organDetails as unknown as SaveProvider;
    // Reset the save provider's save toolbar
    saveProvider.resetSaveToolbar();
  }

  // API response keys on the left, id for our UI on the right
  public idLookup: IdLookup = {
    // Validation keys for journey-level create/update
    'transplant_program.transplant_hospital_id'    : 'rd-transplant-program',
    'transplant_program.transplant_coordinator_id' : 'rd-transplant-coordinator',
    'referred_with_referral_number'                : 'referral-package-referred-with',

    // Validation keys when attempting to add a recipient to the waitlist
    'addToWaitlist.journey.transplant_program.transplant_hospital_id'    : 'rd-transplant-program',
    'addToWaitlist.journey.transplant_program.transplant_coordinator_id' : 'rd-transplant-coordinator',
    'addToWaitlist.journey.referred_with_referral_number'                : 'referral-package-referred-with',

    // Validation keys for recipient-level create
    "journeys[0].organ_code"                                   : "rd-transplant-organ",
    "journeys[0].transplant_program.transplant_hospital_id"    : "rd-transplant-program",
    "journeys[0].transplant_program.transplant_hospital_mrn"   : "rd-transplant-mrn",
  };
}
