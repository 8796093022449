
import { Getter, State } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';
import { mixins } from "vue-class-component";
import { ValidationUtilsMixin } from "@/mixins/validation-utils-mixin";
import { IdLookup } from '@/store/validations/types';
import { SaveResult } from '@/types';
import { VirologyType } from '@/store/labs/types';
import { Recipient } from '@/store/recipients/types';
import PageTop from '@/components/shared/PageTop.vue';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import ClinicalTransaction from '@/components/recipients/ClinicalTransaction.vue';
import Demographics from '@/components/recipients/Demographics.vue';
import ContactInformation from '@/components/recipients/ContactInformation.vue';
import GeneralClinicalInformation from '@/components/recipients/GeneralClinicalInformation.vue';
import VirologyResults from '@/components/virology/VirologyResults.vue';
import SideNavRecipientProfile from "@/components/recipients/side-nav/SideNavRecipientProfile.vue";
import PotentialDuplicateModal from '@/components/recipients/PotentialDuplicateModal.vue';
import OrganDetailsSection from '@/components/organs/shared/_OrganDetailsSection.vue';
import { SystemModules } from '@/store/features/types';

@Component({
  components: {
    PageTop,
    SaveToolbar,
    ClinicalTransaction,
    Demographics,
    VirologyResults,
    ContactInformation,
    SideNavRecipientProfile,
    GeneralClinicalInformation,
    PotentialDuplicateModal,
    OrganDetailsSection
  }
})
export default class NewRecipient extends mixins(ValidationUtilsMixin) {
  // State
  @State(state => state.recipients.selectedRecipient) private recipient!: Recipient;

  // Getters
  @Getter('canSaveGetter', { namespace: 'validations' }) private canSaveGetter!: (newRecord: boolean) => boolean;
  @Getter('isGroupWriteable', { namespace: 'validations' }) private isGroupWriteable!: (groupName: string) => boolean;
  @Getter("moduleEnabled", { namespace: "features" }) private moduleEnabled!: (module: string) => boolean;

  private dispatchEventsComplete = false;

  /**
   * Returns enum value for VirologyType.Recipient
   *
   * @returns {number} enum value for Recipient
   */
  get virologyType(): number {
    return VirologyType.Recipient;
  }

  // Is the Clinical Transaction module enabled?
  get isClinicalTransactionEnabled(): boolean {
    return this.moduleEnabled(SystemModules.CLINICAL_TRANSACTIONS);
  }

  get showOrganDetailsSection(): boolean {
    return this.moduleEnabled('recipient_create_includes_journey');
  }

  public mounted(): void {
    Promise.all([
      this.$store.commit('setPageTitle', 'Patients / New'),
      this.$store.dispatch('recipients/new'),
      this.$store.dispatch('validations/loadNew', { view: 'recipients', action: 'new' }),
    ]).finally(() => {
      this.dispatchEventsComplete = true;
      this.$store.dispatch('utilities/scrollBehavior');
    });
  }

  // Handle saving triggered by Save Recipient button
  public performSave(potential_duplicate_profile_confirmed?: boolean): void {
    // Refer to the save toolbar that handles this page
    const saveToolbar = this.$refs.saveRecipient as SaveToolbar;
    // Show appropriate notification
    saveToolbar.startSaving();
    // Ref for each component required in the patch
    const clinicalTransaction = this.$refs.clinicalTransaction as ClinicalTransaction;
    const demographics = this.$refs.demographics as Demographics;
    const contactInfo = this.$refs.contactInfo as ContactInformation;
    const generalClinical = this.$refs.generalClinical as GeneralClinicalInformation;
    const virology = this.$refs.virology as VirologyResults;
    // Extract demographics and general clinical (urgent and diagnostics)
    const recipientPatch: Recipient = {
      ...generalClinical.extractPatch(),
      ...demographics.extractPatch(potential_duplicate_profile_confirmed),
    };

    // Extract clinical transaction call information
    if (clinicalTransaction) Object.assign(recipientPatch, clinicalTransaction.extractPatch());

    // Extract contact info and merge with patient profile
    recipientPatch.patient_profile = {
      ...recipientPatch.patient_profile,
      ...contactInfo.extractPatch().patient_profile,
    };
    // Extract and add virology if exists
    if (!virology.isEmpty) {
      recipientPatch.virology_labs = [virology.extractPatch()];
    }
    // Extract and add measurement if exists
    if (!generalClinical.isEmpty) {
      recipientPatch.measurements = [generalClinical.extractMeasurementPatch()];
    }
    // Extract and add organ details section if exists
    if (this.showOrganDetailsSection) {
      const organDetailsSection = this.$refs.organDetailsSection as OrganDetailsSection;
      recipientPatch.journeys = [organDetailsSection.extractPatch()];
    }
    // Clear previous errors
    (this.$refs.validations as any).reset();

    // Attempt to create the recipient
    this.$store.dispatch('recipients/saveRecipient', {
       recipientId: null,
       recipient: recipientPatch,
    }).then((success: SaveResult) => {
      // Get the Client ID assigned to the new recipient
      const clientId = success.responseData.recipient.client_id;
      // Navigate to recipient edit
      this.$router.push({ name: 'edit-recipient', params: { id: clientId } });
    }).catch((error: SaveResult) => {
      // Handle errors on the form
      this.handleErrors(error);
      // Refer to the save toolbar that handles this page
      const saveToolbar = this.$refs.saveRecipient as SaveToolbar;
      // Show appropriate saving notification
      if (saveToolbar) {
        saveToolbar.stopSaving(error);
      }
    });
  }

  // Parse and highlight errors from api response
  private handleErrors(errors: SaveResult|SaveResult[]): void {
    const idLookup: IdLookup = {
      ...(this.$refs.demographics as Demographics).idLookup,
      ...(this.$refs.contactInfo as ContactInformation).idLookup,
      ...(this.$refs.generalClinical as GeneralClinicalInformation).idLookup,
      ...(this.$refs.virology as VirologyResults).idLookup()
    };

    const clinicalTransaction = this.$refs.clinicalTransaction as ClinicalTransaction;
    if (clinicalTransaction) Object.assign(idLookup, clinicalTransaction.idLookup());

    // add Organ Details Section if enabled
    const organDetailsSection = this.$refs.organDetailsSection as OrganDetailsSection;
    if (this.showOrganDetailsSection) {
      Object.assign(idLookup, { ...organDetailsSection.idLookup });
    }

    // Standardize errors to an array of SaveResult objects to faciliate duplicate check
    errors = Array.isArray(errors) ? errors : [errors];

    // Derive errors for UI input fields based on API error results
    const formErrors = this.parseFormErrors(errors, idLookup);

    (this.$refs.validations as any).setErrors(formErrors);
    errors.forEach((err: SaveResult) => {
       (this.$refs.potentialDuplicateModal as PotentialDuplicateModal).checkingDuplicateWarning(err,'performSave');
     });
  }

  // Tell the top-level form validation observer to reset all errors
  private resetValidationErrors() {
    (this.$refs.validations as any).reset();
  }
}
