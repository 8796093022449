import { render, staticRenderFns } from "./ReferralDecisionSection.vue?vue&type=template&id=2376d941"
import script from "./ReferralDecisionSection.vue?vue&type=script&lang=ts"
export * from "./ReferralDecisionSection.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/components/organs/shared/_locales/ReferralDecisionSection.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Forgans%2Fshared%2FReferralDecisionSection.vue&external"
if (typeof block0 === 'function') block0(component)
import block1 from "./_locales/common.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Forgans%2Fshared%2FReferralDecisionSection.vue&external"
if (typeof block1 === 'function') block1(component)
import block2 from "@/components/_locales/Organs.json?vue&type=custom&index=2&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Forgans%2Fshared%2FReferralDecisionSection.vue&external"
if (typeof block2 === 'function') block2(component)

export default component.exports