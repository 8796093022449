import { render, staticRenderFns } from "./HccResult.vue?vue&type=template&id=458db5c3"
import script from "./HccResult.vue?vue&type=script&lang=ts"
export * from "./HccResult.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/components/organs/liver/_locales/HccResult.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Forgans%2Fliver%2FHccResult.vue&external"
if (typeof block0 === 'function') block0(component)
import block1 from "@/components/organs/liver/_locales/HccCriteria.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Forgans%2Fliver%2FHccResult.vue&external"
if (typeof block1 === 'function') block1(component)

export default component.exports