
import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { TransplantMixin } from "@/mixins/transplant-mixin";
import { Getter, State } from "vuex-class";
import { Component, Vue, Prop } from "vue-property-decorator";
import { IdLookup } from '@/store/validations/types';
import SubSection from "@/components/shared/SubSection.vue";
import { SmallBowelTransplantAttributes, RecipientJourney, TransplantFactors } from "@/store/recipientJourney/types";
import DateInput from "@/components/shared/DateInput.vue";
import { RecipientTransplantAttributes } from "@/store/recipientJourney/types";
import TimeInput from "@/components/shared/TimeInput.vue";
import NumberInput from '@/components/shared/NumberInput.vue';
import { SaveableSection, SaveProvider, SaveResult } from '@/types';
import { Recipient } from '@/store/recipients/types';
import { TransplantSectionPageState } from '@/mixins/transplant-mixin';
import TextInput from "@/components/shared/TextInput.vue";

export interface SmallBowelTransplantDetailsPageState {
  transplantType?:string;
  transplantStartDate?: string;
  transplantStartTime?: string;
  pv_CrossClampDate?: string;
  pv_CrossClampTime?: string;
  removedFromColdDate?: string;
  removedFromColdTime?: string;
  pv_ClampOffDate?: string;
  pv_ClampOffTime?: string;
  ha_ClampOffDate?: string;
  ha_ClampOffTime?: string;
  rewarmTime?: string;
  coldIschTime?: string;
}

@Component({
  components: {
    SubSection,
    DateInput,
    TimeInput,
    TextInput
  }
})
export default class SmallBowelTransplantDetails extends mixins(DateUtilsMixin, TransplantMixin) implements SaveableSection{
  // State
  @State(state => state.journeyState.selectedJourney) journey!: RecipientJourney;
  @State(state => state.recipients.selectedRecipient) recipient!: Recipient;

  // Getters
  @Getter('clientId', { namespace: 'recipients' }) private recipientId!: string;
  @Getter('canSaveGetter', { namespace: 'validations' }) private canSaveGetter!: (newRecord: boolean) => boolean;
  @Getter('convertTimeInSeconds', { namespace: 'utilities' }) convertTimeInSeconds!: (time?: number) => string;
  @Getter('isGroupWriteable', { namespace: 'validations' }) private isGroupWriteable!: (groupName: string) => boolean;

  // Props
  @Prop({ default: false }) newJourney!: boolean;
  @Prop({ default: false }) canSave!: boolean;

  private transplant_end_date = '';
  private transplant_end_time = '';

  /**
   * Return true if we can edit the transplant details
   *
   * @returns {boolean} true if we can edit
   */
  get canEdit(): boolean{
    if (this.newJourney || this.journey.completed) {
      return false;
    }
    return true;
  }


  public mounted(): void {

    let items = localStorage.getItem(`${this.journey.organ}transplantDetailsOR`);

    if (items) {
      let parsed = JSON.parse(items);
      this.transplant_end_date = parsed.transplant_end_date;
      this.transplant_end_time = parsed.transplant_end_time;
    }
  }

  private loaded(): void {
    this.$emit('loaded', 'small-bowel-transplant-details');
  }

  /**
   * Build state for the SmallBowelTransplantDetails form area
   *
   * @param SmallBowelTransplantAttributes from RecipientTransplantAttributes
   * @returns {SmallBowelTransplantDetailsPageState} form state for SmallBowelTransplant
   */
  public buildTransplantDetailsState(transplantDetails?: RecipientTransplantAttributes): SmallBowelTransplantDetailsPageState {
    const transplantAttributes = transplantDetails as SmallBowelTransplantAttributes || {};
    const result: SmallBowelTransplantDetailsPageState = {
      transplantStartDate: this.parseDateUiFromDateTime(transplantAttributes.transplant_start_date || undefined),
      transplantStartTime: this.parseTimeUiFromDateTime(transplantAttributes.transplant_start_date || undefined),
      removedFromColdDate: this.parseDateUiFromDateTime(transplantAttributes.end_cold_storage_date || undefined),
      removedFromColdTime: this.parseTimeUiFromDateTime(transplantAttributes.end_cold_storage_date || undefined),
      pv_CrossClampDate: this.parseDateUiFromDateTime(transplantAttributes.portal_vein_cross_clamp_date || undefined),
      pv_CrossClampTime: this.parseTimeUiFromDateTime(transplantAttributes.portal_vein_cross_clamp_date || undefined),
      pv_ClampOffDate: this.parseDateUiFromDateTime(transplantAttributes.portal_vein_clamp_removal_date || undefined),
      pv_ClampOffTime: this.parseTimeUiFromDateTime(transplantAttributes.portal_vein_clamp_removal_date || undefined),
      ha_ClampOffDate: this.parseDateUiFromDateTime(transplantAttributes.hepatic_artery_clamp_removal_date || undefined),
      ha_ClampOffTime: this.parseTimeUiFromDateTime(transplantAttributes.hepatic_artery_clamp_removal_date || undefined),
      rewarmTime: this.convertTimeInSeconds(transplantAttributes.rewarm_time || 0), 
      coldIschTime: this.convertTimeInSeconds(transplantAttributes.cold_isch_time || 0)
    };
    return result;
  }

  /**
   * Gets a patch object representing form edit state changes for this form
   *
   * Delegates the logic of building the patch to a local private method
   *
   * @returns {any} patch object containing field changes
   */
  public extractPatch(): any {
    if (!this.editState || !this.editState.transplantDetails || !this.editState.transplantDetails.organTransplantDetails) {
      return {};
    } else {
      return this.extractSmallBowelTransplantDetailsPatch(this.editState.transplantDetails.organTransplantDetails);
    }
  }

  // String for confirmation dialog for partial cluster transplant hold
  get confirmationText(): string {
    if (!this.isConfirmationRequired()) return '';

    return this.$t('confirm_partial_cluster_transplant_hold').toString();
  }

  /**
   * Saves the form edit state.
   *
   * Prepares a payload for Transplant Atributes, dispatches a save action, and registers the save result.
   * @emits clear clear validation errors because saving has begun
   * @emits saved saving has completed successful
   */
  public savePatch(): void {
    // Refer to the save provider that handles this form area
    const saveProvider = this.$refs.saveTransplantDetails as unknown as SaveProvider;
    // Report to parent that saving has began
    this.$emit('clear');
    // Generate payload based on current edit state
    const transplantAttributesPatch = this.extractPatch();
    // Setup saving payload
    const payload = {
      journeyId: this.journey._id ? this.journey._id.$oid : undefined,
      recipientId: this.recipientId,
      transplantAttributes: transplantAttributesPatch,
    };
    // Dispatch save action and register the response
    this.$store.dispatch('journeyState/saveTransplantDetails', payload).then((success: SaveResult) => {
      // Report to parent that saving has completed
      this.$emit('saved', 'smallBowelTransplantDetails');
      saveProvider.registerSaveResult(success);
    }).catch((error: SaveResult) => {
      // Emit event to handle errors
      this.$emit('handleErrors', error);
      // Show error notification
      saveProvider.registerSaveResult(error);
    });


    const transplantDetailsStorage = {
        "transplant_end_date":  this.transplant_end_date,
        "transplant_end_time": this.transplant_end_time,
      };

     localStorage.setItem(`${this.journey.organ}transplantDetailsOR`, JSON.stringify(transplantDetailsStorage));
  }

   /**
   * Clears all save notifications shown by the form.
   *
   * Gets the Save Provider associated with the form, and requests that it reset its own Save Toolbar
   */
  public resetSaveToolbar(): void {
    const saveProvider = this.$refs.saveTransplantDetails as unknown as SaveProvider;
    saveProvider.resetSaveToolbar();
  }


  // API response keys on the left, id for our UI on the right
  public idLookup(): IdLookup {
    return {
      'transplant_start_date' : 'transplantStartDate',
      'end_cold_storage_date' : 'removedFromColdDate',
      'transplant_start_time' : 'transplantStartTime',
      'end_cold_storage_time' : 'removedFromColdTime',
      'portal_vein_clamp_removal_date' : 'pv_ClampOffDate',
      'portal_vein_clamp_removal_time' : 'pv_ClampOffTime',
      'portal_vein_cross_clamp_date' : 'pv_CrossClampDate',
      'portal_vein_cross_clamp_time' : 'pv_CrossClampTime',
      'hepatic_artery_clamp_removal_date' : 'ha_ClampOffDate',
      'hepatic_artery_clamp_removal_time' : 'ha_ClampOffTime'
    };
  }

  /**
   * Returns a journey patch object containing changes from a Transplant Details form
   *
   * @returns {RecipientTransplantAttributes}
   */
  private extractSmallBowelTransplantDetailsPatch(transplantDetails: SmallBowelTransplantDetailsPageState): SmallBowelTransplantAttributes {
    const transplant = this.editState.transplantDetails || {};
    const smallBowelTransplantDetails = transplant.organTransplantDetails as SmallBowelTransplantDetailsPageState || {};

    // Fetch Transplant Date from the top-level of the form
    const transplantDate = this.editState.transplantDetails?.transplantDate;

    // Build nested 'factors' object to include in the Transplant Attributes patch
    const factors: TransplantFactors = {
      transplant_date: transplantDate ? this.sanitizeDateApi(transplantDate) : null,
    };

    // Build patch for Transplant Attributes object
    const result: SmallBowelTransplantAttributes = {
      transplant_start_date: this.sanitizeDateTimeApi(smallBowelTransplantDetails.transplantStartDate, smallBowelTransplantDetails.transplantStartTime),
      end_cold_storage_date: this.sanitizeDateTimeApi(smallBowelTransplantDetails.removedFromColdDate, smallBowelTransplantDetails.removedFromColdTime),
      portal_vein_clamp_removal_date: this.sanitizeDateTimeApi(smallBowelTransplantDetails.pv_ClampOffDate, smallBowelTransplantDetails.pv_ClampOffTime),
      portal_vein_cross_clamp_date: this.sanitizeDateTimeApi(smallBowelTransplantDetails.pv_CrossClampDate, smallBowelTransplantDetails.pv_CrossClampTime),
      hepatic_artery_clamp_removal_date: this.sanitizeDateTimeApi(smallBowelTransplantDetails.ha_ClampOffDate, smallBowelTransplantDetails.ha_ClampOffTime),
      factors,
      donor_id: transplant.donorId ? { $oid: transplant.donorId } : null,
    };

    // Copy Details to Clustered Organs for details see https://shore.tpondemand.com/entity/7541-722-v42-transplant-details
    if (this.isClustered) {
      result.copy_to_cluster = transplant.copyToCluster;
    }

    return result;
  }
}
