
import { Getter, State } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';
import CardSection from "@/components/shared/CardSection.vue";
import DateInput from '@/components/shared/DateInput.vue';
import tablejson from './json/LivingDonorLabsjson.json';
import 'scroll-shadow-element';

@Component({
  components: {
    CardSection,
    DateInput
  }
})
export default class LabSection extends Vue {  
  title = 'Labs';
  startDate = '';
  endDate= '';
  tableData = tablejson;
  originalData = tablejson;
  offsetWidth = 0;
  displayJson = {};

  @Getter('prototypeFeatureEnabled', { namespace: 'features' }) private prototypeFeatureEnabled!: (featureName: string) => boolean;

  mounted() {
    this.resizeScrollBar();
    this.displayJson = tablejson;
  }

  closeSection(item: any) {
    let newJson = tablejson;
    for (const [key, value] of Object.entries( newJson.test_groups)) {
      if (value.group_code == item.group_code) {
        value.active = !value.active;
      }
    }
  }

  resizeScrollBar() {
    let wrapper1 = document.getElementById('wrapper1');
    let wrapper2 = document.getElementById('wrapper2');
    let first = document.getElementById('first');
    let collection = document.getElementsByClassName("th-row");
    let inputList = Array.prototype.slice.call(collection);
  
    this.offsetWidth = first!.offsetWidth;
    let fakediv = document.getElementById('fake-div');
 
    fakediv!.style.width = this.offsetWidth + 'px';
      
    wrapper1!.onscroll = function() {
      wrapper2!.scrollLeft = wrapper1!.scrollLeft;  
      if (wrapper2!.scrollLeft > 5) {

        inputList.map((col,i) => {
          col.classList.add("addBoxShadow");
        });

      } else {
        inputList.map((col,i) => {
          col.classList.remove("addBoxShadow");
        });
          }
    };

    wrapper2!.onscroll = function() {
        wrapper1!.scrollLeft = wrapper2!.scrollLeft;  
    };
    
  }

  replaceDashes(value : any) {
    return value.sample_date.replace(/\//g, "-");
  }

  
  private moveScroll(): void {
    let toDateID = '';
    let selectedDate = new Date(this.startDate).setUTCHours(0,0,0,0);
    for (const [key, value] of Object.entries(this.tableData.date_row)) {
      if ( new Date(this.replaceDashes(value)).setUTCHours(0,0,0,0) >= selectedDate && toDateID == '') {
        toDateID = value.sample_id;    
      } 
    }

    if (toDateID == '') {
      
      let first = Object.entries(tablejson.date_row)[0][1];
      let last = Object.entries(tablejson.date_row)[Object.entries(tablejson.date_row).length - 1] [1];
      
      if (selectedDate > new Date(last.sample_date).setUTCHours(0,0,0,0) ) {
        toDateID = last.sample_id;
      } else {
        toDateID = first.sample_id;
      }
    }
    
    let pos = $('#' + toDateID);

    let offset = pos.offset();
    let wrapper2 = document.getElementById('wrapper2');
    
    let sidebar = (document.querySelector('.menu-header-nav') as HTMLElement).offsetWidth;

    if (offset && wrapper2 && sidebar) {
      wrapper2.scrollLeft +=  offset.left - sidebar - 305 - 100;

      toDateID = '';
    } 
  
     
  }
  
}
