
import { Getter, State } from 'vuex-class';
import CardSection from '@/components/shared/CardSection.vue';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { IdLookup } from '@/store/validations/types';
import { Recipient } from '@/store/recipients/types';
import HlaAntibodiesTest, { HlaAntibodyRow } from '@/components/hla/HlaAntibodiesTest.vue';
import HlaAntibodiesComments, { HlaCommentsForm } from '@/components/hla/HlaAntibodiesComments.vue';

// Form schema
export interface AntibodiesPageState {
  hlaComments?: HlaCommentsForm;
  hlaAntibodies?: HlaAntibodyRow;
}

@Component({
  components: {
    CardSection,
    SaveToolbar,
    HlaAntibodiesTest,
    HlaAntibodiesComments
  }
})
export default class HlaAntibodies extends Vue {
  // State
  @State(state => state.pageState.currentPage.hlaAntibodies) editState!: AntibodiesPageState;
  @State(state => state.recipients.selectedRecipient) private recipient!: Recipient;

  @Getter('checkAllowed', { namespace: 'users' }) private checkAllowed!: (url: string, method?: string) => boolean;
  @Getter('prototypeFeatureEnabled', { namespace: 'features' }) private prototypeFeatureEnabled!: (featureName: string) => boolean;

  @Prop({ default: false }) canSave!: boolean;

  private isFinishedLoadingLookups = false;
  private isFinishedLoadingSubsections = new Set();
  private totalSubSections = 2; // How many sub sections are we mounting

  // Lookup tables to be loaded by the CardSection component
  private lookupsToLoad = [
    'hla_testing_method',
    'hla_dictionary_epitopes',
    'laboratory_hla_antibody_test_kits',
    'hla_antibody_allele_group_overrides',
  ];

  // Have we finished loading lookups and mounting the sub-sections?
  get isFinishedLoading(): boolean {
    return this.isFinishedLoadingLookups && this.isFinishedLoadingSubsections.size === this.totalSubSections;
  }

  // Laboratory lookups to be loaded by the CardSection component
  private laboratoriesToLoad = ['hla'];

  /**
   * Populates form state with default values for a new HLA Antibodies Test.
   */
  public initializeForm(): void {
    // Clear validation errors
    this.clear();
    // Initialize empty page state
    let hlaCommentPage: HlaCommentsForm = {};
    let hlaAntibodyPage: HlaAntibodyRow = {};
    // Sub section components
    const hlaCommentForm = this.$refs.hlaComments as HlaAntibodiesComments;
    const hlaAntibodyForm = this.$refs.hlaAntibodiesTest as HlaAntibodiesTest;
    // Build pageState for HLA Comments section
    if (!!hlaCommentForm) {
      hlaCommentForm.initializeForm();
    }
    // Build pageState for HLA Antibody Tests section
    if (!!hlaAntibodyForm) {
      hlaAntibodyForm.initializeForm();
    }

    // Commit our state
    this.$store.commit('pageState/set', {
      pageKey: 'hlaAntibodies',
      value: { 
        hlaComments: hlaCommentPage,
        hlaAntibodies: hlaAntibodyPage,
       },
    });
  }

  // API response keys on the left, id for our UI on the right
  public idLookup(): IdLookup {
    const result = {};

    // HLA Comments Section
    const hlaCommentsSection = this.$refs.hlaComments as HlaAntibodiesComments;
    if (hlaCommentsSection) {
      Object.assign(result, { ...hlaCommentsSection.idLookup() });
    }
    
    // Antibody Test Section
    const hlaAntibodiesTestSection = this.$refs.hlaAntibodiesTest as HlaAntibodiesTest;
    if (hlaAntibodiesTestSection) {
      Object.assign(result, { ...hlaAntibodiesTestSection.idLookup() });
    }
    
    return result;
  }

  // Event handlers


  // Private methods

  /**
   * Loads all HLA Antibodies Tests for the selected recipient.
   *
   * Called after the Card Section has finished loading any relevant lookups.
   *
   * @listens antibodies#loaded
   */
  private loaded(): void {
    this.isFinishedLoadingLookups = true;
    this.checkIfLoadingComplete();
  }

  // After sub section mounts add the ref to isFinishedLoadingSubsections
  private loadedSubsection(sectionName: string): void {
    this.isFinishedLoadingSubsections.add(sectionName);
    this.checkIfLoadingComplete();
  }

  // Check if all sub sections have been mounted
  private checkIfLoadingComplete(): void {
    if (this.isFinishedLoading) {
      this.$emit('loaded', 'hlaAntibodies');
      this.initializeForm();
    }
  }

  // Emit event to parent so it can handle validations
  private handleErrors(errors: any) {
    this.$emit('handleErrors', errors);
  }
  
  // Emit event to parent so it can clear validations
  private clear() {
    this.$emit('clear');
  }

  // Handle saved event generated by descendent component
  private saved(): void {
    this.$emit('saved');
  }
}
