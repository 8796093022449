var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"card card-form"},[_c('fieldset',{attrs:{"id":"current-offers"}},[_c('legend',{attrs:{"data-v-8cc586c2":""}},[_c('h3',{staticClass:"legend-title"},[_c('span',{staticClass:"pt-1 d-inline-block"},[_vm._v("Current Offers - "+_vm._s(_vm.organDescription))])])]),_c('div',{staticClass:"is-visible"},[_c('div',{staticClass:"card-body"},[_c('fieldset',[_vm._m(0),_c('fieldset',[_c('div',{staticClass:"table-responsive-md allocationOffers"},[_c('table',{staticClass:"table table table-bordered table-hover table-allocation table-demo-offers"},[_vm._m(1),_vm._m(2),_c('tbody',[_c('tr',{staticClass:"offer-row-no-offer",attrs:{"id":"offer_0"}},[_c('td',[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.openCompareModal(552288)}}},[_c('span',[_vm._v("552288")])])]),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11),_vm._m(12),_vm._m(13),_vm._m(14)]),_c('tr',{staticClass:"offer-row-no-offer",attrs:{"id":"offer_0"}},[_c('td',[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.openCompareModal(552454)}}},[_c('span',[_vm._v("552454")])])]),_vm._m(15),_vm._m(16),_vm._m(17),_vm._m(18),_vm._m(19),_vm._m(20),_vm._m(21),_vm._m(22),_vm._m(23),_vm._m(24),_vm._m(25),_vm._m(26)])])])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('legend',[_c('h4',{staticClass:"legend-title"},[_c('span',{},[_vm._v("Offer Responses")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('thead',[_c('tr',{staticClass:"fixed-head"},[_c('th',{attrs:{"scope":"col"}},[_vm._v("Donor")]),_c('th',{staticStyle:{"width":"11%","max-width":"11%","min-width":"11%"},attrs:{"scope":"col"}},[_c('span',[_vm._v("Offer Date Time")])]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Organ")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Laterality")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Offer Type")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Response Date")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Response Time")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Coordinator")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Surgeon")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Offer Response")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Response Category")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Outcome / Dispostion")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Notes")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('tfoot',[_c('tr',{staticClass:"no-hover action-table-row sticky-row"},[_c('td',{attrs:{"colspan":"100%"}},[_c('nav',{staticClass:"nav action-row flex-align-end"},[_c('div',{staticClass:"offer-button-wrap"},[_c('button',{staticClass:"btn btn-wide btn-sm btn-success w-auto",attrs:{"type":"button"}},[_vm._v("Save Responses")])]),_c('div',{staticClass:"ml-auto offer-timers"},[_c('div')])])])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('td',[_c('span',[_vm._v("11-12-2023 14:00")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('td',{staticClass:"text-center"},[_c('span',[_vm._v("Liver")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('td',{staticClass:"text-center"},[_c('span',[_vm._v("Whole")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('td',{staticClass:"text-center"},[_c('span',[_vm._v("Primary")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('td',[_c('input',{staticClass:"form-control",attrs:{"type":"date","value":"12/11/2023"}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('td',[_c('input',{staticClass:"form-control",attrs:{"type":"time","value":"14:00"}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('td',[_c('select',{staticClass:"form-control",attrs:{"id":"table-coordinator"}},[_c('option',{attrs:{"value":"0"}},[_vm._v(" Select... ")]),_c('option',{attrs:{"value":"D"}},[_vm._v("S. Wood")]),_c('option',{attrs:{"value":"U","selected":""}},[_vm._v("M. Couch")]),_c('option',{attrs:{"value":"H"}},[_vm._v("S. Mcphee")]),_c('option',{attrs:{"value":"H"}},[_vm._v("Ajohnson")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('td',[_c('div',[_c('select',{staticClass:"form-control",attrs:{"id":"table-surgeon"}},[_c('option',{attrs:{"value":"0"}},[_vm._v(" Select... ")]),_c('option',{attrs:{"value":"D","selected":""}},[_vm._v("Bjones")]),_c('option',{attrs:{"value":"C"}},[_vm._v("C. Bratton")]),_c('option',{attrs:{"value":"H"}},[_vm._v("H. Payne")]),_c('option',{attrs:{"value":"M"}},[_vm._v("M. Bridger")]),_c('option',{attrs:{"value":"W"}},[_vm._v("W. Johnson")])])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('td',[_c('select',{staticClass:"form-control",attrs:{"id":"table-responses"}},[_c('option',{attrs:{"value":"0","selected":""}},[_vm._v(" Select... ")]),_c('option',{attrs:{"value":"D"}},[_vm._v("Accepted")]),_c('option',{attrs:{"value":"U"}},[_vm._v("Accepted with conditions")]),_c('option',{attrs:{"value":"H"}},[_vm._v("Declined")]),_c('option',{attrs:{"value":"H"}},[_vm._v("Extension Required")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('td',[_c('select',{staticClass:"form-control",attrs:{"id":"table-responses"}},[_c('option',{attrs:{"value":"0","selected":""}},[_vm._v(" Select... ")]),_c('option',{attrs:{"value":"D"}},[_vm._v("Pending Diagnosis")]),_c('option',{attrs:{"value":"U"}},[_vm._v("Pending Donor Info")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('td',[_c('select',{staticClass:"form-control",attrs:{"id":"table-dispostion"}},[_c('option',{attrs:{"value":"0","selected":""}},[_vm._v(" Select... ")]),_c('option',{attrs:{"value":"D"}},[_vm._v("Offer not primary")]),_c('option',{attrs:{"value":"U"}},[_vm._v("Offer withdrawn")]),_c('option',{attrs:{"value":"H"}},[_vm._v("Not transplanted (patient reasons)")]),_c('option',{attrs:{"value":"H"}},[_vm._v("Not transplanted (donor reasons)")]),_c('option',{attrs:{"value":"H"}},[_vm._v("Not transplanted (logistics)")]),_c('option',{attrs:{"value":"D"}},[_vm._v("Transplanted")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('td',[_c('input',{staticClass:"form-control",attrs:{"type":"text","value":"","placeholder":"Add text"}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('td',[_c('span',[_vm._v("11-12-2023 07:30")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('td',{staticClass:"text-center"},[_c('span',[_vm._v("Liver")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('td',{staticClass:"text-center"},[_c('span',[_vm._v("Whole")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('td',{staticClass:"text-center"},[_c('span',[_vm._v("Backup")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('td',[_c('input',{staticClass:"form-control",attrs:{"type":"date","value":"12/11/2023"}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('td',[_c('input',{staticClass:"form-control",attrs:{"type":"time","value":"14:00"}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('td',[_c('select',{staticClass:"form-control",attrs:{"id":"table-coordinator"}},[_c('option',{attrs:{"value":"0"}},[_vm._v(" Select... ")]),_c('option',{attrs:{"value":"D"}},[_vm._v("S. Wood")]),_c('option',{attrs:{"value":"U","selected":""}},[_vm._v("M. Couch")]),_c('option',{attrs:{"value":"H"}},[_vm._v("S. Mcphee")]),_c('option',{attrs:{"value":"H"}},[_vm._v("A. Johnson")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('td',[_c('div',[_c('select',{staticClass:"form-control",attrs:{"id":"table-surgeon"}},[_c('option',{attrs:{"value":"0"}},[_vm._v(" Select... ")]),_c('option',{attrs:{"value":"D","selected":""}},[_vm._v("Bjones")]),_c('option',{attrs:{"value":"U"}},[_vm._v("A. Smith")]),_c('option',{attrs:{"value":"H"}},[_vm._v("H. Payne")]),_c('option',{attrs:{"value":"H"}},[_vm._v("M. Bridger")]),_c('option',{attrs:{"value":"H"}},[_vm._v("W. Johnson")])])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('td',[_c('select',{staticClass:"form-control",attrs:{"id":"table-responses"}},[_c('option',{attrs:{"value":"0","selected":""}},[_vm._v(" Select... ")]),_c('option',{attrs:{"value":"D"}},[_vm._v("Accepted")]),_c('option',{attrs:{"value":"U"}},[_vm._v("Accepted with conditions")]),_c('option',{attrs:{"value":"H"}},[_vm._v("Declined")]),_c('option',{attrs:{"value":"H"}},[_vm._v("Extension Required")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('td',[_c('select',{staticClass:"form-control",attrs:{"id":"table-responses"}},[_c('option',{attrs:{"value":"0","selected":""}},[_vm._v(" Select... ")]),_c('option',{attrs:{"value":"D"}},[_vm._v("Pending Diagnosis")]),_c('option',{attrs:{"value":"U"}},[_vm._v("Pending Donor Info")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('td',[_c('select',{staticClass:"form-control",attrs:{"id":"table-dispostion"}},[_c('option',{attrs:{"value":"0","selected":""}},[_vm._v(" Select... ")]),_c('option',{attrs:{"value":"D"}},[_vm._v("Offer not primary")]),_c('option',{attrs:{"value":"U"}},[_vm._v("Offer withdrawn")]),_c('option',{attrs:{"value":"H"}},[_vm._v("Not transplanted (patient reasons)")]),_c('option',{attrs:{"value":"H"}},[_vm._v("Not transplanted (donor reasons)")]),_c('option',{attrs:{"value":"H"}},[_vm._v("Not transplanted (logistics)")]),_c('option',{attrs:{"value":"D"}},[_vm._v("Transplanted")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('td',[_c('input',{staticClass:"form-control",attrs:{"type":"text","value":"","placeholder":"Add text"}})])
}]

export { render, staticRenderFns }