
import { Organ, OrganCodeValue } from '@/store/lookups/types';
import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { State, Getter }  from 'vuex-class';
import { SaveResult, SaveProvider } from '@/types';
import { Component, Vue } from "vue-property-decorator";
import { GenericCodeValue, NumericCodeValue } from "@/store/types";
import SubSection from "@/components/shared/SubSection.vue";
import DateInput from "@/components/shared/DateInput.vue";
import TimeInput from "@/components/shared/TimeInput.vue";
import TextAreaInput from "@/components/shared/TextAreaInput.vue";
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import SelectInput from "@/components/shared/SelectInput.vue";
import BooleanRadioInput from '@/components/shared/BooleanRadioInput.vue';
import { RecipientJourney } from '@/store/recipientJourney/types';

export interface PostOperativeCareForm {
  dateOfHospitalDischarge:                    string|null;
  timeOfHospitalDischarge:                    string|null;
  transplantComments:                         string|null;

  primaryGraftDysfunction:                    boolean|null;
  primaryGraftDysfunctionGrade:               string|null;
  mechanicalCirculatorySupport:               boolean|null;
  mechanicalCirculatorySupportType:           string|null;
  mechanicalCirculatorySupportSeparationDate: string|null;

  surgicalComplications:                      boolean;
  surgicalComplicationCategory:               number|null;
  surgicalComplicationType:                   number|null;
  clavientDindoClassification:                string|null;

  deathRelatedToTransplantSurgery:            boolean;
  intraOperativeDeath:                        boolean|null;
  deathInHospital:                            boolean|null;
  causeOfDeathCategory:                       string|null;
  causeOfDeathType:                           number|null;
  deathDate:                                  string|null;
  estimated:                                  boolean|null;
}

const PRIMARY_GRAFT_DYSFUNCTION_ORGANS = [
  OrganCodeValue.Heart,
  OrganCodeValue.Lung,
];

const MECHANICAL_CIRCULATORY_SUPPORT_ORGANS = [
  OrganCodeValue.Heart,
  OrganCodeValue.Lung,
];

@Component({
  components: {
    SubSection,
    DateInput,
    TimeInput,
    TextAreaInput,
    CheckboxInput,
    SelectInput,
    BooleanRadioInput,
  }
})
export default class PostOperativeCare extends mixins(DateUtilsMixin) {
  @State(state => state.pageState.currentPage.postOperativeCare) editState!: PostOperativeCareForm;
  @State(state => state.lookups.complication_categories) private surgicalComplicationCategoryOptions!: NumericCodeValue[];
  @State(state => state.lookups.cause_of_death_category) private causeOfDeathCategoryOptions!: GenericCodeValue[];
  @State(state => state.journeyState.selectedJourney) journey!: RecipientJourney;

  @Getter("causeOfDeathTypeLookup", { namespace: "lookups" }) causeOfDeathTypeByCategory!: (deathCode: string) => NumericCodeValue[];
  @Getter('optionsFor', { namespace: 'lookups' }) optionsFor!: (items: string[]) => GenericCodeValue[];

  lookupsToLoad = [
    'complication_categories',
    'cause_of_death_category',
  ];

  get primaryGraftDysfunctionGradeOptions(): GenericCodeValue[] {
    return this.optionsFor([
      "Heart moderate PGD — Left ventricle",
      "Heart severe PGD — Left ventricle",
      "Heart PGD — Right ventricle",
      "Lung PGD Grade 2",
      "Lung PGD Grade 3 ",
    ]);
  }

  get mechanicalCirculatorySupportTypeOptions(): GenericCodeValue[] {
    return this.optionsFor([
      "IABP - Intra-Aortic Balloon Pump",
      "LVAD - Left Ventricular Assist Device",
      "RVAD - Right Ventricular Assist Device",
      "Artificial heart",
      "VA ECMO - Venoarterial Extracorporeal Membrane Oxygenation",
      "VV ECMO - Venovenous Extracorporeal Membrane Oxygenation",
      "Other",
      "Unknown",
    ]);
  }

  get clavientDindoClassificationOptions(): GenericCodeValue[] {
    return this.optionsFor([
      "Grade I",
      "Grade II",
      "Grade IIIa",
      "Grade IIIb",
      "Grade IVa",
      "Grade IVb",
      "Grade V", 
    ]);
  }

  get causeOfDeathTypeOptions(): NumericCodeValue[] {
    const causeOfDeathCategory = this.editState?.causeOfDeathCategory;
    if (causeOfDeathCategory == null) return [];

    return this.causeOfDeathTypeByCategory(causeOfDeathCategory);
  }

  get surgicalComplicationTypeOptions(): NumericCodeValue[] {
    const surgicalComplicationCategory = this.editState?.surgicalComplicationCategory;
    if (surgicalComplicationCategory == null) return [];

    const surgicalComplicationCategoryLookup = this.surgicalComplicationCategoryOptions.find((lookup: NumericCodeValue) => {
      return lookup.code == surgicalComplicationCategory;
    });
    if (!surgicalComplicationCategoryLookup || !surgicalComplicationCategoryLookup.sub_tables) return [];

    return surgicalComplicationCategoryLookup.sub_tables?.complication_types || [];
  }

  get confirmationText(): string|undefined {
    if (!this.editState || !this.editState.deathRelatedToTransplantSurgery) return undefined;

    const deathDate = this.editState.deathDate;
    return this.$t('death_confirmation', { death_date: this.parseDisplayDateUi(deathDate) || 'TODAY' }).toString();
  }

  get showPrimaryGraftDysfunction(): boolean {
    if (!this.journey) return false;

    const organCode = this.journey?.organ_code || null;
    return PRIMARY_GRAFT_DYSFUNCTION_ORGANS.includes(organCode as OrganCodeValue);
  }

  get showMechanicalCirculatorySupport(): boolean {
    if (!this.journey) return false;

    const organCode = this.journey?.organ_code || null;
    return MECHANICAL_CIRCULATORY_SUPPORT_ORGANS.includes(organCode as OrganCodeValue);
  }

  private mounted(): void {
    this.initializeEditState();
  }

  private initializeEditState(): void {
    this.$store.commit('pageState/set', {
      pageKey: 'postOperativeCare',
      value: this.buildEditState(),
    });
  }

  private buildEditState(): PostOperativeCareForm {
    return {
      dateOfHospitalDischarge:                    null,
      timeOfHospitalDischarge:                    null,
      transplantComments:                         null,

      primaryGraftDysfunction:                    null,
      primaryGraftDysfunctionGrade:               null,
      mechanicalCirculatorySupport:               null,
      mechanicalCirculatorySupportType:           null,
      mechanicalCirculatorySupportSeparationDate: null,

      surgicalComplications:                      false,
      clavientDindoClassification:                null,
      surgicalComplicationCategory:               null,
      surgicalComplicationType:                   null,

      deathRelatedToTransplantSurgery:            false,
      intraOperativeDeath:                        null,
      deathInHospital:                            null,
      causeOfDeathCategory:                       null,
      causeOfDeathType:                           null,
      deathDate:                                  null,
      estimated:                                  null,
    };
  }

  private handlePrimaryGraftDysfunctionChanged(): void {
    if (!this.editState) return;

    Vue.set(this.editState, 'primaryGraftDysfunctionGrade', null);
  }

  private handleMechanicalCirculatorySupportChanged(): void {
    if (!this.editState) return;

    Vue.set(this.editState, 'mechanicalCirculatorySupportType', null);
    Vue.set(this.editState, 'mechanicalCirculatorySupportSeparationDate', null);
  }
  
  private handleSurgicalComplcationsChanged(): void {
    if (!this.editState) return;

    Vue.set(this.editState, 'clavientDindoClassification', null);
    Vue.set(this.editState, 'surgicalComplicationCategory', null);
    Vue.set(this.editState, 'surgicalComplicationType', null);
  }

  private handleSurgicalComplicationCategoryChanged(): void {
    if (!this.editState) return;

    Vue.set(this.editState, 'surgicalComplicationType', null);
  }

  private handleSave(): void {
    const saveProvider = this.$refs.savePostOperativeCare as unknown as SaveProvider;
    if (!saveProvider) return;

    this.$emit('saving', 'postOperativeCare');
    setTimeout(() => {
      saveProvider.registerSaveResult({ success: true });
    }, 500);
  }

}
