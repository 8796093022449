
import { Getter, State } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';
import CardSection from "@/components/shared/CardSection.vue";
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import NumberInput from '@/components/shared/NumberInput.vue';

@Component({
  components: {
    CardSection,
    SaveToolbar,
    SelectInput,
    NumberInput
  }
})
export default class SocialHistory extends Vue {  
  title = 'Social History';
  private alcohol_use = '';
  private smoking_use = '';
  private smoking_rate = '';
  private smoking_duration = '';
  private marijuana_use = '';
  private drug_use = '';

  private Options = [{code: 0, value: 'Yes'}, {code: 1, value: 'No'},{code: 2, value: 'Unknown'}];


  private mounted(): void {
    let items = localStorage.getItem("socialHistoryStorage");

    if (items) {
      let parsed = JSON.parse(items);
      this.alcohol_use = parsed.alcohol_use;
      this.smoking_use = parsed.smoking_use;
      this.smoking_rate = parsed.smoking_rate;
      this.smoking_duration = parsed.smoking_duration;
      this.marijuana_use = parsed.marijuana_use;
      this.drug_use = parsed.drug_use;
    }
  
  }

  public performSave(): void {
    const socialHistoryStorage = {
    "alcohol_use":  this.alcohol_use,
    "smoking_use": this.smoking_use,
    "smoking_rate": this.smoking_rate,
    "smoking_duration": this.smoking_duration,
    "marijuana_use": this.marijuana_use,
    "drug_use": this.drug_use
  };

    localStorage.setItem("socialHistoryStorage", JSON.stringify(socialHistoryStorage));
  }


  

}
