
import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { Getter, State } from 'vuex-class';
import { titleCase, mergeClasses, isMasked } from '@/utils';
import SubSection from '@/components/shared/SubSection.vue';
import { LivingDonor, LivingDonorOrgan, LivingDonorAllocationSummary, LivingDonorJourney } from '@/store/livingDonors/types';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Organ, AllDispositionStatus } from '@/store/lookups/types';
import DiscontinueOrgansModal from '@/components/allocations/_DiscontinueOrgansModal.vue';
import { GenericCodeValue } from '@/store/types';
import { Hospital } from '@/store/hospitals/types';
import { Allocations } from '@/store/allocations/types';
import OrganIcon from '@/components/shared/OrganIcon.vue';
import { ClassObject } from '@/types';
import { EP } from '@/api-endpoints';

interface LivingDonorSummaryForm {
  date_of_birth?: string|null|undefined;
  living_donor_id?: number;
  system_id?: number;
  donor_name?: string;
  sex?: string;
  age?: string|number;
  abo?: string|null;
  abo_sub_type?: string|null;
  donor_type?: string;
  height?: number;
  weight?: number;
  insurance_number?: string;
  region?: string;
  serology?: string;
  transplant_program?: string|null;
}

@Component({
   components: {
    SubSection,
    OrganIcon,
    DiscontinueOrgansModal
   },
})
export default class LivingDonorSummary extends mixins(DateUtilsMixin) {
  @State(state => state.pageState.currentPage.livingDonorSummary) editState!: LivingDonorSummaryForm;
  @State(state => state.livingDonors.selectedLivingDonor) private livingDonor!: LivingDonor;
  @State(state => state.livingAllocations.isLoadingAllocation) isLoadingAllocation!: boolean;
  @State(state => state.livingAllocations.isLoadingAllocations) isLoadingAllocations!: boolean;
  @State(state => state.livingAllocations.isDiscontinuingAllAllocations) isDiscontinuingAllAllocations!: boolean;

  //Getters
  @Getter('livingDonorId', { namespace: 'livingDonors' }) private livingDonorId!: string;
  @Getter('clientId', { namespace: 'livingDonors' }) private clientId!: string;
  @Getter('livingDonorDisplayName', { namespace: 'livingDonors' } ) private livingDonorDisplayName!: string;
  @Getter('lookupValue', { namespace: 'lookups' }) lookupValue!: (code: string|undefined, lookupId: string) => any;
  @Getter('getOntarioHospitalById', { namespace: 'hospitals' }) private getOntarioHospitalById!: (hospitalId?: string|null) => Hospital|null;
  @Getter('getHospitalAbbreviation', { namespace: 'hospitals' }) getHospitalAbbreviation!: (hospitalCode?: string|null) => string|null;
  @Getter('organName', { namespace: 'lookups' }) organNameLookup!: (organCode?: number) => string;
  @Getter('selectedLivingDonorConsentedOrganList', { namespace: 'livingDonors'}) private selectedLivingDonorConsentedOrganList!: LivingDonorAllocationSummary[];
  @Getter('checkAllowed', { namespace: 'users' }) private checkAllowed!: (url: string, method?: string) => boolean;
  @Getter('getDonorAge', { namespace: 'livingDonors' }) getDonorAge!: number|undefined;
  @Getter('selectedLivingDonorJourney', { namespace: 'livingDonors' }) selectedLivingDonorJourney!: LivingDonorJourney|null;

  // Afflo Prototype
  @Getter('prototypeFeatureEnabled', { namespace: 'features' }) private prototypeFeatureEnabled!: (featureName: string) => boolean;

  @Prop({ default: '' }) inputClass!: string; // Class string for list

  public lookupsToLoad = [];
  private isFinishedLoadingEvents = false;

  /**
   * Get hash for donation information section if allowed to see the section
   *
   * @returns {string} return hash to section, otherwise empty
   */
  get allowJumpToDonationInformation(): string {
    const apiAllowed = this.checkAllowed(EP.living_donors.show, "GET");
    return apiAllowed ? "#donation-information" : "";
  }

  /**
   * Get a boolean represenation of if we have any active allocations
   *
   * @returns {boolean} true if there are active allocations
   */
  get showDiscontinue(): boolean {
    // TODO: replace this - check API for active allocations (not implemented yet)
    const apiAllowed = this.checkAllowed(EP.living_donors.allocations.offers.discontinue_offers, "POST");
    return this.selectedLivingDonorConsentedOrganList.length > 0 && apiAllowed;
  }

   //Getter - Selected organ shown as active
  get listItemClass(){
    return (consentedOrgan: any): ClassObject => {
      const option = this.$route.params.option;
      const organCode = this.$route.params.organ_code;
      const consentedOrganRoute = consentedOrgan.route.params.option;
      const consentedOrganCode = consentedOrgan.organ_code;
      let isActive : any;

      if(consentedOrgan.route.params.option)
      {
        isActive = consentedOrgan.display_name.toLowerCase().includes(option) && organCode == consentedOrganCode.toString();
      } else {
        isActive = organCode == consentedOrganCode.toString();
      }
      //Define dynamic class based on the selected organ
      const conditionalClass = {
        'active' : isActive
      };
      return conditionalClass;

    };
  }

  // Afflo Prototype: Living Donor Summary Extended
  get listItemClassExtended() {
    return (consentedOrgan: any): ClassObject => {
      const consendedClassObject = {};
      Object.assign(consendedClassObject, this.listItemClass || {});

      const isActiveTile = true;
      const isInactiveTile = false;
      const isDonatedTile = false;

      Object.assign(consendedClassObject, {
        'allocation-eligible': isActiveTile,
        'allocation-not-eligible': isInactiveTile,
        'waiting-for-living-donor-only': isDonatedTile,
      });
      return consendedClassObject;
    };
  }

  // Afflo Prototype: Living Donor Summary Extended
  get phaseDisplayValue() {
    return (consentedOrgan: any): string => {
      return 'Recipient Match Assessment';
    };
  }

  // Afflo Prototype: Living Donor Summary Extended
  get statusDisplayValue() {
    return (consentedOrgan: any): string =>{
      return 'Active';
    };
  }

  /**
   * Initializes the Journey Status form state after recipient events, lookups, and all subsections have finished loading/mounting
   *
   * Emits a loaded event to notify its page layout that loading of this card section has completed
   *
   * @emits loaded
   */
  private checkIfLoadingComplete(): void {
    if (this.isFinishedLoadingEvents) {
      this.$store.dispatch('hospitals/load').then(() => {
        this.initializeForm();
      });
    }
  }

  @Watch('livingDonor', { immediate: true })
  private onLivingDonorChanged(): void {
    this.initializeForm();
  }

  public loaded(): void {
    this.$emit('loaded', 'livingDonorSummary');
  }

  // Private methods
  public initializeForm(): void {
    this.$store.commit('pageState/set', {
      pageKey: 'livingDonorSummary',
      value: this.extractDonorSummaryForm(this.livingDonor)
    });
  }

  // Format date-only field for display
  public formatDate(dateValue: string): string|undefined {
    if (!isNaN(Date.parse(dateValue))) {
      return this.parseDisplayDateUi(dateValue);
    } else {
      return dateValue;
    }
  }

  // Format combined date/time for display
  public formatDateTime(dateTimeValue: string): string|undefined {
    if (!isNaN(Date.parse(dateTimeValue))) {
      return this.parseFormattedDateTimeUi(dateTimeValue);
    } else {
      return dateTimeValue;
    }
  }

  public toggleClass(organ_code: string){
    let organCode = this.$route.params.organ_code;
    return organ_code == organCode? true : false;
  }

  public extractDonorSummaryForm(donor?: LivingDonor): LivingDonorSummaryForm {
    // Return empty object if there is no data document
    if (!donor) {
      return {};
    }
    // Sanitize nested objects, so their parameters can easily be read as 'undefined' instead of raising type errors
    const patientProfile = donor.patient_profile || {};
    const birth = donor.patient_profile?.birth || {};
    const referral = donor.referral || {};
    const blood = donor.blood || {};
    const donorMeasurements = donor.measurements || [];
    const insurance = donor.patient_profile?.insurance;

    const addresses = patientProfile.addresses || {};
    // Most recent measurement will be first in the array
    const measurement = donorMeasurements.length > 0 ? donorMeasurements[0] : {};

    const hospital = this.selectedLivingDonorJourney?.transplant_program ? this.getOntarioHospitalById(this.selectedLivingDonorJourney?.transplant_program?.transplant_hospital_id?.$oid) : undefined;
    const hospitalName = hospital?.hospital_name_info.abbreviation;

    const birthDate = !isMasked(birth.date) ? this.parseDisplayDateUi(birth.date) : birth.date;

    // Return parameters extracted from data document based on structure of form state interface
    return {
      date_of_birth: birthDate,
      living_donor_id: donor.living_donor_id,
      system_id: donor.client_id,
      donor_name: patientProfile.first_name && patientProfile.last_name,
      sex: patientProfile.sex || undefined,
      age: isMasked(birth.date) ? '*******' : this.getDonorAge,
      abo: blood.type,
      abo_sub_type: blood.sub_type,
      height: measurement.height,
      weight: measurement.weight,
      insurance_number: insurance?.number || undefined,
      transplant_program: hospitalName || undefined
    };
  }
}

