
import { SaveableSection, SaveProvider, SaveResult } from '@/types';
import { ObjectId } from "@/store/types";
import { Getter, State } from 'vuex-class';
import { Hospital } from '@/store/hospitals/types';
import SubSection from '@/components/shared/SubSection.vue';
import { DeceasedDonor, DeceasedDonorOrganRecovery, DeceasedDonorOrganDonations } from '@/store/deceasedDonors/types';
import CardSection from '@/components/shared/CardSection.vue';
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { IdLookup } from '@/store/validations/types';
import TextInput from '@/components/shared/TextInput.vue';
import DateInput from '@/components/shared/DateInput.vue';
import TimeInput from '@/components/shared/TimeInput.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import SelectOtherInput from "@/components/shared/SelectOtherInput.vue";
import TableCheckboxInput from '@/components/shared/TableCheckboxInput.vue';
import { GenericCodeValue } from '@/store/types';
import RecoveryInformation, { RecoveryInformationPageState } from '@/components/deceasedDonors/RecoveryInformation.vue';
import OrganRecoveryData, { OrganDonationsForm } from '@/components/deceasedDonors/OrganRecoveryData.vue';

// Form schemas
export interface RecoveryDetailsPageState {
  recoveryInformation?: RecoveryInformation;
  organRecoveryData?: OrganRecoveryData;
}

@Component({
  components: {
    SubSection,
    CardSection,
    TextInput,
    DateInput,
    TimeInput,
    SelectInput,
    SelectOtherInput,
    TableCheckboxInput,
    RecoveryInformation,
    OrganRecoveryData
  }
})
// TODO: still waiting on data for this components so it will need to be reworked a bit soon
export default class RecoveryDetails extends Vue {
  // State
  @State(state => state.hospitals.all) private hospitals!: Hospital[];
  @State(state => state.lookups.organ_delivery_status) OrganDeliveryStatusLookup!: GenericCodeValue[];
  @State(state => state.deceasedDonors.selected) private deceasedDonor!: DeceasedDonor;
  @State(state => state.pageState.currentPage.recoveryDetails) editState!: RecoveryDetailsPageState;

  @Getter('province', { namespace: 'lookups' }) private provinceLookup: any;
  @Getter('isGroupWriteable', { namespace: 'validations' }) private isGroupWriteable!: (groupName: string) => boolean;

  @Getter('prototypeFeatureEnabled', { namespace: 'features' }) private prototypeFeatureEnabled!: (featureName: string) => boolean;

  private isFinishedLoadingSubsections = new Set();
  private totalSubSections = 2; // How many sub sections are we mounting

  // public 
  // Lookup tables to be loaded by the CardSection component
  public lookupsToLoad = ['country', 'organ_delivery_status'];

  public buildOrganRecoveryForm(deceasedDonor?: any): RecoveryDetailsPageState {
    const organDonations = deceasedDonor.organ_donations || [];

    const result: RecoveryDetailsPageState = Object.assign({});

    return result;
  }

  // Event handlers
  private mounted(): void {
    this.initializeForm();
  }

  @Watch('deceasedDonor', { immediate: true, deep: true })
  private onDeceasedDonorChanged(): void {
    this.initializeForm();
  }

  public loaded(): void {
    this.$emit('loaded', 'organRecoveryDetails');
  }

  // After sub section mounts add the ref to isFinishedLoadingSubsections
  private loadedSubsection(sectionName: string): void {
    this.isFinishedLoadingSubsections.add(sectionName);
    this.checkIfLoadingComplete();
  }

  // Check if all sub sections have been mounted
  private checkIfLoadingComplete(): void {
    if (this.isFinishedLoadingSubsections.size === this.totalSubSections) {
      this.initializeForm();
    }
  }

  // Public methods
  public initializeForm(): void {
    // Clear validation errors
    // Initialize empty page state
    let recoveryInformationPageState: RecoveryInformationPageState = {};
    let organDonationsFormPageState: OrganDonationsForm = {};
    // Sub section components
    const recoveryInformation = this.$refs.recoveryInformation as RecoveryInformation;
    const organDonationsForm = this.$refs.organRecoveryData as OrganRecoveryData;
    // Build pageState for recovery information section
    if (!!recoveryInformation) {
      recoveryInformationPageState = recoveryInformation.buildRecoveryInformationPageState(this.deceasedDonor);
    }
    // Build pageState for organ recovery section
    if (!!organDonationsForm) {
      organDonationsFormPageState = organDonationsForm.buildOrganRecoveryForm(this.deceasedDonor);
    }

    this.$store.commit('pageState/set', {
      pageKey: 'recoveryDetails',
      value: {
        recoveryInformation: recoveryInformationPageState,
        organRecoveryData: organDonationsFormPageState
      }
    });
  }

  // Clear save notifications
  public resetSaveToolbar(): void {
    // Refer to the save provider that handle the areas present on this form component
    const gci = this.$refs.saveOrganRecovery as unknown as SaveProvider;
    // Reset the save provider's save toolbar
    gci.resetSaveToolbar();
  }

  // API response keys on the left, id for our UI on the right
  public idLookup(): IdLookup {
    const result = {};

    // Recovery Information
    const recoveryInformation = this.$refs.recoveryInformation as RecoveryInformation;
    if (recoveryInformation) {
      Object.assign(result, { ...recoveryInformation.idLookup });
    }

    // Organ Recovery Data
    const organRecoveryData = this.$refs.organRecoveryData as OrganRecoveryData;
    if (organRecoveryData) {
      Object.assign(result, { ...organRecoveryData.idLookup() });
    }
    
    return result;
  }

  // Emit event to parent so it can handle validations
  private handleErrors(errors: any) {
    this.$emit('handleErrors', errors);
  }

  // Emit event to parent so it can clear validations
  private clear() {
    this.$emit('clear');
  }

}
