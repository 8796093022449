
import { Getter, State } from "vuex-class";
import { Component, Prop, Vue } from "vue-property-decorator";
import { IdLookup } from '@/store/validations/types';
import CardSection from "@/components/shared/CardSection.vue";
import { RecipientJourney } from "@/store/recipientJourney/types";
import SubSection from "@/components/shared/SubSection.vue";
import { Recipient } from "@/store/recipients/types";
import { SaveableSection, SaveProvider, SaveResult } from "@/types";
import TextAreaInput from '@/components/shared/TextAreaInput.vue';
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import { SmallBowelDetails } from "@/store/organSpecificDetails/types";

export interface SmallBowelSpecificPageState {
  comments?: string;
  generalInfo: SmallBowelSpecificDetailsForm;
}

interface SmallBowelSpecificDetailsForm {
  includeStomach?: boolean;
}

@Component({
  components: {
    CardSection,
    SubSection,
    TextAreaInput,
    CheckboxInput
  }
})
export default class SmallBowelSpecificDetails extends Vue implements SaveableSection {
  // State
  @State(state => state.recipients.selectedRecipient) recipient!: Recipient;
  @State(state => state.journeyState.selectedJourney) journey!: RecipientJourney;
  @State(state => state.pageState.currentPage.smallBowelDetails) editState!: SmallBowelSpecificPageState;

  // Props
  @Prop({ default: false }) newJourney!: boolean;
  @Prop({ default: false }) canSave!: boolean;

  @Getter('prototypeFeatureEnabled', { namespace: 'features' }) private prototypeFeatureEnabled!: (featureName: string) => boolean;


  // Getters
  @Getter("clientId", { namespace: "recipients" }) recipientId!: string;
  @Getter("recipientAge", { namespace: "recipients" }) recipientAge!: number;
  @Getter("isOopRecipient", { namespace: 'recipients' }) isOopRecipient!: boolean;
  @Getter('journeyId', { namespace: 'journeyState' }) journeyId!: string|undefined;
  @Getter('canSaveGetter', { namespace: 'validations' }) private canSaveGetter!: (newRecord: boolean) => boolean;

  /**
   * Return true if Small Bowel specific details section can be edited
   *
   * cannot be edited if new journey
   * cannot be edited if journey is completed
   *
   * @returns {boolean} true if we can edit
   */
  get canEdit(): boolean{
    if (this.newJourney || this.journey.completed) {
      return false;
    }
    return true;
  }

  get smallBowelHeader() {
    if(this.editState?.generalInfo?.includeStomach){
      return `${this.$t('Small Bowel + Stomach')}`;
    } else {
      return `${this.$t('Small Bowel')}`;
    }
  }

  get showIncludeStomach(): boolean {
    const organSpecificDetails = this.journey?.organ_specific_details as SmallBowelDetails;
    return !organSpecificDetails?.stomach_included && this.recipientAge >= 18 ;
  }

  /**
   * Populates the Small Bowel  Specific Details form state with data from
   */
  public initializeForm(): void {
    // Initialize form edit state
    this.$store.commit("pageState/set", {
      pageKey: "smallBowelDetails",
      value: {
        comments: (this.journey || {}).comments,
        generalInfo: this.extractSmallBowelSpecificDetails(this.journey)
      }
    });
  }

  /**
   * Emits a loaded event after all subcomponents have finished loading.
   *
   * The Small Bowel Details card section emits a loaded event when
   * it finishes loading lookup tables (if any).
   *
   * @listens saveSmallBowelSpecificDetails#loaded
   * @emits loaded
   */
  public loaded(): void {
    this.initializeForm();
    this.$emit('loaded', 'smallBowelDetails');
  }

  /**
   * Generates Small Bowel Specific Details form state based on the selected journey
   *
   * @param journey current selected jounrey
   * @returns {SmallBowelSpecificDetailsForm}SmallBowel Specific Details form state
   */
  public extractSmallBowelSpecificDetails(journey: RecipientJourney): SmallBowelSpecificDetailsForm {
    if (!journey) {
      return {};
    }
    const organSpecificDetails = journey.organ_specific_details as SmallBowelDetails;
    const result= {
      includeStomach: organSpecificDetails.stomach_included,
    };
    return result;
  }

  /**
   * Saves the form edit state.
   *
   * Prepares an update payload for Small Bowel Specific Details,
   * dispatches a save action, and registers the save result.
   */
  public savePatch(): void {
    // Refer to the save provider that handles this form area
    const saveProvider = (this.$refs
      .saveSmallBowelSpecificDetails as unknown) as SaveProvider;
    // Generate payload based on current edit state
    const payload = {
      recipientId: this.recipientId,
      journeyId: this.journey._id!.$oid,
      journey: this.extractPatch()
    };
    // Dispatch save action and register the response
    this.$store.dispatch("journeyState/saveJourney", payload).then((success: SaveResult) => {
      // Reload recipient, journey
      const recipients_route = this.isOopRecipient ? 'recipients/getOop' : 'recipients/get';
      this.$store.dispatch(recipients_route, this.recipientId);
      this.$store.dispatch('journeyState/getJourney', this.journey._id!.$oid);
      // If successful, update the current recipient and show success notification
      this.$store.commit("journeyState/setJourney",success.responseData.journey);
      saveProvider.registerSaveResult(success);
    }).catch((error: SaveResult) => {
        // Show error notification
        saveProvider.registerSaveResult(error);
        // Emit event to handle errors
        this.$emit("handleErrors", error);
    });
  }

  /**
   * Gets changes from the editState as a patch for the journey's Small Bowel Specific Details
   *
   * If the edit state doesn't exist return {}
   *
   * @returns {any} object containing field changes
   */
  public extractPatch(): any {
    if (!this.editState || !this.editState.generalInfo) {
      return {};
    } else {
      return this.extractSmallBowelSpecificDetailsPatch(this.editState.generalInfo);
    }
  }

  // Clear save notifications
  public resetSaveToolbar(): void {
    // Refer to the save provider that handle the areas present on this form component
    const gci = (this.$refs.saveSmallBowelSpecificDetails as unknown) as SaveProvider;
    // Reset the save provider's save toolbar
    gci.resetSaveToolbar();
  }

  // API response keys on the left, id for our UI on the right
  public idLookup(): IdLookup {
    // General SmallBowel Details
    return {
      "comments"                                          : "smallBowel-comments",
      "organ_specific_details.stomach_included"           : "smallBowel-icludeStomach"
    };
  }

  /**
   * PRIVATE
   */

  /**
   * Gets changes from the edit state as a patch for the SmallBowel Specific Details
   *
   * @returns {RecipientJourney} patch object containing field changes
   */
  private extractSmallBowelSpecificDetailsPatch(smallBowelSpecificDetails: SmallBowelSpecificDetailsForm): RecipientJourney {
    return {
      comments: this.editState.comments,
      organ_specific_details: {
        stomach_included: smallBowelSpecificDetails.includeStomach
      }
    };
  }

  // Emit event to parent so it can handle validations
  private handleErrors(errors: any) {
    this.$emit("handleErrors", errors);
  }

  // Emit event to parent so it can handle clearing validations when saving
  private saving(formReference: string) {
    this.$emit("saving", formReference);
  }
}
