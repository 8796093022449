
import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { State, Getter } from 'vuex-class';
import { calculateAge, isMasked } from '@/utils';
import { Gender } from '@/store/lookups/types';
import TextInput from '@/components/shared/TextInput.vue';
import DateInput from '@/components/shared/DateInput.vue';
import TimeInput from '@/components/shared/TimeInput.vue';
import NumberInput from '@/components/shared/NumberInput.vue';
import SubSection from '@/components/shared/SubSection.vue';
import { DeceasedDonor } from '@/store/deceasedDonors/types';
import CardSection from '@/components/shared/CardSection.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { IdLookup } from '@/store/validations/types';
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import { SaveableSection, SaveProvider, SaveResult } from '@/types';
import {
  Hospital,
  HospitalOrgansTransplanted,
  ACTIVE_OOP_TRANSPLANT_PROGRAM,
  HospitalOption
} from '@/store/hospitals/types';
import ClinicalInformation from './ClinicalInformation.vue';

// Declare all v-model fields used in the form
export interface ReferralInformationForm {
  client_id?: number;
  national_donor_id?: string;
  itransplant_donor_id?: string;
  mrn?: string;
  referral_hospital?: string|null;
  out_of_province_donation?: boolean;
  tissue?: boolean;
  organ?: boolean;
  unknown?: boolean;
  referral_date?: string;
  referral_time?: string;
  first_name?: string;
  last_name?: string;
  sex?: string;
  date_of_birth?: string|null;
  age?: number;
}

@Component({
  components: {
    TextInput,
    DateInput,
    TimeInput,
    NumberInput,
    SubSection,
    CardSection,
    SelectInput,
    CheckboxInput,
  }
})

export default class ReferralInformation extends mixins(DateUtilsMixin) implements SaveableSection {
  // State
  @State(state => state.deceasedDonors.selected) private deceasedDonor!: DeceasedDonor;
  @State(state => state.pageState.currentPage.referralInformation) editState!: ReferralInformationForm;
  @State(state => state.pageState.currentPage.clinicalInformation) private clinicalInformationState!: ClinicalInformation;

  @Getter('hospitalOptionsOOP', { namespace: 'hospitals' }) hospitalOptionsOOP!: HospitalOption[];
  @Getter('hospitalOptionsOntarioAll', { namespace: 'hospitals' }) hospitalOptionsOntarioAll!: HospitalOption[];
  @Getter('sexOptions', { namespace: 'lookups' }) sexOptions!: boolean;
  @Getter('clientId', { namespace: 'deceasedDonors' }) clientId!: string|undefined;
  @Getter('getDobMinDateLimit', { namespace: 'lookups' }) private getDobMinDateLimit!: string;
  @Getter('getDobMaxDateLimit', { namespace: 'lookups' }) private getDobMaxDateLimit!: string;
  @Getter('getDonorAge', { namespace: 'deceasedDonors' }) getDonorAge!: number|undefined;
  @Getter('getNewDonorAge', { namespace: 'deceasedDonors' }) getNewDonorAge!: number|undefined;
  @Getter('isGroupWriteable', { namespace: 'validations' }) private isGroupWriteable!: (groupName: string) => boolean;
  @Getter('prototypeFeatureEnabled', { namespace: 'features' }) private prototypeFeatureEnabled!: (featureName: string) => boolean;

  // Properties
  @Prop({ default: false }) newDonor!: boolean;
  @Prop({ default: false }) canSave!: boolean;

  // Lookup tables to be loaded by the CardSection component
  public lookupsToLoad = ['gender'];

  get fromITransplant(): boolean {
    if (!this.deceasedDonor) return false;
    if (this.deceasedDonor && !this.deceasedDonor.indicators) return false;
    return this.deceasedDonor.indicators?.from_itransplant ? true : false;
  }

  /**
   * Checks if TGLN ID can be edited
   * Editable only when its blank
   *
   * @returns {boolean} true if the field can be edited, false otherwise
   */
  get canEditTglnId(): boolean {
    return this.deceasedDonor.deceased_donor_id ? true : false;
  }

  get referralHospitalsList(): HospitalOption[] {
    if(this.editState.out_of_province_donation) {
      return this.hospitalOptionsOOP;
    } else {
      return this.hospitalOptionsOntarioAll;
    }
  }

  // called when checking and unchecking out of province
  // clears referral hospital and declaration hospital and loads new hospitals dropdown list
  public clearReferralHospital(): void {
    Vue.set(this.editState, 'referral_hospital', undefined);
    Vue.set(this.clinicalInformationState, 'declarationHospital', undefined);
  }

  public loadHospitals(): void {
    this.$store.dispatch('hospitals/load');
    this.$store.dispatch('hospitals/load', ACTIVE_OOP_TRANSPLANT_PROGRAM);
  }

  /**
   * Set the default value of declaration hospital to the same value as referral hospital
   */
  public setDefaultDelarationHospital(): void {
    Vue.set(this.clinicalInformationState, 'declarationHospital', this.editState.referral_hospital);
  }

  // Event handlers

  /**
   * Vue lifecyle hook, for when the reactivity system has taken control of the Document Object Model.
   *
   * @listens #mounted
   */
  private mounted(): void {
    this.loadHospitals();
    this.initializeForm();
  }

  /**
   * Emits a loaded event after all subcomponents have finished loading.
   *
   * The Referral Information card section emits a loaded event when it finishes loading lookup tables. This is the
   * only loading process that this form component is responsible for, so it immediately emits its own loaded event.
   *
   * @listens referralInformation#loaded
   * @emits loaded
   */
  public loaded(): void {
    this.$emit('loaded', 'referralInformation');
  }

  // Private methods

  /**
   * Populates the Referral Information form state with data from the selected Deceased Donor.
   */
  public initializeForm(): void {
    this.$store.commit('pageState/set', {
      pageKey: 'referralInformation',
      value: this.buildReferralInformationForm(this.deceasedDonor)
    });
  }

  /**
   * Generates Referral Information form state based on a Deceased Donor document
   *
   * @param deceasedDonor Deceased Donor document provided by API
   * @returns {ReferralInformationForm} Referral Information form state
   */
  public buildReferralInformationForm(deceasedDonor?: DeceasedDonor): ReferralInformationForm {
    // Return empty object if there is no data document
    if (!deceasedDonor) {
      return {};
    }
    // Sanitize nested objects, so their parameters can easily be read as 'undefined' instead of raising type errors
    const indicators = deceasedDonor.indicators || {};
    const patientProfile = deceasedDonor.patient_profile || {};
    const birth = patientProfile.birth || {};
    const referral = deceasedDonor.referral || {};
    let hospitalId = referral.referring_hospital_id ? referral.referring_hospital_id.$oid : undefined;

    // Return parameters extracted from data document based on structure of form state interface
    return {
      client_id: deceasedDonor.deceased_donor_id,
      national_donor_id: patientProfile.ctr ? patientProfile.ctr.national_donor_id : undefined,
      mrn: referral.referring_hospital_mrn,
      // TODO: check if phone number should be from hospital collection
      referral_hospital: hospitalId,
      out_of_province_donation: indicators.out_of_province,
      tissue: indicators.tissue_donor,
      organ: indicators.organ_donor,
      unknown: indicators.unknown_donor_type,
      referral_date: this.parseDateUiFromDateTime(referral.referral_date),
      referral_time: this.parseTimeUiFromDateTime(referral.referral_date),
      first_name: patientProfile.first_name,
      last_name: patientProfile.last_name,
      sex: patientProfile.sex || undefined,
      date_of_birth: this.parseDateUi(birth.date),
      age: patientProfile.age,
    };
    // TODO: itransplant_donor_id
  }

  // Translate the form structure into the Donor data structure
  public extractPatch(): DeceasedDonor {
    const updatedProfile = {
      first_name: this.editState.first_name,
      last_name: this.editState.last_name,
      birth: {
        date: this.sanitizeDateApi(this.editState.date_of_birth)
      },
      sex: this.editState.sex || null,
      age: this.newDonor ? this.getNewDonorAge : this.getDonorAge,
    };
    const referral_hospital = this.editState.referral_hospital;
    const referral_date = this.editState.referral_date;
    const referral_time = this.editState.referral_time ? this.editState.referral_time : "00:00";
    const updatedReferral = {
      referring_hospital_mrn: this.editState.mrn,
      referring_hospital_id: referral_hospital ? { $oid: referral_hospital } : null,
      referral_date: this.sanitizeDateTimeApi(referral_date, referral_time),
    };
    return {
      deceased_donor_id: this.deceasedDonor.deceased_donor_id ? undefined : this.editState.client_id, // only send if it doesn't exist
      patient_profile: updatedProfile,
      referral: updatedReferral,
      indicators: {
        out_of_province: this.editState.out_of_province_donation,
        tissue_donor: this.editState.tissue,
        organ_donor: this.editState.organ,
        unknown_donor_type: this.editState.unknown,
      }
    };
  }

  // Clear save notifications
  public resetSaveToolbar(): void {
    // Refer to the save provider that handle the areas present on this form component
    const saveProvider = this.$refs.saveReferralInformation as unknown as SaveProvider;
    // Reset the save provider's save toolbar
    saveProvider.resetSaveToolbar();
  }

  // Handle saving triggered by local save button
  public savePatch(): void {
    // Refer to the save provider that handles this form area
    const saveProvider = this.$refs.saveReferralInformation as unknown as SaveProvider;
    // Report to parent that saving has began
    this.$emit('save', 'referralInformation');
    // Generate payload based on current edit state
    const donorPatch = this.extractPatch();
    // Dispatch save action and register the response
    this.$store.dispatch('deceasedDonors/saveDonor', { clientId: this.clientId, donor: donorPatch }).then((success: SaveResult) => {
      // If successful, update the current donor and show success notification
      this.$store.commit('deceasedDonors/set', success.responseData.donor);
      saveProvider.registerSaveResult(success);
      // Request donor page reload data that might be affected by this form changing
      this.$emit('reload');
      this.initializeForm();
    }).catch((error: SaveResult) => {
      // Emit event to handle errors
      this.$emit('handleErrors', error);
      // Show error notification
      saveProvider.registerSaveResult(error);
    });
  }

  // API response keys on the left, id for our UI on the right
  public idLookup: IdLookup = {
    'deceased_donor_id'                                    : 'referralInformation-client_id',
    'referral.referring_hospital_mrn'                      : 'referralInformation-mrn',
    'referral.referring_hospital_id'                       : 'referralInformation-referral_hospital',
    'indicators.out_of_province'                           : 'referralInformation-out_of_province_donation',
    'indicators.tissue_donor'                              : 'referralInformation-tissue',
    'indicators.organ_donor'                               : 'referralInformation-organ',
    'indicators.unknown_donor_type'                        : 'referralInformation-unknown',
    'referral.referral_date'                               : 'referralInformation-referral_date',
    'referral.referral_time'                               : 'referralInformation-referral_time',
    'patient_profile.first_name'                           : 'referralInformation-first_name',
    'patient_profile.last_name'                            : 'referralInformation-last_name',
    'patient_profile.sex'                                  : 'referralInformation-sex',
    'patient_profile.birth.date'                           : 'referralInformation-date_of_birth',
    'patient_profile.age'                                  : 'referralInformation-age',
    'referral.referralInformation-tgln-id'                 : 'referralInformation-tgln-id',
    'referral.referralInformation-national_donor_id'       : 'referralInformation-national_donor_id',
    'referral.referralInformation-mrn'                     : 'referralInformation-mrn',
    'referral.referralInformation-out_of_province_donation': 'referralInformation-out_of_province_donation'
  }
}
