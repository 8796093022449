

import { Component, Vue } from 'vue-property-decorator';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import SubSection from '@/components/shared/SubSection.vue';
import LivingDonorMedicalHistoryJson from './json/LivingDonorMedicalHistory.json';
import DateInput from '@/components/shared/DateInput.vue';
import TextAreaInput from '@/components/shared/TextAreaInput.vue';

@Component({
  components: {
    SubSection,
    SaveToolbar,
    SelectInput,
    DateInput,
    TextAreaInput
  }
})
export default class LivingDonorMedicalHistory extends Vue {  
  title = 'Medical History';
  private previous_discussion = '';
  private medicalList = LivingDonorMedicalHistoryJson;
  private selectedMedicalHistory :any = []
  private getOptions() {
    let options :any  = [];
    Object.values(this.medicalList).map((items: any) => {
      items.map((item: any) => {
        options.push({code: item.md_code, value: item.md_label });
      });
    
    });
    return options;
  }

  private addMedicalHistory(value: any) {
    if (!this.selectedMedicalHistory.some((e : any) => e.id === value)) {
      
      this.selectedMedicalHistory.push({id: value});
    } else {
    const pos = this.selectedMedicalHistory.findIndex((e : any) => e.id === value);
    if (pos >= 0) {
      this.selectedMedicalHistory.splice(pos, 1);
      }
    }
  }

  private checkIndex(value: any) {
    return this.selectedMedicalHistory.some((e : any) => e.id === value);
  }


  
}
