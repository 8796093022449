
import { Getter, State } from 'vuex-class';
import { Component, Vue, Prop } from 'vue-property-decorator';
import CardSection from "@/components/shared/CardSection.vue";
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import SelectInput from '@/components/shared/SelectInput.vue';

@Component({
  components: {
    CardSection,
    SaveToolbar,
    SelectInput
  }
})
export default class LivingDonation extends Vue {  
  @Prop({ default: null }) organ!: string|null;
  title = 'Living Donation';
  private previous_discussion = '';
  @Getter('prototypeFeatureEnabled', { namespace: 'features' }) private prototypeFeatureEnabled!: (featureName: string) => boolean;
  private categoryOptions = [{code: 0, value: 'Yes'}, {code: 1, value: 'No'},{code: 2, value: 'Unknown'}];
  

  private mounted(): void {
    let items = localStorage.getItem(`${this.organ}livingDonationStorage`);

    if (items) {
      let parsed = JSON.parse(items);
      this.previous_discussion = parsed.previous_discussion;
    }
  
  }

  public performSave(): void {
    const livingDonationStorage = {
    "previous_discussion":  this.previous_discussion,
  };

    localStorage.setItem(`${this.organ}livingDonationStorage`, JSON.stringify(livingDonationStorage));
  }
}
