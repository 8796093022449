

import { Component, Vue } from 'vue-property-decorator';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import SubSection from '@/components/shared/SubSection.vue';
import comorbiditiesJson from './json/ComorbiditiesJson.json';
import TempCormorditiesInfo from './json/TempCormorditiesInfo.json';
import DateInput from '@/components/shared/DateInput.vue';

@Component({
  components: {
    SubSection,
    SaveToolbar,
    SelectInput,
    DateInput
  }
})
export default class Comorbidities extends Vue {  
  title = 'Comorbidities';
  private previous_discussion = '';
  private comorbiditiesList = comorbiditiesJson;
  private selectedComorbidities :any = []
  private comorbiditiesArray = TempCormorditiesInfo
  private getOptions() {
    let options :any  = [];
    Object.values(this.comorbiditiesList).map((items: any) => {
      items.map((item: any) => {
        options.push({code: item.como_code, value: item.como_label });
      });
    
    });
    return options;
  }

  private addComorbidity(value: any) {
    if (!this.selectedComorbidities.some((e : any) => e.id === value)) {
      
      this.selectedComorbidities.push({id: value});
    } else {
    const pos = this.selectedComorbidities.findIndex((e : any) => e.id === value);
    if (pos >= 0) {
      this.selectedComorbidities.splice(pos, 1);
      }
    }
  }

  private checkIndex(value: any) {
    return this.selectedComorbidities.some((e : any) => e.id === value);
  }


  
}
