
import axios from 'axios';
import { APIRoute, EP } from '@/api-endpoints';

import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { uniqueElements } from '@/utils';
import { Getter, State } from "vuex-class";
import { TagObject } from '@/store/utilities/types';
import { LivingDonor, LivingDonorIntendedRecipient } from '@/store/livingDonors/types';
import TextInput from '@/components/shared/TextInput.vue';
import DateInput from '@/components/shared/DateInput.vue';
import SubSection from '@/components/shared/SubSection.vue';
import CardSection from '@/components/shared/CardSection.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import SelectOtherInput from '@/components/shared/SelectOtherInput.vue';
import { VueTagsInput, createTag, createTags } from '@johmun/vue-tags-input';
import { GenericCodeValue, NumericCodeValue, ObjectId } from '@/store/types';
import { TableConfig, SaveableSection, SaveProvider, SaveResult } from '@/types';
import { Recipient } from "@/store/recipients/types";

interface LinkToDonorHistoryRow {
  _id?: ObjectId;
  linkDate: string;
  removedDate: string;
  donorId: string;
  donorFirstName: string;
  donorLastName: string;
  recipientRelationship: string;
}

@Component({
  components: {
    TextInput,
    DateInput,
    SubSection,
    SelectInput,
    CardSection,
    VueTagsInput,
    CheckboxInput,
    SelectOtherInput,
  }
})
export default class LinkToDonor extends mixins(DateUtilsMixin) {
  // Component properties
  @Prop({ default: false }) canSave!: boolean;
  @Prop({ default: false }) newRecipient!: boolean;

  // Vue-x store state
  @State(state => state.recipients.selectedRecipient) selectedRecipient!: Recipient;
  @State(state => state.lookups.relationship) relationshipLookup!: NumericCodeValue[];
  // @State(state => state.pageState.currentPage.linkToDonor) editState!: LinkToDonorForm;
  @State(state => state.lookups.unlink_reason) recipientUnlinkReasonsLookup!: NumericCodeValue[];
  // @State(state => state.recipients.selectedIntendedDonor) selectedIntendedDonor!: RecipientIntendedDonor|null;

  // Vue-x store getters
  @Getter('clientId', { namespace: 'recipients' }) recipientId!: string;
  // @Getter('showList', { namespace: 'donors' }) donorList!: { entries: any[] };
  @Getter('translateError', { namespace: 'utilities' }) private translateError!: (error?: any, field?: string|null) => string;
  @Getter('lookupValue', { namespace: 'lookups' }) lookupValue!: (code: string|undefined, lookupId: string) => any;
  // @Getter('selectedRecipientJourney', { namespace: 'recipients' }) selectedRecipientJourney!: RecipientJourney|null;
  @Getter('getHospitalAbbreviation', { namespace: 'hospitals' }) getHospitalAbbreviation!: (hospitalCode?: string|null) => string|null;
  @Getter('prototypeFeatureEnabled', { namespace: 'features' }) private prototypeFeatureEnabled!: (featureName: string) => boolean;

  // Lookup tables to be loaded by the CardSection component
  public lookupsToLoad = [
    'relationship',
    'unlink_reason',
  ];

  // Local component state
  private isLoadingDonorList = false;
  private linkedLivingDonors: LivingDonor[] = [];

  // Configure table for the sub-section component
  get linkToDonorTableConfig(): TableConfig {
    return {
      data: this.linkToDonorHistoryRows,
      columns: [
        { label: this.$t('link_date_column').toString(), field: 'linkDate' },
        { label: this.$t('removed_date_column').toString(), field: 'removedDate' },
        { label: this.$t('donor_id_column').toString(), field: 'donorId' },
        { label: this.$t('donor_first_name_column').toString(), field: 'donorFirstName' },
        { label: this.$t('donor_last_name_column').toString(), field: 'donorLastName' },
        { label: this.$t('recipient_relationship_column').toString(), field: 'recipientRelationship' },
      ],
      empty: this.$t('link_history_empty').toString(),
      createButton: false,
    };
  }

  // Rows to show in sub-section table
  get linkToDonorHistoryRows(): LinkToDonorHistoryRow[] {
    if (!this.linkedLivingDonors) return [];

    const rows: LinkToDonorHistoryRow[] = this.linkedLivingDonors.map((linkedLivingDonor: LivingDonor): any => {
      const donorId = linkedLivingDonor.living_donor_id;
      const livingDonorJourneys = linkedLivingDonor.living_donor_info?.journeys || [];
      const livingDonorJourney = livingDonorJourneys.length > 0 ? livingDonorJourneys[0] : {};
      const intendedRecipients = livingDonorJourney?.intended_recipients || [];
      const selectedIntendedRecipient = intendedRecipients.find((intendedRecipient: LivingDonorIntendedRecipient) => {
        return intendedRecipient?.recipient_id?.$oid == this.selectedRecipient?._id?.$oid;
      });

      const recipientRelationshipCode = selectedIntendedRecipient?.donor_relationship_code;
      const recipientRelationship = recipientRelationshipCode ? this.lookupValue(recipientRelationshipCode.toString(), 'relationship') : null;
      return {
        _id: linkedLivingDonor._id,
        linkDate: this.parseDisplayDateUi(selectedIntendedRecipient?.link_date) || '-',
        removedDate: this.parseDisplayDateUi(selectedIntendedRecipient?.unlink_date) || '-',
        recipientRelationship: recipientRelationship || '-',
        donorId:  donorId ? donorId.toString() : '-',
        donorFirstName: linkedLivingDonor?.patient_profile?.first_name || '-',
        donorLastName: linkedLivingDonor?.patient_profile?.last_name || '-',
      };
    }).reverse();
    return rows;
  }

  get isLoaded(): boolean {
    return !this.isLoadingDonorList;
  }

  // Initialize the form before the page mounts
  private mounted(): void {
    this.loadAdditionalDataIntendedDonors();
  }

  private loadAdditionalDataIntendedDonors(): void {
    this.isLoadingDonorList = true;
    this.linkedLivingDonors = [];
    const url = APIRoute(EP.recipients.additional_data.linked_living_donors, [[':recipientId', this.recipientId]]);
    axios.get(url).then((response: any) => {
      this.linkedLivingDonors = response?.data?.linked_living_donors || [];
      this.isLoadingDonorList = false;
    }).catch((error: any) => {
      console.warn(error);
      this.isLoadingDonorList = false;
    });
  }

  // Watch for changes to Living Recipient state in vue-x store from elsewhere
  @Watch('recipient', { immediate: true, deep: true })
  private onRecipientChanged(): void {
    this.loadAdditionalDataIntendedDonors();
  }
}
