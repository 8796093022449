
import { State, Getter } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';
import { organCodeLookup } from '@/types';
import { OrganCodeValue } from '@/store/lookups/types';
import { RecipientJourney } from '@/store/recipientJourney/types';

@Component
export default class CurrentOffers extends Vue {
  @State(state => state.journeyState.selectedJourney) journey!: RecipientJourney;

  @Getter("includeStomach", { namespace: "recipients" }) includeStomach!: (journeyId?: string) => boolean;
  @Getter('journeyId', { namespace: 'journeyState', }) journeyId!: string|undefined;

  /**
   * Return titlized organ name
   *
   * @returns {string} organ name titlized
   */
  get organDescription(): string {
    let organName = this.organComponent;
    if (this.includeStomach(this.journeyId) && this.journey.organ_code == OrganCodeValue.SmallBowel) {
      return organName = this.$t(`${organName} + Stomach`).toString();
    } else {
      return this.$t(organName).toString();
    }
  }

  /**
   * Gets the current journey as lower case
   *
   * Using the organ code, return the lower case organ name
   *
   * @returns {string} organ as lower case
   */
  get organComponent(): string {
    return organCodeLookup[`${this.journey ? this.journey.organ_code : ''}`] || '';
  }

  private openCompareModal(donorId: any) {
    this.$emit('openCompareModal', donorId);
  }
}
