
  import { Getter, State } from 'vuex-class';
  import { mixins } from "vue-class-component";
  import { Component, Vue } from 'vue-property-decorator';
  import CardSection from "@/components/shared/CardSection.vue";
  import SaveToolbar from '@/components/shared/SaveToolbar.vue';  
  import { VueGoodTable } from 'vue-good-table';
  import SelectInput from '@/components/shared/SelectInput.vue';
  import CheckboxInput from '@/components/shared/CheckboxInput.vue';
  import TextInput from '@/components/shared/TextInput.vue';
  import TextAreaInput from '@/components/shared/TextAreaInput.vue';
  import SubSection from '@/components/shared/SubSection.vue';
  import { TableConfig } from '@/types';
  import { Recipient } from "@/store/recipients/types";
  import { UserDetails } from '@/store/userAccounts/types';
  import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
  import NumberInput from '@/components/shared/NumberInput.vue';
  import DateInput from '@/components/shared/DateInput.vue';
      
    interface TableRow {
    date: string;
    systolic_pa?: string;
    diastolic_pa?: string;
    mean_pa?: string;
    mean_pcw?: string;
    cardiac_index?: string;
    cardiac_output?: string;
    pvr?: string;
    pvr_reactivity?:string;

  }

@Component({
  components: {
    CardSection,
    SaveToolbar,
    SubSection,
    SelectInput,
    TextInput,
    CheckboxInput,
    TextAreaInput,
    VueGoodTable,
    DateInput,
    NumberInput
  }
})
export default class RecipientDocuments extends mixins(DateUtilsMixin) {  

  @State(state => state.recipients.selectedRecipient) recipient!: Recipient;
  @State(state => state.userAccounts.selected) private selected!: UserDetails;
  @Getter('getUser', { namespace: 'users' }) private getUser!: any;

  

  private id = null;
  private date = '';
  private systolic_pa = '';
  private diastolic_pa = '';
  private mean_pa = '';
  private mean_pcw = '';
  private cardiac_index = '';
  private cardiac_output = '';
  private pvr = '';
  private pvr_reactivity = '';
  private isTableUpdating = false;
  private taskInProgress = false;
  public perPage = 10;
  public currentPageTable = 1;
  private documents: any = [];
  private editstate = false

  private pvrOptions = [{code: 0, value: 'Reactive'}, {code: 1, value: 'Non-reactive'},{code: 2, value: 'Test not done'},{code: 3, value: 'Unknown'}];

  get tableRows() {
    const documents = this.documents || [];
    return documents.map((item: any) => {
      return {
        id: item.id,
        date: item.date,
        pvr_reactivityValue : item.pvr_reactivity ?  this.getPvrValue(item.pvr_reactivity) : null,
        systolic_pa: item.systolic_pa || '',
        diastolic_pa: item.diastolic_pa || '',
        mean_pa: item.mean_pa || '',
        mean_pcw: item.mean_pcw || '',    
        cardiac_index: item.cardiac_index || '',
        cardiac_output: item.cardiac_output || '',
        pvr: item.pvr || '',
        pvr_reactivity: item.pvr_reactivity || '',

      };
    });
  }
  get tableConfig(): TableConfig {
  const tableConfig = [
    { label: "Date of test",
      field: 'date',
      width: '12%',
      sortable: true
    },
    { label: "Systolic PA",
      field: 'systolic_pa',
      sortable: true
    },
    { label: "Diastolic PA",
      field: 'diastolic_pa',
      sortable: true
    },
    { label: "Mean PA",
      field: 'mean_pa',
      sortable: true
    },
    { label: "Mean PCW",
      field: 'mean_pcw',
      sortable: true
    },
    { label: "Cardiac Index",
      field: 'cardiac_index',
      sortable: true
    },
    { label: "Cardiac Output",
      field: 'cardiac_output',
      sortable: true
    },
    { label: "PVR",
      field: 'pvr',
      sortable: true
    },
    { 
      field: 'pvr_reactivity',
      hidden: 'true'
    },
    { label: "PVR Reactivity",
      field: 'pvr_reactivityValue',
      width: '10%',
      sortable: true
    }
  ];

  return {
    data: this.tableRows,
    columns: tableConfig,
    createButton: true,
    createText: 'Create Hemodynamic Information',
    sortOptions: {
      enabled: true,
      initialSortBy: {field: 'date', type: 'desc'}
    },
    paginationOptions: {
    enabled: true,
    mode: 'records',
    perPage: this.perPage,
    perPageDropdown: [10, 25, 50],
    setCurrentPage: this.currentPageTable,
    dropdownAllowAll: true,
    nextLabel: this.$t('table.older'),
    prevLabel: this.$t('table.newer'),
    rowsPerPageLabel: this.$t('table.results'),
    },
  };
  }

    private editSelected(row :any) {
      if (row) {
        this.editstate = true;
        this.id = row.row.id;
        this.date = row.row.date;
        this.systolic_pa = row.row.systolic_pa;
        this.diastolic_pa = row.row.diastolic_pa;
        this.mean_pa = row.row.mean_pa;
        this.mean_pcw = row.row.mean_pcw;        
        this.cardiac_index = row.row.cardiac_index;
        this.cardiac_output = row.row.cardiac_output;
        this.pvr = row.row.pvr;
        this.pvr_reactivity = row.row.pvr_reactivity;
      }
    }

  get emptyMessage(): string {
    if (!this.documents) {
      return this.$t('loading').toString();
    } else {
      return this.$t('use_form_below').toString();
    }
  }

  public resetSaveToolbar(): void {
    // Refer to the save toolbar that handles this page
    const saveToolbar = (this.$refs.uploadDocument as SubSection).$refs['save-recipient-upload-document'] as SaveToolbar;
    saveToolbar.reset();
  }


  get getUserFullName(): string|undefined {
    let user = this.getUser;

    return user.name;
  }

  public getPvrValue(item: any) {
    return this.pvrOptions[item as any].value;
  }

  public clearForm(): any {
    this.date = '';
    this.systolic_pa = '';
    this.diastolic_pa = '';
    this.mean_pa = '';
    this.mean_pcw = '';        
    this.cardiac_index = '';
    this.cardiac_output = '';
    this.pvr = '';
    this.pvr_reactivity = '';
    this.$emit('clear');
    this.resetSaveToolbar();
    this.editstate = false;
  }

  private mounted(): void {
    let documents = localStorage.getItem("lungHemodynamicInformation");

    if (documents) {
      this.documents = JSON.parse(documents);
    }
  
  }

  public uploadDocument(): any {
    this.isTableUpdating = true;
    let documents = this.documents || [];

    if (!this.editstate) {
      const payload: any = {
        id: Math.random().toString(16).slice(2),
        date: this.date,
        systolic_pa: this.systolic_pa,
        diastolic_pa: this.diastolic_pa,
        mean_pa: this.mean_pa,
        mean_pcw : this.mean_pcw,
        cardiac_index: this.cardiac_index,
        cardiac_output: this.cardiac_output,
        pvr: this.pvr,
        pvr_reactivity: this.pvr_reactivity
      };

      documents.push( payload );
    } else {
      documents.map((item: any) => {
        if (item.id === this.id) {
        item.date = this.date;
        item.systolic_pa = this.systolic_pa;
        item.diastolic_pa = this.diastolic_pa;
        item.mean_pa = this.mean_pa;
        item.mean_pcw = this.mean_pcw;
        item.cardiac_index = this.cardiac_index;
        item.cardiac_output = this.cardiac_output;
        item.pvr = this.pvr;
        item.pvr_reactivity = this.pvr_reactivity;
        }
      });

    }
    this.documents = documents;
    localStorage.setItem("lungHemodynamicInformation", JSON.stringify(documents));

    this.isTableUpdating = false;
    this.clearForm();
  }
}
