
  import {
    Getter,
    State,
  } from 'vuex-class';
  import {
    mixins
  } from "vue-class-component";
  import {
    Component,
    Vue,
    Prop
  } from 'vue-property-decorator';
  import CardSection from "@/components/shared/CardSection.vue";
  import SaveToolbar from '@/components/shared/SaveToolbar.vue';
  import {
    VueGoodTable
  } from 'vue-good-table';
  import SelectInput from '@/components/shared/SelectInput.vue';
  import CheckboxInput from '@/components/shared/CheckboxInput.vue';
  import {
    GenericCodeValue
  } from '@/store/types';
  import TextInput from '@/components/shared/TextInput.vue';
  import TextAreaInput from '@/components/shared/TextAreaInput.vue';
  import SubSection from '@/components/shared/SubSection.vue';
  import DateInput from '@/components/shared/DateInput.vue';
  import {
    CoordinatorOptions
  } from '@/store/coordinators/types';
  import {
    Hospital
  } from '@/store/hospitals/types';
  import {
    TableConfig
  } from '@/types';
  import {
    Recipient
  } from "@/store/recipients/types";
  import {
    UserDetails
  } from '@/store/userAccounts/types';
  import {
    DateUtilsMixin
  } from "@/mixins/date-utils-mixin";

  interface TableRow {
    id: string;
    category ? : string;

    date ? : string;
    fileName ? : string;

    fileType ? : string;
    description ? : string;
    uploadedBy ? : string;
  }

  @Component({
    components: {
      CardSection,
      SaveToolbar,
      SubSection,
      DateInput,
      SelectInput,
      TextInput,
      CheckboxInput,
      TextAreaInput,
      VueGoodTable
    }
  })
  export default class RecipientDocuments extends mixins(DateUtilsMixin) {

    @State(state => state.recipients.selectedRecipient) recipient!: Recipient;
    @State(state => state.userAccounts.selected) private selected!: UserDetails;
    @State(state => state.pageState.currentPage.journeyStatus) editState!: any;


    @Prop({
      default: null
    }) columns!: any;
    @Prop({
      default: null
    }) tableData!: any;
    @Prop({
      default: null
    }) title!: string;
    @Prop({
      default: null
    }) localStorageRef!: string;
    @Getter('getUser', {
      namespace: 'users'
    }) private getUser!: any;

    @Prop({
      default: ''
    }) currentHospital!: string;
    @Getter('prototypeFeatureEnabled', {
      namespace: 'features'
    }) private prototypeFeatureEnabled!: (featureName: string) => boolean;
    @Getter('regionTransplantOptionsByOrgan', {
      namespace: 'hospitals'
    }) regionTransplantOptionsByOrgan!: (organCode ? : number) => GenericCodeValue[];
    @Getter('currentFollowUpHospitalId', {
      namespace: 'journeyState'
    }) currentFollowUpHospitalId!: string | null;
    @Getter('followUpCoordinatorOptions', {
      namespace: 'coordinators'
    }) followUpCoordinatorOptions!: CoordinatorOptions[];
    @Getter('getHospitalById', {
      namespace: 'hospitals'
    }) getHospitalById!: (hospitalCode ? : string | null) => Hospital | null;

    loaded() {
      // Parse tasks from local storage if found, otherwise default to JSON file contents
      let formState;
      const defaultState = {
        transfers: this.tableData.rows
      };
      const items: any = localStorage.getItem(this.localStorageRef);
      if (items) {
        try {
          formState = JSON.parse(items);
        } catch (error) {
          console.warn('Warning: Cannot parse tasklist from local storage, setting default tasks.', error);
          formState = defaultState;
        }
      } else {
        formState = defaultState;
      }
      this.$store.commit("pageState/set", {
        pageKey: "journeyStatus",
        value: formState,
      });
      this.transfers = formState.transfers || [];
    }

    private mounted(): void {
      Promise.all([
        this.$store.dispatch('hospitals/load'),
      ]).finally(() => {
        this.loadFollowUpCoordinators(this.currentFollowUpHospitalId);
        this.loaded();
      });
    }

    private id = null;
    private date = null;
    private hospital = '';
    private fromHospital = "";
    private coordinator = '';
    private isTableUpdating = false;
    private taskInProgress = false;
    public perPage = 10;
    public currentPageTable = 1;
    private transfers: any = [];
    private isLoading = true;
    private isSubSectionLoading = true;
    private isLoadingCoordinators = false;
    private editstate = false
    public hospitalsToLoad = ['all'];


    // Transplant programs that have access to the same {organ} as the selected journey
    get followUpHospitalOptions(): GenericCodeValue[] {

      return this.regionTransplantOptionsByOrgan(3);
    }

    // Process changes to Follow-Up Hospital dropdown in edit state=
    private onFollowUpHospitalChanged(newValue: string | null): void {
      // Populate Transfer Date with today's date
      Vue.set(this, 'date', new Date().toLocaleDateString('en-CA'));

      // Clear selection from Recipient Coordinator dropdown
      Vue.set(this, 'coordinator', null);

      // Reload coordinator options
      this.loadFollowUpCoordinators(newValue);

      if (this.transfers.length == 0) {
        this.fromHospital = this.currentHospital;
      } else {
        let lastElement = this.transfers[this.transfers.length - 1];
        this.fromHospital = lastElement.to_hospital;
      }


    }


    private loadFollowUpCoordinators(hospitalId: string | null): void {
      // Clear out any previously loaded data
      this.$store.commit('coordinators/clear', 'followUpCoordinators');

      // Check if hospital specified
      if (!hospitalId) {
        // Don't need to load any coordinators
        this.isLoadingCoordinators = false;
        this.checkIfLoadingComplete();
      } else {
        // Start loading coordinators
        this.isLoadingCoordinators = true;
        this.$store.dispatch('coordinators/load', {
          hospitalId,
          key: 'followUpCoordinators'
        }).then(() => {
          // Done loading coordinators
          this.isLoadingCoordinators = false;
          this.checkIfLoadingComplete();
        }).catch((err) => {
          console.warn(err);
        });
      }
    }


    private checkIfLoadingComplete(): void {
      if (!this.isLoading) return;
      this.isLoading = this.isSubSectionLoading || this.isLoadingCoordinators;
    }

    get tableRows() {
      const transfers = this.transfers || [];
      const results = transfers.map((item: any) => {
        return {
          id: item.id,
          date: this.parseDisplayDateUi(item.date),
          to_hospital: item.to_hospital,
          to_hospital_value: this.getHospitalName(item.to_hospital),
          from_hospital: item.from_hospital_value,
          from_hospital_value: this.getHospitalName(item.from_hospital),
          coordinator: item.coordinator
        };
      });
      return results;
    }

    get tableConfig(): TableConfig {
      return {
        data: this.tableRows,
        columns: this.columns,
        createButton: false,
        sortOptions: this.tableData.sortOptions,
        paginationOptions: {
          enabled: true,
          mode: 'records',
          perPage: this.perPage,
          perPageDropdown: [10, 25, 50],
          setCurrentPage: this.currentPageTable,
          dropdownAllowAll: true,
          nextLabel: this.$t('table.older'),
          prevLabel: this.$t('table.newer'),
          rowsPerPageLabel: this.$t('table.results'),
        },
      };
    }

    private getHospitalName(id: any) {
      const hospital = this.getHospitalById(id);
      return hospital ? hospital.hospital_name_info.name : null;
    }

    private editSelected(row: any) {
      if (row) {
        this.id = row.row.id;
        this.editstate = true;
        this.date = row.row.date;
        this.fromHospital = row.row.fromHospital;
        this.hospital = row.row.to_hospital;
          this.loadFollowUpCoordinators(this.hospital);
        this.coordinator = row.row.coordinator;
      }
    }

    get emptyMessage(): string {
      if (!this.transfers) {
        return this.$t('loading').toString();
      } else {
        return this.$t('use_form_below_transfer').toString();
      }
    }


    public resetSaveToolbar(): void {
      // Refer to the save toolbar that handles this page
      const saveToolbar = (this.$refs.uploadDocument as SubSection).$refs[
        'save-recipient-upload-document'] as SaveToolbar;
      saveToolbar.reset();
    }


    get getUserFullName(): string | undefined {
      let user = this.getUser;
      return user.name;
    }

    public getOptionValue(item: any, options: any) {
      return options[item as any].value;
    }

    public clearForm(): any {
      this.id = null;
      this.hospital = '';
      this.coordinator = '';
      this.$emit('clear');
      this.resetSaveToolbar();
      this.editstate = false;
    }

    public uploadDocument(): any {
      this.isTableUpdating = true;
      let transfers = this.transfers || [];

      if (!this.editstate) {
        const payload: any = {
          id: Math.random().toString(16).slice(2),
          date: this.date,
          to_hospital: this.hospital,
          from_hospital: this.fromHospital,
          coordinator: this.coordinator,
        };

        transfers.push(payload);
      } else {
        transfers.map((item: any) => {
          if (item.id === this.id) {
            item.date = this.date;
            item.to_hospital = this.hospital;
            item.coordinator = this.coordinator;
          }
        });

      }

      this.transfers = transfers;

      const defaultState = {
        transfers: transfers
      };

      localStorage.setItem(this.localStorageRef, JSON.stringify(defaultState));

      this.isTableUpdating = false;
      this.editstate = false;
      this.clearForm();
    }
  }

