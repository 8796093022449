
import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { IdLookup } from '@/store/validations/types';
import { TableConfig } from '@/types';
import { Getter, State } from 'vuex-class';
import { Laboratory } from '@/store/laboratories/types';
import RadioGroup from '@/components/shared/RadioGroup.vue';
import TextInput from '@/components/shared/TextInput.vue';
import DateInput from '@/components/shared/DateInput.vue';
import TimeInput from '@/components/shared/TimeInput.vue';
import SubSection from '@/components/shared/SubSection.vue';
import { RootState, GenericCodeValue } from '@/store/types';
import CardSection from '@/components/shared/CardSection.vue';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import TextAreaInput from '@/components/shared/TextAreaInput.vue';
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import SelectOtherInput from '@/components/shared/SelectOtherInput.vue';
import { SaveableSection, SaveProvider, SaveResult } from '@/types';
import { Component, Vue, Prop } from 'vue-property-decorator';
import BooleanRadioInput from '@/components/shared/BooleanRadioInput.vue';
import { LabVirology, LabVirologyResult, VirologyType } from '@/store/labs/types';
import { VirologyCode, VirologyCodeValue, VirologyResultCodesValue } from '@/store/lookups/types';
import { LAB_CODE_OTHER } from '@/store/laboratories/types';
import { Recipient } from '@/store/recipients/types';
import { DeceasedDonor } from '@/store/deceasedDonors/types';
import { LivingDonor } from '@/store/livingDonors/types';

interface VirologyResultsForm {
  lab_code?: string|null;
  lab_other?: string|null;
  test_date?: string;
  test_time?: string;
  test_code?: string;
  sample_number?: string;
  sample_date?: string;
  sample_time?: string;
  sample_type?: string|null;
  from_mother?: boolean|null;
  comments?: string;
  virologyComments?: string; // For Living Donor virology comments (saved to living_donor_info.virology_comments)
  results?: { [key: string]: VirusResultForm };
  secondary_results?: { [key: string]: VirusResultForm };
}

interface VirusResultForm {
  tested: boolean;
  code: string;
  positive?: boolean|null;
  initial_result?: string;
  comments: string|null;
  value?: string|null;
}

@Component({
  components: {
    DateInput,
    RadioGroup,
    TextInput,
    TimeInput,
    SubSection,
    CardSection,
    SaveToolbar,
    SelectInput,
    TextAreaInput,
    CheckboxInput,
    SelectOtherInput,
    BooleanRadioInput,
  }
})
export default class VirologyResults extends mixins(DateUtilsMixin) {
  // State
  @State(state => state.laboratories.ser) private virologyLaboratoryLookup!: Laboratory[];
  @State(state => state.pageState.currentPage.virologyResults) editState!: VirologyResultsForm;
  @State(state => state.labs.virologies) private virologyResults!: LabVirology[];
  @State(state => state.labs.selectedVirology) private selectedVirology?: LabVirology;

  // Getters
  @Getter('clientId', { namespace: 'recipients' }) clientRecipientId!: string|undefined;
  @Getter('clientId', { namespace: 'deceasedDonors' }) clientDonorId!: string|undefined;
  @Getter('clientId', { namespace: 'livingDonors' }) clientLivingDonorId!: string|undefined;
  @Getter('donorFromItransplant', { namespace: 'deceasedDonors' }) donorFromItransplant!: boolean;
  @Getter('show', { namespace: 'recipients' }) private recipient!: Recipient;
  @Getter('show', { namespace: 'deceasedDonors' }) private deceasedDonor!: DeceasedDonor;
  @Getter('show', { namespace: 'livingDonors' }) private livingDonor!: LivingDonor;
  @Getter('newVirologyResult', { namespace: 'labs' }) private newVirologyResult!: (type: number) => LabVirology;
  @Getter('virology_codes_combined', { namespace: 'lookups' }) private virologyCodesCombined!: (type: number) => VirologyCode[];
  @Getter('virology_codes_secondary', { namespace: 'lookups' }) private virologyCodesSecondary!: (isDeceasedDonor: boolean) => any;
  @Getter('sample_types', { namespace: 'lookups' }) private sampleTypes!: any;
  @Getter('defaultLookup', { namespace: 'lookups' }) defaultLookup!: (lookupId: string) => any;
  @Getter('filterExpired', { namespace: 'lookups' }) filterExpired!: (entries: any[], recursive?: boolean, label?: string) => any[];
  @Getter('laboratoryOptions', { namespace: 'laboratories' }) private laboratoryOptions!: Laboratory[];
  @Getter('isTransplantCoordinator', { namespace: 'users' }) private isTransplantCoordinator!: boolean;
  @Getter('livingDonorVirologyResultOptions', { namespace: 'lookups' }) private livingDonorVirologyResultOptions!: GenericCodeValue[];
  @Getter('deceasedDonorVirologyResultOptions', { namespace: 'lookups' }) private deceasedDonorVirologyResultOptions!: GenericCodeValue[];
  @Getter('prototypeFeatureEnabled', { namespace: 'features' }) private prototypeFeatureEnabled!: (featureName: string) => boolean;

  // Properties
  @Prop({ default: VirologyType.Recipient }) virologyType!: number; // Recipient, Living Donor or Deceased Donor
  @Prop({ default: false }) newRecord!: boolean;
  @Prop({ default: false }) canSave!: boolean;

  // Lookup tables to be loaded by the CardSection component
  public lookupsToLoad = [
    'sample_types',
    'virology_codes_combined',
    'virology_codes_secondary',
    'donor_virology_result_codes',
  ];

  // Convert boolean into human value for ui
  parseBoolean(boolean?: boolean): string|undefined {
    if(boolean == undefined || boolean == null)
    {
      return '-';
    }
    return boolean == true ? this.$t('yes').toString() : this.$t('no').toString();
  }

  /**
   * Return if the VirologyType is Deceased Donor
   *
   * @returns {boolean} true if Deceased Donor
   */
  get isDeceasedDonor(): boolean {
    return VirologyType.DeceasedDonor === this.virologyType;
  }

  /**
   * Return if the VirologyType is Living Donor
   *
   * @returns {boolean} true if Living Donor
   */
  get isLivingDonor(): boolean {
    return VirologyType.LivingDonor === this.virologyType;
  }

  /**
   * Return if the VirologyType is Donor or Living Donor
   *
   * @returns {boolean} true if Donor or Living Donor
   */
  get isLivingDonorOrDeceasedDonor(): boolean {
    return [VirologyType.DeceasedDonor, VirologyType.LivingDonor].includes(this.virologyType);
  }

  // Deceased Donor and Living Donor have slightly different result options
  get virologyResultOptions(): GenericCodeValue[] {
    return this.isLivingDonor ? this.livingDonorVirologyResultOptions : this.deceasedDonorVirologyResultOptions;
  }

  // Show secondary test markers in the box-style virology?
  get showSecondaryResults(): boolean {
    return !this.isLivingDonorOrDeceasedDonor;
  }

  // Show secondary test markers in the table-style serology / virology?
  get showSecondaryTestMarkers(): boolean {
    return this.isLivingDonor;
  }

  get getTabbableValue(): any {
    if (this.isDeceasedDonor) return this.$t('sample_identifier');
    return this.$t('test_date');
  }

  // Laboratory lookups to be loaded by the CardSection component
  private laboratoriesToLoad = ['ser'];

  selectedSecondaryResult = null;

  /**
   * Return label of 'Virology' for Recipient and Living Donor
   * or 'Serology' for Deceased Donor
   *
   * @returns {string} string label
   */
  get virologyOrSerology(): string {
    return this.isDeceasedDonor ? 'serology' : 'virology';
  }

  get availableSecondaryResults(): any[] {
    const availableCodes = this.getVirologyCodesSecondary.filter((item: any) => {
      return this.editState.secondary_results && !this.editState.secondary_results[item.code];
    });
    return availableCodes;
  }

  // Returns whether or not the virology edit state is empty
  get isEmpty(): boolean {
    const virology = this.editState;
    // Create reference object to check default values using same process as initializing an empty form
    const defaultFormState = this.buildVirologyResultsForm(Object.assign({}, this.newVirologyResult(this.virologyType)));
    // Check if Virology Test Date is still default and other fields remain empty
    const isEmpty = (virology.lab_code == null || virology.lab_code.length === 0)
        && (virology.test_date === defaultFormState.test_date)
        && (virology.test_time == null || virology.test_time.length === 0)
        && (virology.test_code == null || virology.test_code.length === 0)
        && (virology.sample_number == null || virology.sample_number.length === 0)
        && (virology.sample_date === defaultFormState.sample_date)
        && (virology.sample_time == null || virology.sample_time.length === 0)
        && (virology.comments == null || virology.comments.length === 0)
        && (virology.sample_type == null || virology.sample_type.length === 0)
        && (virology.from_mother == null)
        && (virology.virologyComments == null || virology.virologyComments.length === 0);
    // Check length of results array
    const resultsArray = virology.results ? this.virusObjectToArray(virology.results) : [];
    const noResults = resultsArray.length === 0;
    if (this.virologyType === VirologyType.LivingDonor) {
      return isEmpty;
    }
    return isEmpty && noResults;
  }

  get getVirologyCodesCombined(): any {
    return this.filterExpired(this.virologyCodesCombined(this.virologyType), true);
  }

  get getVirologyCodesSecondary(): any {
    return this.filterExpired(this.virologyCodesSecondary(this.isLivingDonorOrDeceasedDonor), true);
  }

  get getVirologyColumnConfig(): any {
    switch(this.virologyType) {
      case VirologyType.DeceasedDonor:
        return [
          { label: `${this.$t('sample_identifier')}`, field: 'test_code', width: '16.67%' },
          { label: `${this.$t('sample_date')}`, field: 'sample_date', width: '16.67%', formatFn: this.formatFromDate },
          { label: `${this.$t('sample_time')}`, field: 'sample_time', width: '16.67%', formatFn: this.formatFromTime },
          { label: `${this.$t('sample_type')}`, field: 'sample_type', width: '16.67%', formatFn: this.formatSampleType },
          { label: `${this.$t('from_mother')}`, field: 'from_mother', width: '16.67%' },
        ];
        break;
      case VirologyType.LivingDonor:
        return [
          { label: `${this.$t('test_date')}`, field: 'test_date', width: '16.67%', formatFn: this.formatFromDate },
          { label: `${this.$t('lab_code')}`, field: 'lab_code', width: '16.67%' },
          { label: `${this.$t('test_time')}`, field: 'test_time', width: '16.67%', formatFn: this.formatFromTime },
          { label: `${this.$t('sample_code')}`, field: 'sample_code', width: '16.67%' },
          { label: `${this.$t('sample_date')}`, field: 'sample_date', width: '16.67%', formatFn: this.formatFromDate },
          { label: `${this.$t('sample_time')}`, field: 'sample_time', width: '16.67%', formatFn: this.formatFromTime },
        ];
        break;
      case VirologyType.Recipient:
        return [
          { label: `${this.$t('test_date')}`, field: 'test_date', width: '16.67%', formatFn: this.formatFromDate },
          { label: `${this.$t('lab_code')}`, field: 'lab_code', width: '16.67%' },
          { label: `${this.$t('test_time')}`, field: 'test_time', width: '16.67%', formatFn: this.formatFromTime },
          { label: `${this.$t('sample_code')}`, field: 'sample_code', width: '16.67%' },
          { label: `${this.$t('sample_date')}`, field: 'sample_date', width: '16.67%', formatFn: this.formatFromDate },
          { label: `${this.$t('sample_time')}`, field: 'sample_time', width: '16.67%', formatFn: this.formatFromTime },
        ];
        break;
    }
  }

  /**
   * Gets configuration for the Virology results table
   *
   * @returns {TableConfig} Virology Table configuration
   */
  get virologyResultsTableConfig(): TableConfig {
    return {
      data: this.getVirologyRowData || [],
      columns: this.getVirologyColumnConfig,
      empty: `${this.$t(`empty_${this.virologyOrSerology}_results`)}`,
      createButton: this.canSave && !this.donorFromItransplant,
      createText: `${this.$t(`create_${this.virologyOrSerology}_results`)}`,
      pagination: true,
      paginationOptions: {
        enabled: true,
        perPage: 5,
        mode: 'records',
        perPageDropdown: [5, 10, 25, 100],
        dropdownAllowAll: false,
        nextLabel: this.$t('older').toString(),
        prevLabel: this.$t('newer').toString(),
        rowsPerPageLabel: this.$t('results_per_page').toString(),
      }
    };
  }

  public formatFromDate(value: string): string|undefined {
    return value ? this.parseDisplayDateUiFromDateTime(value || '') : '-';
  }

  public formatFromTime(value: string): string|undefined {
    return value ? this.parseTimeUiFromDateTime(value) : '-';
  }

  public selectedLabCode(labCode: string) {
    if (labCode !== LAB_CODE_OTHER) {
      Vue.set(this.editState, 'lab_other', null);
    }
  }

  public addSecondaryResult(event: any): void {
    event.preventDefault();
    if (this.selectedSecondaryResult) {
      const result = this.getVirologyCodesSecondary.find((item: any) => {
        return item.code === this.selectedSecondaryResult;
      });
      if (this.editState && result) {
        // Add blank result for untested virus
        const virusObject: { [key: string]: VirusResultForm } = {};
        if (this.virologyType === VirologyType.LivingDonor) {
          // Add 'Not Tested' default for new rows (Living Donor Virology)
          virusObject[result.code] = {
            tested: true,
            code: result.code,
            initial_result: VirologyResultCodesValue.NotTested,
            positive: null,
            comments: null,
            value: result.value
          };
        } else {
          virusObject[result.code] = {
            tested: false,
            code: result.code,
            initial_result: undefined,
            positive: null,
            comments: null,
            value: result.value
          };
        }
        if (this.editState.secondary_results && !this.editState.secondary_results[result.code]) {
          this.editState.secondary_results = {...this.editState.secondary_results, ...virusObject};
        }
      }
    }
  }

  public getVirologyCombinedLabel(code: string): string|undefined {
    const result = this.getVirologyCodesCombined.find((item: VirologyCode) => item.code === code);
    if (!result) { return undefined; }
    if (this.virologyType === VirologyType.DeceasedDonor) return result.donor_label;
    if (this.virologyType === VirologyType.LivingDonor) return result.living_donor_label;
    if (this.virologyType === VirologyType.Recipient) return result.value;
  }

  public getVirologySecondaryLabel(code: string): string {
    const result = this.getVirologyCodesSecondary.find((item: any) => item.code === code);
    return result ? result.value : undefined;
  }

  public formatSampleType(value: string): string|undefined {
    const type = this.sampleTypes.find((item: any) => { return item.code == value; });
    return type ? type.value : undefined;
  }

  /**
   * Gets table row data for the Virology Results table
   *
   * @returns {LabVirology[]} Virology Results table rows
   */
  get getVirologyRowData(): LabVirology[] {
    if (this.newRecord || !this.virologyResults) {
      return [];
    }
    const result: LabVirology[] = [];
    this.virologyResults.forEach((record: LabVirology) => {

      const row: any = {
        _id: record._id,
        test_date: record.test_date || '-',
        test_time: record.test_date || '-',
        test_code: record.test_code || '-',
        sample_date: record.sample_date,
        sample_time: record.sample_date,
        sample_code: record.sample_number || '-',
        sample_type: record.sample_type || '-',
        from_mother: this.parseBoolean(record.from_mother) || '-',
      };
      if (this.virologyType === VirologyType.LivingDonor) {
        const lab_id = record?.lab_id?.$oid ? record.lab_id.$oid : null;
        row.lab_code = record.lab_other ? LAB_CODE_OTHER : this.laboratoryCode(lab_id);
        row.lab_other = record.lab_other;
        row.laboratory = row.lab_code == LAB_CODE_OTHER ? row.lab_other : row.lab_code;
      }
      if (this.virologyType === VirologyType.Recipient) {
        row.lab_code = record.lab_other ? record.lab_other : record.lab_code;
      }
      result.push(row);
    });
    return result;
  }

  public laboratoryCode(value: string|null): string|undefined {
    if (!value) return '';
    const labratory = this.laboratoryOptions.find((lab: Laboratory) => {
      return value === lab._id?.$oid;
    });
    return labratory?.lab_code ? labratory.lab_code : '-';
  }

  // Initialize form when card section has loaded
  public loaded(): void {
    // Load the labs after card section has finished loading relevant lookups, but not for a new Recipient
    if (!this.newRecord) {
      this.loadVirologyLabs();
    } else {
      this.initializeForm();
    }
    this.$emit('loaded', 'virology');
  }

  // Populate form model with values extracted from selected recipient
  public initializeForm(): void {
    this.$store.commit('labs/selectVirology', undefined);
    this.$store.commit('pageState/set', {
      pageKey: 'virologyResults',
      value: this.buildVirologyResultsForm(Object.assign({}, this.newVirologyResult(this.virologyType)))
    });
  }

  public selectVirologyResult(event: any) {
    // Get Virology ID from the table row referenced in the select event
    const selectedVirologyId = event.row._id && event.row._id.$oid ? event.row._id!.$oid : undefined;
    // Retrieve the Virology object from the Vue-X Store Virologies getter with the matching ID
    const foundVirology: LabVirology|undefined = this.virologyResults.find((each: LabVirology) => {
      return each._id && each._id.$oid === selectedVirologyId;
    });
    // Check if we successfully found the Virology object
    if (foundVirology) {
      // Store the Virology object in an immutable attribute to be used for detecting input changes
      this.$store.commit('labs/selectVirology', foundVirology);
      // Clone the Virology object into the editable page state
      this.$store.commit('pageState/set', {
        pageKey: 'virologyResults',
        value: this.buildVirologyResultsForm(Object.assign({}, this.selectedVirology))
      });
    }
  }

  /**
   * Saves the current edit state.
   *
   * Prepares HLA Typing payload, including endpoint IDs and parameter changes. Handles posting new a new entry as well
   * as patching an existing entry. Registers a SaveResult indicating whether the save was successful. If successful,
   * then the SaveResult contains the HLA Typing record. Otherwise, the SaveResult contains a textual description of
   * the error(s) encountered as well as any field-specific validation errors that were raised.
   */
  public savePatch(): void {
    if (this.virologyType === VirologyType.DeceasedDonor) return this.savePatchDonor();
    if (this.virologyType === VirologyType.LivingDonor) return this.savePatchLivingDonor();
    if (this.virologyType === VirologyType.Recipient) return this.savePatchRecipient();
  }

  // Attempt to save Living Donor Comments
  public saveCommentsPatch(): void {
    // Refer to the save provider that handles this form area
    const saveProvider = this.$refs.saveVirologyComments as unknown as SaveProvider;
    // Setup patch
    const livingDonorPatch: LivingDonor = {
      living_donor_info: {
        virology_comments: this.editState.virologyComments,
      }
    };
    this.$store.dispatch('livingDonors/saveLivingDonor', { livingDonorId: this.clientLivingDonorId, livingDonor: livingDonorPatch }).then((success: SaveResult) => {
      // Set living donor response to state
      this.$store.commit('livingDonors/set', success.responseData.living_donor);
      saveProvider.registerSaveResult(success);
    }).catch((error: SaveResult) => {
      saveProvider.registerSaveResult(error);
    });
  }

  // Attempt to save Recipient Virology
  public savePatchRecipient(): void {
    // Ref to our validation-observer
    const validationObserver = this.$parent as any;
    // Refer to the save provider that handles this form area
    const saveProvider = this.$refs.saveVirology as unknown as SaveProvider;
    // Report to parent that saving has began
    this.$emit('save', 'virology');
    // Generate payload based on current edit state
    const virologyPatch = this.extractPatch();
    // Setup saving payload
    const payload = {
      virologyType: this.virologyType,
      id: this.selectedVirology && this.selectedVirology._id && this.selectedVirology._id.$oid ? this.selectedVirology._id!.$oid : undefined,
      clientId: this.clientRecipientId,
      virology: virologyPatch
    };

    // Clear any errors
    validationObserver.reset();
    // Dispatch save action and register the response
    this.$store.dispatch('labs/saveVirology', payload).then((success: SaveResult) => {
      // If successful, reload all of the recipient's Virology Labs and show success notification
      this.loadVirologyLabs();
      saveProvider.registerSaveResult(success);
    }).catch((error: SaveResult) => {
      // Handle errors
      this.handleErrors(error);
      // Show error notification
      saveProvider.registerSaveResult(error);
    });
  }

  // Attempt to save Donor Virology
  public savePatchDonor(): void {
    // Ref to our validation-observer
    const validationObserver = this.$parent as any;
    // Refer to the save provider that handles this form area
    const saveProvider = this.$refs.saveVirology as unknown as SaveProvider;
    // Report to parent that saving has began
    this.$emit('save', 'virology');
    // Generate payload based on current edit state
    const virologyPatch = this.extractPatch();
    // Setup saving payload
    const payload = {
      virologyType: this.virologyType,
      id: this.selectedVirology && this.selectedVirology._id && this.selectedVirology._id.$oid ? this.selectedVirology._id!.$oid : undefined,
      clientId: this.clientDonorId,
      virology: virologyPatch
    };
    // Clear any errors
    validationObserver.reset();
    // Dispatch save action and register the response
    this.$store.dispatch('labs/saveVirology', payload).then((success: SaveResult) => {
      // If successful, reload all of the recipient's Virology Labs and show success notification
      this.loadVirologyLabs();
      saveProvider.registerSaveResult(success);
      // Request donor page reload data that might be affected by this form changing
      this.$emit('reload');
    }).catch((error: SaveResult) => {
      // Handle errors
      this.handleErrors(error);
      // Show error notification
      saveProvider.registerSaveResult(error);
    });
  }

  // Attempt to save Living Donor Virology
  public savePatchLivingDonor(): void {
    // Ref to our validation-observer
    const validationObserver = this.$parent as any;
    // Refer to the save provider that handles this form area
    const saveProvider = this.$refs.saveVirology as unknown as SaveProvider;
    // Report to parent that saving has began
    this.$emit('save', 'virology');
    // Generate payload based on current edit state
    const virologyPatch = this.extractPatch();
    // Setup saving payload
    const payload = {
      virologyType: this.virologyType,
      id: this.selectedVirology && this.selectedVirology._id && this.selectedVirology._id.$oid ? this.selectedVirology._id!.$oid : undefined,
      clientId: this.clientLivingDonorId,
      virology: virologyPatch
    };
    // Clear any errors
    validationObserver.reset();
    // Dispatch save action and register the response
    this.$store.dispatch('labs/saveVirology', payload).then((success: SaveResult) => {
      // If successful, reload all of the Living Donor's Virology Labs and show success notification
      this.loadVirologyLabs();
      saveProvider.registerSaveResult(success);
    }).catch((error: SaveResult) => {
      // Handle errors
      this.handleErrors(error);
      // Show error notification
      saveProvider.registerSaveResult(error);
    });
  }

  public createVirologyResult() {
    this.$store.commit('labs/selectVirology', undefined);
    this.$store.commit('pageState/set', {
      pageKey: 'virologyResults',
      value: this.buildVirologyResultsForm(Object.assign({}, this.newVirologyResult(this.virologyType)))
    });

    this.$emit('clear');
    this.resetSaveToolbar();
  }

  // Translate from relevant parts of the recipient data structure to the form layout
  public buildVirologyResultsForm(virologyResultData: any): VirologyResultsForm {
    const virologyCodes = this.getVirologyCodesCombined;
    const livingDonorVirologyComments = this.livingDonor?.living_donor_info?.virology_comments;
    const defaultDonorSampleDate = this.isDeceasedDonor ? this.currentDateUi() : undefined;

    const result: VirologyResultsForm = {
      test_date: virologyResultData.test_date ? this.parseDateUiFromDateTime(virologyResultData.test_date) : this.currentDateUi(),
      test_time: virologyResultData.test_date ? this.parseTimeUiFromDateTime(virologyResultData.test_date): undefined,
      sample_date: virologyResultData.sample_date ? this.parseDateUiFromDateTime(virologyResultData.sample_date) : defaultDonorSampleDate,
      sample_time: virologyResultData.sample_date ? this.parseTimeUiFromDateTime(virologyResultData.sample_date) : undefined,
      comments: virologyResultData.comments,
      virologyComments: livingDonorVirologyComments,
      results: this.extractVirusResultsObjectFromArray(virologyResultData.results || []),
      secondary_results: this.extractVirusSecondaryResultsObjectFromArray(virologyResultData.results || [])
    };
    if (this.virologyType === VirologyType.DeceasedDonor) {
      result.test_code = virologyResultData.test_code;
      result.sample_type = virologyResultData.sample_type;
      result.from_mother = virologyResultData.from_mother;
    }
    if (this.virologyType === VirologyType.LivingDonor) {
      result.lab_code = virologyResultData.lab_other ? LAB_CODE_OTHER : this.laboratoryCode(virologyResultData.lab_id?.$oid);
      result.lab_other = virologyResultData.lab_other;
      result.sample_number = virologyResultData.sample_number;
    }
    if (this.virologyType === VirologyType.Recipient) {
      result.lab_code = virologyResultData.lab_other ? LAB_CODE_OTHER : virologyResultData.lab_code;
      result.lab_other = virologyResultData.lab_other;
      result.sample_number = virologyResultData.sample_number;
    }
    return result;
  }

  public extractVirusResultsObjectFromArray(virusArray?: LabVirologyResult[]): { [key: string]: VirusResultForm }|undefined {
    const virologyCodes = this.getVirologyCodesCombined;
    if (!virusArray || !virologyCodes) {
      return undefined;
    }
    const virusObject: { [key: string]: VirusResultForm } = {};
    // Iterate through all virology codes i.e. all possible tested viruses
    for (const virologyCode of virologyCodes) {
      // Check whether the lab results includes a test for the virus
      const arrayElement = virusArray.find((element) => { return element.code === virologyCode.code; });
      if (arrayElement) {
        // Add tested virus result data to results object
        virusObject[virologyCode.code] = this.extractVirusResultForm(arrayElement);
      } else if (this.virologyType === VirologyType.LivingDonor) {
        // Add 'Not Tested' default for new rows (Living Donor Virology)
        virusObject[virologyCode.code] = {
          tested: false,
          code: virologyCode.code,
          initial_result: VirologyResultCodesValue.NotTested,
          positive: null,
          comments: null
        };
      } else {
        // Add blank result for new rows (Recipient Virology and Deceased Donor Serology)
        virusObject[virologyCode.code] = {
          tested: false,
          code: virologyCode.code,
          initial_result: undefined,
          positive: null,
          comments: null
        };
      }
      virusObject[virologyCode.code].value = virologyCode.value;
    }
    return virusObject;
  }

  public extractVirusSecondaryResultsObjectFromArray(virusArray?: LabVirologyResult[]): { [key: string]: VirusResultForm }|undefined {
    const virologyCodes = this.getVirologyCodesSecondary;
    if (!virusArray || !virologyCodes) {
      return undefined;
    }
    const virusObject: { [key: string]: VirusResultForm } = {};
    // Iterate through all virology codes i.e. all possible tested viruses
    for (const virologyCode of virologyCodes) {
      // Check whether the lab results includes a test for the virus
      const arrayElement = virusArray.find((element) => { return element.code === virologyCode.code; });
      if (arrayElement) {
        // Add tested virus result data to results object
        virusObject[virologyCode.code] = this.extractVirusResultForm(arrayElement);
        // Include 'value' display text if rendered on living donor (i.e. table-style virology)
        if (this.isLivingDonor) {
          const result = this.getVirologyCodesSecondary.find((item: any) => {
            return item.code === virusObject[virologyCode.code].code;
          });
          virusObject[virologyCode.code].value = result.value;
        }
      }
    }
    return virusObject;
  }

  public extractVirusResultForm(virusResult: LabVirologyResult): VirusResultForm {
    const result: VirusResultForm = {
      tested: true,
      code: virusResult.code,
      positive: this.isDeceasedDonor ? null : virusResult.positive,
      initial_result: this.isLivingDonorOrDeceasedDonor ? virusResult.initial_result : undefined,
      comments: virusResult.comments
    };
    if (this.virologyType === VirologyType.LivingDonor) {
      const initialResultPositive = virusResult.initial_result === VirologyResultCodesValue.Positive;
      const initialResultNegative = virusResult.initial_result === VirologyResultCodesValue.Negative;
      // If Living Donor has an additional test marker we need to add it to the result form with the positive key
      Object.assign(result, { positive: (initialResultPositive || initialResultNegative) ? (initialResultPositive ? true : false) : null });
    }
    return result;
  }

  public virusObjectToArray(virusObject: { [key: string]: VirusResultForm }): LabVirologyResult[] {
    const virologyCodes = this.getVirologyCodesCombined;
    const virusArray: LabVirologyResult[] = [];
    for (const virologyCode of virologyCodes) {
      const objectElement = virusObject[virologyCode.code];

      let isTested = false;

      // Skip if not tested
      if (this.isLivingDonorOrDeceasedDonor) {
        isTested = objectElement && objectElement.initial_result ? true : false;
      } else {
        isTested = objectElement && objectElement.tested;
      }

      if (isTested) {
        virusArray.push(this.virusResultFormToVirologyResult(objectElement));
      }
    }
    return virusArray;
  }

  public virusObjectToArraySecondary(virusObject: { [key: string]: VirusResultForm }): LabVirologyResult[] {
    const virologyCodes = this.getVirologyCodesSecondary;
    const virusArray: LabVirologyResult[] = [];
    for (const virologyCode of virologyCodes) {
      const objectElement = virusObject[virologyCode.code];

      // Skip if not tested
      const isTested = objectElement && objectElement.tested;

      if (isTested) {
        virusArray.push(this.virusResultFormToVirologyResult(objectElement));
      }
    }
    return virusArray;
  }

  public virusResultFormToVirologyResult(resultForm: VirusResultForm): LabVirologyResult {
    const result: LabVirologyResult = {
      code: resultForm.code,
      comments: (this.virologyType === VirologyType.LivingDonor) ? null : resultForm.comments!
    };
    if (this.virologyType === VirologyType.DeceasedDonor) {
      result.initial_result = resultForm.initial_result || undefined; // Save as untested if not selected
    }
    if (this.virologyType === VirologyType.LivingDonor) {
      result.initial_result = resultForm.initial_result || undefined; // Save as untested if not selected
    }
    if (this.virologyType === VirologyType.Recipient) {
      result.positive = resultForm.positive;
    }
    return result;
  }

  // Returns a LabVirology patch request payload or null of the virology edit state is empty
  public extractPatch(): any {
    if (this.editState) return this.extractVirologyPatch(this.editState);
    if (!this.editState || !this.getVirologyCodesCombined || this.isEmpty) {
      return {};
    }
  }

  // Clear save notifications
  public resetSaveToolbar(): void {
    // Refer to the save provider that handle the areas present on this form component
    const virology = this.$refs.saveVirology as unknown as SaveProvider;
    if (virology) virology.resetSaveToolbar();
  }

  // API response keys on the left, id for our UI on the right
  public idLookup(): IdLookup {
    const mapping: IdLookup = {
      'virology_labs.lab_id'                  : 'lab_code',
      'virology_labs.lab_code'                : 'lab_code',
      'virology_labs.lab_other'               : 'lab_other',
      'virology_labs.test_date'               : ['virology-results-test_date', 'virology-results-test_time'],
      'virology_labs.test_code'               : 'virology-results-test_code',
      'virology_labs.sample_number'           : 'virology-results-sample_code',
      'virology_labs.sample_date'             : ['virology-results-sample_date', 'virology-results-sample_time'],
      'virology_labs.sample_type'             : 'virology-results-sample_type',
      'virology_labs.from_mother'             : 'virology-results-from_mother',
    };

    // Build attribute mapping for error messages based on whether donor/living-donor/recipient
    switch(this.virologyType) {
      case VirologyType.DeceasedDonor:
      case VirologyType.LivingDonor:
        [...this.getVirologyCodesCombined, ...this.getVirologyCodesSecondary].forEach((item: LabVirologyResult) => {
          mapping[`virology_labs.results[${item.code}].initial_result`] = `virology-results-${item.code}-initial_result`;
        });
        break;
      case VirologyType.Recipient:
        [...this.getVirologyCodesCombined, ...this.getVirologyCodesSecondary].forEach((item: LabVirologyResult) => {
          mapping[`virology_labs.results[${item.code}].positive`] = `virology-results-${item.code}-positive`;
        });
        break;
    }

    return mapping;
  }

  // PRIVATE

  // Load virology results for the selected recipient
  private loadVirologyLabs(): void {
    const request: { virologyType: number, clientId?: string } = { virologyType: this.virologyType };
    if (this.virologyType === VirologyType.DeceasedDonor) {
      Object.assign(request, { clientId: this.clientDonorId });
    }
    if (this.virologyType === VirologyType.LivingDonor) {
      Object.assign(request, { clientId: this.clientLivingDonorId });
    }
    if (this.virologyType === VirologyType.Recipient) {
      Object.assign(request, { clientId: this.clientRecipientId });
    }
    this.$store.dispatch('labs/loadVirologies', request).then(() => {
      this.initializeForm();
    }).catch(() => {
      this.initializeForm();
    });
  }

  // Translate from the form layout to relevant parts of the API data structure
  private extractVirologyPatch(virology: VirologyResultsForm): LabVirology {
    const resultsArray = virology.results ? this.virusObjectToArray(virology.results) : [];
    const secondaryResultsArray = virology.secondary_results ? this.virusObjectToArraySecondary(virology.secondary_results) : [];
    const virologyId = this.selectedVirology ? this.selectedVirology._id!.$oid : undefined;

    const result: LabVirology = {
      _id: { $oid: virologyId },
      test_date: virology.test_date ? this.sanitizeDateWithRequiredTimeApi(virology.test_date, virology.test_time) : null,
      sample_date: this.sanitizeOptionalDateWithTimeApi(virology.sample_date, virology.sample_time),
      results: resultsArray.concat(secondaryResultsArray)
    };
    // We need to send the lab_id instead of the lab_code for Living Donor
    const laboratoryId = this.laboratoryOptions.find((laboratory: Laboratory) => {
      return laboratory.lab_code === virology.lab_code;
    });

    switch(this.virologyType) {
      case VirologyType.DeceasedDonor:
        result.test_code = virology.test_code;
        result.sample_type = virology.sample_type || null;
        result.from_mother = virology.from_mother || false; // always send value for from_mother
        break;
      case VirologyType.LivingDonor:
        result.lab_id = laboratoryId?._id?.$oid ? laboratoryId._id : null;
        result.lab_other_code = virology.lab_code === LAB_CODE_OTHER ? LAB_CODE_OTHER : null;
        result.lab_other = virology.lab_other;
        result.sample_number = virology.sample_number;
        result.virology_comments = virology.virologyComments;
        break;
      case VirologyType.Recipient:
        result.lab_other_code = virology.lab_code === LAB_CODE_OTHER ? LAB_CODE_OTHER : null;
        result.lab_code = this.chosenLabOther(virology.lab_code) ? null : virology.lab_code;
        result.lab_other = virology.lab_other;
        result.sample_number = virology.sample_number;
        break;
    }

    return result;
  }

  private handleErrors(errors: any) {
    this.$emit('handleErrors', errors);
  }

  private chosenLabOther(value: any): boolean {
    return value === LAB_CODE_OTHER;
  }

  // Toggle the virology results div only if you're not a Transplant Coordinator (this role can view only)
  toggleVirology(code: string, editState: { [key: string]: VirusResultForm }): void {
    if (this.isTransplantCoordinator) return;
    const virologyResults = editState || {};
    if (code &&  typeof code === 'string' && virologyResults[code]) {
      const resultTested = virologyResults[code].tested;
      Vue.set(virologyResults[code], 'tested', !resultTested);
    }
  }
}
