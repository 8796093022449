
import { Getter, State } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';
import CardSection from "@/components/shared/CardSection.vue";
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import EncountersDetailsSection from '@/components/prototypes/organs/EncountersDetailsSection.vue';

@Component({
  components: {
    CardSection,
    SaveToolbar,
    EncountersDetailsSection
  }
})
export default class NotesAndEncounters extends Vue {  
  title = 'Notes / Encounters';
  @Getter('prototypeFeatureEnabled', { namespace: 'features' }) private prototypeFeatureEnabled!: (featureName: string) => boolean;
}
