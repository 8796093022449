
  import store from '@/store';
  import {
    Getter,
    State
  } from 'vuex-class';
  import {
    Component,
    Vue,
    Prop,
    Watch
  } from 'vue-property-decorator';
  import BaseWidget from '@/components/dashboard/widgets/_BaseWidget.vue';
  import {
    DashboardState,
    DashboardSettingState,
    WidgetSettings
  } from '@/store/dashboard/types';
  import {
    TableConfig
  } from '@/types';
  import {
    VueGoodTable
  } from 'vue-good-table';
  import TextInput from '@/components/shared/TextInput.vue';
  import CheckboxInput from '@/components/shared/CheckboxInput.vue';
  import SelectInput from '@/components/shared/SelectInput.vue';
  import {
    SaveableSection,
    SaveProvider,
    SaveResult
  } from '@/types';
  import {
    Format
  } from '@/store/utilities/types';

  @Component({
    components: {
      BaseWidget,
      VueGoodTable,
      TextInput,
      CheckboxInput,
      SelectInput
    }
  })
  export default class WidgetActions extends Vue {
    @Prop({
      default: false
    }) requiredWidget!: boolean;

    @State(state => state.users.user.preferences) private preferences!: any;
    @State(state => state.pageState.currentPage.dashActions) private editState!: any;

    @Getter('getPreferences', {
      namespace: 'users'
    }) getPreferences!: any;
    @Getter('getPanelStyles', {
      namespace: 'dashboard'
    }) getPanelStyles!: any[];
    @Getter('getDateFormat', {
      namespace: 'features'
    }) private getDateFormat!: string;

    public errorMessage = null;

    mounted(): void {
      Promise.all([
        this.$store.dispatch('dashboard/loadActions')
      ]).finally(() => {
        this.initializeWidget();
      });
    }

    // Setup temporary edit state for unsaved widget preferences form fields
    public initializeWidget(): void {
      this.errorMessage = null; // clear error message
      const preferences = this.getPreferences;
      this.$store.commit('pageState/set', {
        pageKey: 'dashActions',
        value: this.buildEditState(preferences),
      });
    }

    // Commit edit state field model values to vue-x store
    saveSettings(): void {
      const newState = this.extractDashboardSettings(this.preferences, this.editState);
      this.$store.dispatch('users/savePreferences', {
        preferences: newState
      }).then((success: SaveResult) => {
        // If successful dismiss dialog
        this.dismiss();
        this.initializeWidget();
        // Reinitialize if overall dashboard settings change
        this.$emit('reloadDashboard');
      }).catch((error: any) => {
        // Show error notification
        this.errorMessage = error.message;
      });
    }

    public dismiss(): void {
      $("#idActions").collapse('toggle');
    }

    // Build new overall dashboard settings with new settings for this specific widget
    extractDashboardSettings(currentSettings: DashboardSettingState, editState: WidgetSettings): DashboardSettingState {
      const newSettings = Object.assign({
        actions: {}
      }, currentSettings);
      newSettings.actions = {
        style: editState.style,
        visible: editState.visible
      };
      return newSettings;
    }

    // Build edit state based on overall dashboard preferences
    buildEditState(preferences: any): WidgetSettings {
      return Object.assign({}, preferences.actions);
    }


  }

