
import { Getter, State } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';
import CardSection from "@/components/shared/CardSection.vue";
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import SubSection from '@/components/shared/SubSection.vue';
import Comorbidities from './Comorbidities.vue';
import MedicalProcedures from './MedicalProcedures.vue'


@Component({
  components: {
    CardSection,
    SaveToolbar,
    SubSection,
    Comorbidities,
    MedicalProcedures
  }
})
export default class MedicalHistory extends Vue {  
  title = 'Medical History';
}
