import { render, staticRenderFns } from "./_PhaseStatus.vue?vue&type=template&id=b35973bc"
import script from "./_PhaseStatus.vue?vue&type=script&lang=ts"
export * from "./_PhaseStatus.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./_locales/PhaseStatus.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Fprototypes%2Forgans%2F_PhaseStatus.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports