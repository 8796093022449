
import { State, Getter } from 'vuex-class';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Recipient } from "@/store/recipients/types";

@Component
export default class SelectedRecipientOfferLinks extends Vue {
  @State(state => state.recipients.selectedRecipient) private recipient!: Recipient;

  @Prop({default: false}) active!: boolean;
  @Prop() journeyId!: string;

  public show = true;

  public toggleNav() {
    this.show = !this.show;
  }

  public toggleSideNav(): void {
    if (window.innerWidth < 992) { 
       this.$emit("toggle");
    }
  }
  /**
   * Gets the recipient client_id.
   *
   * Converts the recipient client_id to a string
   *
   * @returns {string} client_id as a string
   */
  get clientId(): string {
    return this.recipient.client_id == null ? '' : this.recipient.client_id.toString();
  }

  get isActive(): boolean {
    const organId = this.$route.params.organ_id;
    if (!!organId) {
      return this.journeyId == organId;
    }
    return false;
  }

  public getUrl(hash: string): string {
    hash = `#${hash}`;
    
    const currentRoute = this.$router.currentRoute;
    if (currentRoute.name == 'recipient-offers') {
      return hash;
    }

    const location = this.$router.resolve({ name: 'recipient-offers', params: { organ_id: this.journeyId }, hash: hash });
    return location.href;
  }
}
