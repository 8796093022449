
import { Getter, State } from 'vuex-class';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class SelectedLivingDonorOrganLinks extends Vue {
  @Getter('isTransplantCoordinator', { namespace: 'users' }) private isTransplantCoordinator!: boolean;
  @Getter('prototypeFeatureEnabled', { namespace: 'features' }) private prototypeFeatureEnabled!: (featureName: string) => boolean;


  @Prop() organId!: string;
  @Prop() organ!: string;
  @Prop() organCode!: string;
  @Prop() organRoute!: any;

  show = false;

  public selectedJourney = '';

  // Hide organ specific deatils from side nav
  public ORGAN_CODES_TO_EXCLUDE = ['7'];

  public toggleNav() {
    this.show = !this.show;
  }

  public mounted() {
    this.show = this.isActive;
  }

  @Watch('$route', { immediate: true, deep: true })
  public onUrlChange() {
    this.show = this.isActive;
  }

  get isActive(): boolean {
    return this.organCode == this.$route.params.organ_code
      && this.organRoute.params.option == this.$route.params.option;
  }

  get showOrganSpecificDetails(): boolean {
   return true;
  }

  public toggleSideNav(): void {
    if (window.innerWidth < 992) {
      this.$emit("toggle");
    }
  }

  public getUrl(hash: string): string {
    hash = `#${hash}`;
    if(this.isActive) {
      return hash;
    } else {
      // We are fine not checking for the existence of client_id as for new recipients we only ever show this in active mode
      const location = this.$router.resolve({ name: 'living-donor-organ', params: { organ_code: this.organCode }, hash: hash });
      return location.href;
    }
  }
}
