
import { State, Getter } from 'vuex-class';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { IdLookup } from '@/store/validations/types';
import { Recipient } from '@/store/recipients/types';
import { RecipientJourney } from '@/store/recipientJourney/types';
import CardSection from '@/components/shared/CardSection.vue';
import PostTransplantFollowUpSection, { FollowUpPageState } from '@/components/organs/shared/PostTransplantFollowUpSection.vue';
import PostTransplantTransferSection, { PostTransplantTransferState } from '@/components/organs/shared/PostTransplantTransferSection.vue';
import { SystemModules } from '@/store/features/types';

export interface PostTransplantSectionPageState {
  followUp?: FollowUpPageState;
  transferFollowUp?: PostTransplantTransferState;
}

@Component({
  components: {
    CardSection,
    PostTransplantTransferSection,
    PostTransplantFollowUpSection
  }
})
export default class PostTransplantSection extends Vue {
  // State
  @State(state => state.recipients.selectedRecipient) recipient!: Recipient;
  @State(state => state.journeyState.selectedJourney) journey!: RecipientJourney;
  @State(state => state) state!: boolean;

  // Getters
  @Getter('clientId', { namespace: 'recipients' }) recipientId!: string;
  @Getter('isAdmin', { namespace: 'users' }) private isAdmin!: boolean;
  @Getter('isCSCManager', { namespace: 'users' }) private isCSCManager!: boolean;
  @Getter('journeyId', { namespace: 'journeyState', }) journeyId!: string|undefined;
  @Getter('hasPostTransplantFollowUpTransfer', { namespace: 'journeyState' }) hasPostTransplantFollowUpTransfer!: boolean;
  @Getter("moduleEnabled", { namespace: "features" }) private moduleEnabled!: (module: string) => boolean;

  // Properties
  @Prop({ default: false }) newJourney!: boolean;
  @Prop({ default: false }) canSave!: boolean;
  @Getter('prototypeFeatureEnabled', { namespace: 'features' }) private prototypeFeatureEnabled!: (featureName: string) => boolean;


  private lookupsToLoad = [
    'recipient_status_post_transplant',
    'source_of_information',
    'lost_to_followup_reason',
    'yes_no_unknown',
    'cause_of_death_category'
  ];

  /**
   * Populates form state with default values for the Consultation Section
   */
  public initializeForm(): void {
    // Afflo Prototype
    let postTransplantPageState: FollowUpPageState = {
      followUpType: null,
      checklist: {
        type: null,
      },
    };

    let transferFollowUpPageState: PostTransplantTransferState = {};

    // Delegate building page states to the subsections
    const postTransplantForm = this.$refs.postTransplantFollowUp as PostTransplantFollowUpSection;
    const postTransplantTransferForm = this.$refs.postTransplantTransfer as PostTransplantTransferSection;

    // Build page state for Transfer Follow-Up Hospital
    if (!!postTransplantTransferForm) {
      transferFollowUpPageState = postTransplantTransferForm.buildPageState();
    }

    // Build page state for Post Transplant Follow-Up
    if (!!postTransplantForm) {
      if (this.journeyId) this.$store.dispatch('journeyState/loadPostTransplantFollowUps', { journeyId: this.journeyId, recipientId: this.recipientId });
      postTransplantPageState = postTransplantForm.buildPostTransplantFollowUpPageState();
    }

    // Commit our state
    this.$store.commit('pageState/set', {
      pageKey: 'postTransplantSection',
      value: {
        followUp: postTransplantPageState,
        transferFollowUp: transferFollowUpPageState,
      },
    });
  }

  // API response keys on the left, id for our UI on the right
  public idLookup(): IdLookup {
    const result: { [key: string]: string } = {};

    // Transfer Section
    const postTransplantTransfer = this.$refs.postTransplantTransfer as PostTransplantTransferSection;
    if (postTransplantTransfer) {
      Object.assign(result, { ...postTransplantTransfer.idLookup() });
    }

    // Follow-Up Section
    const postTransplantFollowUp = this.$refs.postTransplantFollowUp as PostTransplantFollowUpSection;
    if (postTransplantFollowUp) {
      Object.assign(result, { ...postTransplantFollowUp.idLookup });
    }

    return result;
  }

  // PRIVATE

  private loaded(): void {
    this.$emit('loaded', 'postTransplantSection');
    // Clear stored selection
    this.$store.commit('journeyState/clearPostTransplantFollowUp');
  }

  // Emit event to parent so it can handle validations
  private handleErrors(errors: any) {
    this.$emit('handleErrors', errors);
  }

  // Local getters

  /**
   * This section should always appear to CSC Manager and TGLN Administrator roles. It should
   * become visible to other users who are authorized to view the journey’s Post-Transplant area
   * when data has been saved. 
   */
  get showPostTransplantTransfer(): boolean {
    if (this.hasPostTransplantFollowUpTransfer) return true;
    return this.isCSCManager || this.isAdmin;
  }

  // Is the Post-Transplant Transfer system module enabled?
  get isPostTransplantTransferEnabled(): boolean {
    return this.moduleEnabled(SystemModules.TRANSFER_POST_TRANSPLANT);
  }
}
