
import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { TableConfig } from '@/types';
import { Getter, State } from 'vuex-class';
import { VueGoodTable } from 'vue-good-table';
import DateInput from '@/components/shared/DateInput.vue';
import { ProcedureStemCellTherapy } from '@/store/procedures/types';
import { uniqueElements } from '@/utils';
import { Recipient } from '@/store/recipients/types';
import HlaInput from '@/components/shared/HlaInput.vue';
import { Laboratory } from '@/store/laboratories/types';
import TextInput from '@/components/shared/TextInput.vue';
import SubSection from '@/components/shared/SubSection.vue';
import { DeceasedDonor } from '@/store/deceasedDonors/types';
import CardSection from '@/components/shared/CardSection.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import TextAreaInput from '@/components/shared/TextAreaInput.vue';
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import { SaveableSection, SaveProvider, SaveResult } from "@/types";
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { IdLookup } from '@/store/validations/types';
import OverrideMappingModal from '@/components/hla/OverrideMappingModal.vue';
import { LabHLATypingRecipient, HlaTypingTag, HlaSerologicalValue, LabHlaTypingDonor, LabHlaTypingAntigen, LabHlaTypingEpitope } from '@/store/labs/types';
import { HlaDictionaryEntry, HlaMolecularCode, HlaSerologicalCode, HlaMolecularRelation, HlaSerologicalRelation, HlaEpitope, EPITOPE_PRESENT, EPITOPE_NOT_PRESENT, EPITOPE_OPTIONS } from '@/store/lookups/types';
import { GenericCodeValue, ObjectId} from '@/store/types';
import { EP } from '@/api-endpoints';
import { SystemModules } from '@/store/features/types';

export interface HlaTypingForm {
  hla_lab?: string|null;
  comments?: string;
  technique_all_rows?: number|null;
  last_update?: string;
  last_updated_by?: string;
  updated_by_user_id?: string;
  last_updated_user_lab?: string;
  typing_incomplete?: boolean;
  hla_typing_details?: HlaTypingDetailsForm;
  submitDate?: string|null;
}

export interface HlaTypingLocusGroup {
  [sequence_number: number]: HlaTypingDetailRow;
}

export interface HlaTypingDetailsForm {
  [locus: string]: HlaTypingLocusGroup;
}

export interface HlaTypingDetailRow {
  _id?: { $oid: string };
  comments?: string;
  molecular_locus?: string;
  molecular_override?: string[];
  molecular_value?: string[];
  most_likely_allele?: string[];
  override_mapping?: boolean;
  sequence_number?: number;
  serologic_locus?: string;
  serologic_value?: string[];
  testing_technique?: number;
  isEpitope?: boolean;
  epitopeDictionaryResult?: string|null;
  epitopeDictionaryResultValue?: string;
  epitopeOverride?: boolean;
  epitopeOverrideResult?: string|null;
}

interface HlaTypingLoci {
  molecular_locus: string;
  serologic_locus: string;
  serologic_label?: string;
}

// Order of molecular locus values shown in HLA Typing Details Table
const DETAILS_TABLE_LOCUS_SEQUENCE: HlaTypingLoci[] = [
  { molecular_locus: 'A', serologic_locus: 'A' },
  { molecular_locus: 'B', serologic_locus: 'B' },
  { molecular_locus: 'C', serologic_locus: 'C' },
  { molecular_locus: 'DRB1', serologic_locus: 'DR' },
  { molecular_locus: 'DRB3', serologic_locus: 'DR', serologic_label: 'DR52' },
  { molecular_locus: 'DRB4', serologic_locus: 'DR', serologic_label: 'DR53' },
  { molecular_locus: 'DRB5', serologic_locus: 'DR', serologic_label: 'DR51' },
  { molecular_locus: 'DQB1', serologic_locus: 'DQ' },
  { molecular_locus: 'DQA1', serologic_locus: 'DQA' },
  { molecular_locus: 'DPB1', serologic_locus: 'DP' },
  { molecular_locus: 'DPA1', serologic_locus: 'DPA' }
];
// Epitope constants specific to BW4 and BW6 rows
const OBSOLETE_LOCI = ['BW'];
const UNKNOWN_LOCUS = 'UNKNOWN';
const LOCI_THAT_MAY_CONTAIN_EPITOPES = ['A', 'B'];

@Component({
  components: {
    DateInput,
    CardSection,
    SubSection,
    SelectInput,
    TextInput,
    TextAreaInput,
    HlaInput,
    VueGoodTable,
    CheckboxInput,
    OverrideMappingModal,
  }
})
export default class HlaTyping extends mixins(DateUtilsMixin) {
  // State
  @State(state => state.pageState.currentPage.hlaTyping) editState!: HlaTypingForm;
  @State(state => state.laboratories.hla) private hlaLaboratoryLookup!: Laboratory[];
  @State(state => state.lookups.hla_technique) private  hlaTypingTechniqueLookup!: any;
  @State(state => state.deceasedDonors.selected) private deceasedDonor!: DeceasedDonor;
  @State(state => state.lookups.hla_dictionary_epitopes) private hlaDictionaryEpitopes!: HlaEpitope[];
  @State(state => state.procedures.stemCellTherapy) selectedStemCellTherapy!: ProcedureStemCellTherapy;

  // Getters
  @Getter('hlaTyping', { namespace: 'labs' }) hlaTyping!: LabHLATypingRecipient|LabHlaTypingDonor|null;
  @Getter('clientId', { namespace: 'recipients' }) recipientId!: string;
  @Getter('clientId', { namespace: 'livingDonors' }) livingDonorId!: string;
  @Getter('parseHlaTypingTag', { namespace: 'labs' }) parseHlaTypingTag!: (tagText: string) => HlaTypingTag|undefined;
  @Getter('findSerologicalByMolecular', { namespace: 'lookups'}) findSerologicalByMolecular!: (geneLocus: string, molecular: HlaTypingTag) => HlaSerologicalRelation[]|undefined;
  @Getter('findSerologicalByMostLikelyAllele', { namespace: 'lookups'}) findSerologicalByMostLikelyAllele!: (geneLocus: string, mostLikelyAllele: HlaTypingTag) => HlaSerologicalRelation[]|undefined;
  @Getter('clientId', { namespace: 'deceasedDonors' }) donorClientId!: string|undefined;
  @Getter('findEpitopes', { namespace: 'lookups'}) findEpitopes!: (antigens: HlaTypingTag[]) => string[];
  @Getter('getAllUserLaboratoryIds', { namespace: 'users' }) getAllUserLaboratoryIds!: string[];
  @Getter("moduleEnabled", { namespace: "features" }) private moduleEnabled!: (module: string) => boolean;
  @Getter('prototypeFeatureEnabled', { namespace: 'features' }) private prototypeFeatureEnabled!: (featureName: string) => boolean;

  // Properties
  @Prop({ default: false }) canSave!: boolean;
  @Prop({ default: false }) newRecord!: boolean; // Set to true to disable form because underlying patient record has not been created yet

  @Prop({ default: false }) enableDonor!: boolean; // Set to true for Donor functionality instead of Recipient
  @Prop({ default: false }) livingDonor!: boolean; // Set to true for Living Donor functionality instead of Deceased Donor

  // Lookup tables to be loaded by the CardSection component
  private lookupsToLoad = ['hla_technique', 'hla_dictionary_epitopes'];

  // Laboratory lookups to be loaded by the CardSection component
  private laboratoriesToLoad = ['hla'];

  // Loading state
  private isFinishedLoadingLookups = false;
  private isFinishedLoadingHlaTypingLab = false;
  private isFinishedLoadingStemCellTherapyProcedure = false;

  // Saving state
  private isFormDisabled = false;

  get isFinishedLoading(): boolean {
    return this.isFinishedLoadingLookups && this.isFinishedLoadingHlaTypingLab && this.isFinishedLoadingStemCellTherapyProcedure;
  }

  // Is the HLA Typing Incomplete system module enabled?
  get isHlaTypingIncompleteEnabled(): boolean {
    return this.moduleEnabled(SystemModules.HLA_TYPING_INCOMPLETE);
  }

  // Is the HLA Typing Molecular Group system module enabled?
  get isHlaTypingMolecularGroupEnabled(): boolean {
    return this.moduleEnabled(SystemModules.HLA_TYPING_MOLECULAR_GROUP);
  }

  // Is the HLA Typing Molecular Override system module enabled?
  get isHlaTypingMolecularOverrideEnabled(): boolean {
    return this.moduleEnabled(SystemModules.HLA_TYPING_MOLECULAR_OVERRIDE);
  }

  // Is the HLA Typing Most Likely Allele system module enabled?
  get isHlaTypingMostLikelyAlleleEnabled(): boolean {
    return this.moduleEnabled(SystemModules.HLA_TYPING_MOST_LIKELY_ALLELE);
  }

  // Is the HLA Typing Submit Date Editable system module enabled?
  get isHlaTypingSubmitDateEditable(): boolean {
    return this.moduleEnabled(SystemModules.HLA_TYPING_SUBMIT_DATE_EDITABLE);
  }

  // Is the HLA Stem Cell Therapy system module enabled?
  get isHlaStemCellTherapyEnabled(): boolean {
    return this.moduleEnabled(SystemModules.HLA_STEM_CELL_THERAPY);
  }

  /**
   * Get hla laboratory lookup,
   * based on the user's laboratory id's or the patient's lab result
   *
   * @returns {GenericCodeValue[]} hla laboratory options
   */
  get laboratoryLookup(): GenericCodeValue[]{
    if(!this.hlaLaboratoryLookup) return [];
    if(!this.hlaTyping) return [];

    let filteredLaboratoryLookup;
    const createdUserLabId = this.hlaTyping.lab_id ? this.hlaTyping.lab_id.$oid : null;

    if(!createdUserLabId) {
      filteredLaboratoryLookup = this.hlaLaboratoryLookup.filter((lab: Laboratory)=> {
        return this.getAllUserLaboratoryIds.includes(lab._id?.$oid);
      });
    } else {
      filteredLaboratoryLookup = this.hlaLaboratoryLookup.filter((lab: Laboratory)=> {
        return lab._id?.$oid == createdUserLabId;
      });
    }
    // this will show oop donor labs in the hla lab
    // NOTE: here we assume no option is needed if we don't have any 'lab_code' (ATQ-709)
    if(filteredLaboratoryLookup.length == 0 && this.hlaTyping.lab_code){
      let oopLab = [];
      oopLab.push({
        code:  createdUserLabId || '',
        value: this.hlaTyping.lab_code || ''
      });
      return oopLab;
    }
    return filteredLaboratoryLookup.map((lab: Laboratory) => {
      return {
        code: lab._id?.$oid,
        value: lab.name || ''
      };
    });
  }

  /**
   * Gets the initial lab value from CTR
   *
   * @returns {string|null} returns the initial lab value, null if none defined
   */
  get getInitialLab(): string|null {
    const initialLab = this.hlaTyping && this.hlaTyping.initial_laboratory ? this.hlaTyping.initial_laboratory : null;
    return initialLab ? `${initialLab.lab_code}-${initialLab.lab_type} ${initialLab.name}` : null;
  }

  /**
   * Gets configuration for the Details Table
   *
   * @returns {TableConfig} Details Table configuration
   */
  get hlaTypingDetailsTableConfig(): TableConfig {
    const columns = [];
    if (this.isHlaTypingMolecularGroupEnabled) columns.push({ label: this.$t('molecular'), field: 'molecular', width: '15%', tdClass: 'hla-col' });
    if (this.isHlaTypingMolecularOverrideEnabled) columns.push({ label: this.$t('molecular_override'), field: 'molecular_override', width: '15%', tdClass: 'hla-col' });
    if (this.isHlaTypingMostLikelyAlleleEnabled) columns.push({ label: this.$t('most_likely_allele'), field: 'most_likely_allele', width: '12%', tdClass: 'hla-col' });
    columns.push({ label: this.$t('serologic'), field: 'serologic', width: '12%', tdClass: 'hla-col' });
    columns.push({ label: this.$t('testing_technique'), field: 'testing_technique', width: '15%', tdClass: 'hla-col' });
    columns.push({ label: this.$t('comments'), field: 'comments', tdClass: 'hla-col' });

    return {
      data: this.hlaTypingDetailRows,
      columns,
      // Disable unused sorting feature, because Vue Good Table has sorting enabled by default
      sortOptions: {
        enabled: false,
      },
    };
  }

  get possibleEpitopes(): HlaEpitope[] {
    const result: HlaEpitope[] = this.hlaDictionaryEpitopes || [];
    return result;
  }

  /**
   * Gets table row data representing the HLA Typing for the selected recipient.
   *
   * @param formState current edit state for the HLA Typing form
   * @returns {HlaTypingDetailRow[]} HLA Typing rows
   */
  get hlaTypingDetailRows(): HlaTypingDetailRow[] {
    if (!this.editState || !this.editState.hla_typing_details) {
      return [];
    }
    const result: HlaTypingDetailRow[] = [];
    // Iterate through all possible loci
    DETAILS_TABLE_LOCUS_SEQUENCE.forEach((loci: HlaTypingLoci) => {
      // Set up empty entries for both sequence numbers for these loci
      let sequence1: HlaTypingDetailRow = {
        sequence_number: 1,
        molecular_locus: loci.molecular_locus,
        molecular_value: [],
        molecular_override: [],
        most_likely_allele: [],
        serologic_locus: loci.serologic_locus,
        serologic_value: []
      };
      let sequence2: HlaTypingDetailRow = {
        sequence_number: 2,
        molecular_locus: loci.molecular_locus,
        molecular_value: [],
        molecular_override: [],
        most_likely_allele: [],
        serologic_locus: loci.serologic_locus,
        serologic_value: []
      };
      // If the HLA Typing lab has data for these loci, merge the data into the empty entries
      if (!!this.editState.hla_typing_details && !!this.editState.hla_typing_details[loci.molecular_locus]) {
        sequence1 = Object.assign(sequence1, this.editState.hla_typing_details[loci.molecular_locus][1]);
        sequence2 = Object.assign(sequence2, this.editState.hla_typing_details[loci.molecular_locus][2]);
      }
      // Store the entries for this locus
      result.push(sequence1);
      result.push(sequence2);
    });
    // Build epitope detail rows
    const emptyEpitopeRows = this.possibleEpitopes.map((epitopeDictionaryEntry: HlaEpitope): HlaTypingDetailRow => {
      const epitopeValue = epitopeDictionaryEntry.code || UNKNOWN_LOCUS;
      const typingDetails: HlaTypingDetailsForm = this.editState.hla_typing_details || {};
      const epitopeSequences = typingDetails[epitopeValue] || {};
      const epitopeSequence = epitopeSequences[1] || {};
      return epitopeSequence;
    });
    // Epotopes rows are shown after antigen detail rows that are used to calculate the epitopes
    // E.g. if the only loci that contain epitopes are "A" and "B", then insert epitopes details after those rows
    let rowIndexToInjectEpitopes = 0;
    result.forEach((row: HlaTypingDetailRow, index: number) => {
      const locus = row.molecular_locus || UNKNOWN_LOCUS;
      if (LOCI_THAT_MAY_CONTAIN_EPITOPES.includes(locus)) {
        rowIndexToInjectEpitopes = index + 1;
      }
    });
    // Insert epitopes at the appropriate location in the details table
    result.splice(rowIndexToInjectEpitopes, 0, ...emptyEpitopeRows);
    return result;
  }

  // Event handlers

  /**
   * Loads HLA Typing for the selected recipient.
   *
   * Called after the Card Section has finished loading any relevant lookups.
   *
   * @listens typing#loaded
   */
  private loaded(): void {
    this.$store.dispatch('lookups/load', { lookup: 'hla_dictionary' }).then(() => {
      // Populate epitope dictionary results based on extracted details        
      this.isFinishedLoadingLookups = true;
      this.checkIfLoadingComplete();
    });
  }

  // Begin loading recipient HLA Typing Lab document, HLA Dictionary, and HLA Stem Cell Therapy Procedure
  private mounted(): void {
    this.loadHlaTyping();
    // Load HLA Stem Cell Therapy Procedure only if form is for Recipient
   //TODO: de-scoped for go-live,will come back to it later
    // if (!this.enableDonor) {
    //   this.loadHlaStemCellTherapyProcedure();
    // } else {
    //   this.isFinishedLoadingStemCellTherapyProcedure = true;
    // }
    this.isFinishedLoadingStemCellTherapyProcedure = true;
    this.checkIfLoadingComplete();
    if(!this.canSave){
      this.disableForm();
    }
  }
  private loadHlaStemCellTherapyProcedure(): void {
    this.$store.dispatch('procedures/loadStemCellTherapy', this.recipientId).then(() => {
      this.isFinishedLoadingStemCellTherapyProcedure = true;
      this.checkIfLoadingComplete();
    }).catch(() => {
      // 404 error expected if recipient does not have an HLA Stem Cell Therapy procedure
      this.isFinishedLoadingStemCellTherapyProcedure = true;
      this.checkIfLoadingComplete();
    });
  }

  /**
   * Sets all HLA Typing Technique fields in the Details Table to the value of Technique (all rows)
   *
   * @listens technique_all_rows#changed
   */
  private changedTechniqueAllRows(): void {
    DETAILS_TABLE_LOCUS_SEQUENCE.forEach((loci: HlaTypingLoci) => {
      if (!!this.editState.hla_typing_details) {
        Vue.set(this.editState.hla_typing_details[loci.molecular_locus][1], 'testing_technique', this.editState.technique_all_rows);
        Vue.set(this.editState.hla_typing_details[loci.molecular_locus][2], 'testing_technique', this.editState.technique_all_rows);
      }
    });
  }

  /**
   * Get a reference to a specific row in the HLA Details table
   *
   * @param locus letter corresponding to the location of the gene e.g. "A"
   * @param sequenceNumber 1 or 2, indicating which of the two HLA Typing Detail rows per locus were updated
   * @returns {HlaTypingDetailRow|undefined} reference to detail row object if it exists, undefined otherwise
   */
  private hlaDetail(locus: string, sequenceNumber: number): HlaTypingDetailRow|undefined {
    let hlaTypingDetail = undefined;
    const hlaTypingDetails = this.editState.hla_typing_details;
    if (!!hlaTypingDetails) {
      const hlaTypingDetailsByLocus = hlaTypingDetails[locus];
      if (!!hlaTypingDetailsByLocus) {
        hlaTypingDetail = hlaTypingDetailsByLocus[sequenceNumber];
      }
    }
    return hlaTypingDetail;
  }

  private buildMolecularTags(molecularRelations: HlaMolecularRelation[]): string[] {
    const tagStrings = molecularRelations.map((relation: HlaSerologicalRelation): string => {
      const molecularText = `${relation.code}${relation.tbc ? '-tbc' : ''}`;
      const antigen = molecularText != undefined ? this.parseHlaTypingTag(molecularText) : undefined;
      if (!antigen) {
        return molecularText;
      }
      return antigen.standardText || molecularText;
    });
    return tagStrings;
  }

  private buildSerologicalTags(serologicalRelations: HlaSerologicalRelation[]): string[] {
    const tagStrings = serologicalRelations.map((relation: HlaSerologicalRelation): string => {
      const serologicalText = `${relation.code}${relation.tbc ? '-tbc' : ''}`;
      const antigen = serologicalText != undefined ? this.parseHlaTypingTag(serologicalText) : undefined;
      if (!antigen) {
        return serologicalText;
      }
      return antigen.standardText || serologicalText;
    });
    return tagStrings;
  }

  private revalidateMolecular(locus: string, sequenceNumber: number): void {
    const molecularReference = `molecular-observer-${locus}-${sequenceNumber}`;
    const molecularObserver = this.$refs[molecularReference] as any;
    if (molecularObserver) molecularObserver.validate();
  }

  private revalidateMolecularOverride(locus: string, sequenceNumber: number): void {
    const molecularOverrideReference = `molecular-override-observer-${locus}-${sequenceNumber}`;
    const molecularOverrideObserver = this.$refs[molecularOverrideReference] as any;
    if (molecularOverrideObserver) molecularOverrideObserver.validate();
  }

  private revalidateSerologic(locus: string, sequenceNumber: number): void {
    const serologicReference = `serologic-observer-${locus}-${sequenceNumber}`;
    const serologicObserver = this.$refs[serologicReference] as any;
    if (serologicObserver) serologicObserver.validate();
  }

  /**
   * Fills in associated fields when the user enters a Molecular value and other values can be inferred
   *
   * @listens editState.hla_typing_details[locus][sequenceNumber].molecular_value#input
   * @param locus letter corresponding to the location of the gene e.g. "A"
   * @param sequenceNumber 1 or 2, indicating which of the two HLA Typing Detail rows per locus were updated
   */
  private onMolecularInput(locus: string, sequenceNumber: number): void {
    // Reference v-model for the changed detail row
    const hlaTypingDetail = this.hlaDetail(locus, sequenceNumber) || {};
    // Fetch text from the first tag entered
    const molecularTags = hlaTypingDetail.molecular_value || [undefined];
    const molecularText = molecularTags[0];
    // Skip if text is blank
    if (molecularText == undefined || molecularText.length === 0) {
      this.updateEpitopeDictionaryResults();
      return;
    }
    // Special case: populate Molecular Override & Serologic fields -- based on Molecular --
    if (molecularText === '--') {
      Vue.set(hlaTypingDetail, 'molecular_override', ['--']);
      Vue.set(hlaTypingDetail, 'serologic_value', ['--']);
      // Validate the Molecular Override & Serologic fields when they are populated based on Molecular
      this.revalidateMolecularOverride(locus, sequenceNumber);
      this.revalidateSerologic(locus, sequenceNumber);
      // Clear Most Likely Allele
      Vue.set(hlaTypingDetail, 'most_likely_allele', []);
      return;
    }
    // Parse text as antigen
    const antigen = molecularText != undefined ? this.parseHlaTypingTag(molecularText) : undefined;
    // Standardize Molecular value
    const standardizedMolecularValue = antigen != undefined ? antigen.standardAlleleGroupOnly : molecularText;
    if (standardizedMolecularValue != molecularText) {
      const standardizedMolecularTags = [standardizedMolecularValue];
      Vue.set(hlaTypingDetail, 'molecular_value', standardizedMolecularTags);
    }
    // Populate Molecular Override based on what was entered in Molecular
    const standardizedMolecularOverrideValue = antigen != undefined ? antigen.standardAlleleSpecific : molecularText;
    if (standardizedMolecularOverrideValue) {
      const standardizedMolecularTags = standardizedMolecularOverrideValue !== null ? [standardizedMolecularOverrideValue] : [];
      Vue.set(hlaTypingDetail, 'molecular_override', standardizedMolecularTags);
    }
    // Populate Most Likely Allele if that is what was entered in Molecular
    const standardizedMostLikelyAlleleValue = antigen != undefined ? antigen.standardMostLikelyAllele : null;
    if (standardizedMostLikelyAlleleValue) {
      const standardizedMolecularTags = standardizedMostLikelyAlleleValue !== null ? [standardizedMostLikelyAlleleValue] : [];
      Vue.set(hlaTypingDetail, 'most_likely_allele', standardizedMolecularTags);
    } else {
      // NOTE: here is where we assume that if there is no standardized most likely allele value we should clear it (ATQ-694)
      Vue.set(hlaTypingDetail, 'most_likely_allele', []);
    }
    // Retrieve the Serological equivalents for the Molecular value
    const antigenQuery: HlaTypingTag = antigen || { standardText: molecularText };
    const serologicalRelations = this.findSerologicalByMolecular(locus, antigenQuery);
    if (serologicalRelations != undefined) {
      const serologicalTags = this.buildSerologicalTags(serologicalRelations);
      if (serologicalTags.length > 0) {
        Vue.set(hlaTypingDetail, 'serologic_value', serologicalTags);
        // Validate the Serologic field when it is populated based on Molecular
        this.revalidateSerologic(locus, sequenceNumber);
      }
    }
    // Populate epitope dictionary results based on extracted details
    this.updateEpitopeDictionaryResults();
  }

  /**
   * Fills in associated fields when the user enters a Molecular Override value and other values can be inferred
   *
   * @listens editState.hla_typing_details[locus][sequenceNumber].molecular_override#input
   * @param locus letter corresponding to the location of the gene e.g. "A"
   * @param sequenceNumber 1 or 2, indicating which of the two HLA Typing Detail rows per locus were updated
   */
  private onMolecularOverrideInput(locus: string, sequenceNumber: number): void {
    // Reference v-model for the changed detail row
    const hlaTypingDetail = this.hlaDetail(locus, sequenceNumber) || {};
    // Fetch text from the first tag entered
    const molecularOverrideTags = hlaTypingDetail.molecular_override || [undefined];
    const molecularOverrideText = molecularOverrideTags[0];
    // Skip if text is blank
    if (molecularOverrideText == undefined || molecularOverrideText.length === 0) {
      this.updateEpitopeDictionaryResults();
      return;
    }
    // Special case: populate Molecular & Serologic -- based on Molecular Override --
    if (molecularOverrideText === '--') {
      Vue.set(hlaTypingDetail, 'molecular_value', ['--']);
      Vue.set(hlaTypingDetail, 'serologic_value', ['--']);
      // Validate the Molecular & Serologic fields when they are populated based on Molecular Override
      this.revalidateMolecular(locus, sequenceNumber);
      this.revalidateSerologic(locus, sequenceNumber);
      // Clear Most Likely Allele
      Vue.set(hlaTypingDetail, 'most_likely_allele', []);
      return;
    }
    // Parse text as antigen
    const antigen = molecularOverrideText != undefined ? this.parseHlaTypingTag(molecularOverrideText) : undefined;
    // Standardize Molecular Override value
    const standardizedMolecularOverrideValue = antigen != undefined ? antigen.standardAlleleSpecific : molecularOverrideText;
    if (standardizedMolecularOverrideValue != molecularOverrideText) {
      const standardizedMolecularTags = standardizedMolecularOverrideValue !== null ? [standardizedMolecularOverrideValue] : [];
      Vue.set(hlaTypingDetail, 'molecular_override', standardizedMolecularTags);
    }
    // Populate Molecular
    if (antigen) {
      const mostLikelyAlleleTags = [antigen.standardAlleleGroupOnly];
      Vue.set(hlaTypingDetail, 'molecular_value', mostLikelyAlleleTags);
      // Validate the Molecular field when it is populated based on Most Likely Allele
      this.revalidateMolecular(locus, sequenceNumber);
    } else {
      // If we cannot parse, then populate raw input
      Vue.set(hlaTypingDetail, 'molecular_value', [molecularOverrideText]);
    }
    // Populate Most Likely Allele if that is what was entered in Molecular
    const standardizedMostLikelyAlleleValue = antigen != undefined ? antigen.standardMostLikelyAllele : null;
    if (standardizedMostLikelyAlleleValue) {
      const standardizedMolecularTags = standardizedMostLikelyAlleleValue !== null ? [standardizedMostLikelyAlleleValue] : [];
      Vue.set(hlaTypingDetail, 'most_likely_allele', standardizedMolecularTags);
    } else {
      // NOTE: here is where we assume that if there is no standardized most likely allele value we should clear it (ATQ-694)
      Vue.set(hlaTypingDetail, 'most_likely_allele', []);
    }
    // Retrieve Serological equivalents for Most Likely Allele value
    const antigenQuery: HlaTypingTag = antigen || { standardText: molecularOverrideText };
    const serologicalRelations = this.findSerologicalByMostLikelyAllele(locus, antigenQuery);
    if (serologicalRelations != undefined) {
      const serologicalTags = this.buildSerologicalTags(serologicalRelations);
      if (serologicalTags.length > 0) {
        Vue.set(hlaTypingDetail, 'serologic_value', serologicalTags);
        // Validate the Serologic field when it is populated based on Most Likely Allele
        this.revalidateSerologic(locus, sequenceNumber);
      }
    }
    // Populate epitope dictionary results based on extracted details
    this.updateEpitopeDictionaryResults();
  }

  /**
   * Fills in associated fields when the user enters a Most Likely Allele value and other values can be inferred
   *
   * @listens editState.hla_typing_details[locus][sequenceNumber].most_likely_allele#input
   * @param locus letter corresponding to the location of the gene e.g. "A"
   * @param sequenceNumber 1 or 2, indicating which of the two HLA Typing Detail rows per locus were updated
   */
  private onMostLikelyAlleleInput(locus: string, sequenceNumber: number): void {
    // Reference v-model for the changed detail row
    const hlaTypingDetail = this.hlaDetail(locus, sequenceNumber) || {};
    // Fetch text from the first tag entered
    const mostLikelyAlleleTags = hlaTypingDetail.most_likely_allele || [undefined];
    const mostLikelyAlleleText = mostLikelyAlleleTags[0];
    // Skip if text is blank
    if (mostLikelyAlleleText == undefined || mostLikelyAlleleText.length === 0) {
      this.updateEpitopeDictionaryResults();
      return;
    }
    // Special case: populate Molecular, Molecular Override, & Serologic -- based on Most Likely Allele --
    if (mostLikelyAlleleText === '--') {
      Vue.set(hlaTypingDetail, 'molecular_value', ['--']);
      Vue.set(hlaTypingDetail, 'molecular_override', ['--']);
      Vue.set(hlaTypingDetail, 'serologic_value', ['--']);
      // Validate the Molecular, Molecular Override, & Serologic fields when they are populated based on Most Likely Allele
      this.revalidateMolecular(locus, sequenceNumber);
      this.revalidateMolecularOverride(locus, sequenceNumber);
      this.revalidateSerologic(locus, sequenceNumber);
      // Clear Most Likely Allele
      Vue.set(hlaTypingDetail, 'most_likely_allele', []);
      return;
    }
    // Parse text as antigen
    const antigen = mostLikelyAlleleText != undefined ? this.parseHlaTypingTag(mostLikelyAlleleText) : undefined;
    // Standardize Most Likely Allele value
    const standardizedMostLikelyAlleleValue = antigen != undefined ? antigen.standardMostLikelyAllele : mostLikelyAlleleText;
    if (standardizedMostLikelyAlleleValue != mostLikelyAlleleText) {
      const standardizedMolecularTags = standardizedMostLikelyAlleleValue !== null ? [standardizedMostLikelyAlleleValue] : [];
      Vue.set(hlaTypingDetail, 'most_likely_allele', standardizedMolecularTags);
    }
    // Populate Molecular
    if (antigen) {
      const mostLikelyAlleleTags = [antigen.standardAlleleGroupOnly];
      Vue.set(hlaTypingDetail, 'molecular_value', mostLikelyAlleleTags);
      // Validate the Molecular field when it is populated based on Most Likely Allele
      this.revalidateMolecular(locus, sequenceNumber);
    }
    // Populate Molecular Override based on what was entered in Molecular
    const standardizedMolecularOverrideValue = antigen != undefined ? antigen.standardAlleleSpecific : null;
    if (standardizedMolecularOverrideValue) {
      const standardizedMolecularTags = standardizedMolecularOverrideValue !== null ? [standardizedMolecularOverrideValue] : [];
      Vue.set(hlaTypingDetail, 'molecular_override', standardizedMolecularTags);
    }
    // Retrieve Serological equivalents for Most Likely Allele value
    const antigenQuery: HlaTypingTag = antigen || { standardText: mostLikelyAlleleText };
    const serologicalRelations = this.findSerologicalByMostLikelyAllele(locus, antigenQuery);
    if (serologicalRelations != undefined) {
      const serologicalTags = this.buildSerologicalTags(serologicalRelations);
      if (serologicalTags.length > 0) {
        Vue.set(hlaTypingDetail, 'serologic_value', serologicalTags);
        // Validate the Serologic field when it is populated based on Most Likely Allele
        this.revalidateSerologic(locus, sequenceNumber);
      }
    }
    // Populate epitope dictionary results based on extracted details
    this.updateEpitopeDictionaryResults();
  }

  /**
   * Fills in associated fields when the user enters a Serologic value and other values can be inferred
   *
   * @listens editState.hla_typing_details[locus][sequenceNumber].serologic_value#input
   * @param locus letter corresponding to the location of the gene e.g. "A"
   * @param sequenceNumber 1 or 2, indicating which of the two HLA Typing Detail rows per locus were updated
   */
  private onSerologicInput(locus: string, sequenceNumber: number): void {
    // Reference v-model for the changed detail row
    const hlaTypingDetail = this.hlaDetail(locus, sequenceNumber) || {};
    // Fetch text from the first and last tags entered
    const serologicTags = hlaTypingDetail.serologic_value || [];
    // Skip if no tags
    if (serologicTags == undefined || serologicTags.length === 0) {
      this.updateEpitopeDictionaryResults();
      return;
    }
    // First tag is used to match equivalents
    const firstSerologicText = serologicTags[0];
    // Standardize all Serological values
    if (serologicTags !== undefined && serologicTags.length > 0) {
      let wasStandardized = false;
      const standardizedTags: string[] = serologicTags.map((serologic: string): string => {
        const antigen = serologic != undefined ? this.parseHlaTypingTag(serologic) : undefined;
        if (antigen == undefined) {
          return serologic;
        }
        const standardized = antigen.standardText;
        if (standardized != serologic) {
          wasStandardized = true;
        }
        return antigen.standardText;
      });
      if (wasStandardized) {
        Vue.set(hlaTypingDetail, 'serologic_value', standardizedTags);
      }
    }
    // Populate epitope dictionary results based on extracted details
    this.updateEpitopeDictionaryResults();
    // Cancel any previous confirmation of mapping override
    Vue.set(hlaTypingDetail, 'override_mapping', false);
  }

  private onEpitopeOverrideChange(epitopeLocus: string): void {
    // Reference v-model for the changed detail row
    if (!this.editState.hla_typing_details) {
      return;
    }
    // Note: epitope only has sequence_number of 1 in form schema
    const hlaTypingDetail = this.hlaDetail(epitopeLocus, 1) || {};
    // Initialize override value as opposite of dictionary value
    const dictionaryValue = this.extractEpitopeResult(hlaTypingDetail.epitopeDictionaryResult || null);
    const overrideValue = this.buildEpitopeResult(!dictionaryValue);
    Vue.set(this.editState.hla_typing_details[epitopeLocus][1], 'epitopeOverrideResult', overrideValue);
  }

  // Public methods

  /**
   * Gets changes from the edit state as a patch for the recipient's HLA Typing.
   *
   * If recipient does not yet have HLA Typing, then the patch contains all parameters for the post request.
   *
   * @returns {any} object containing field changes
   */
  public extractPatch(): any {
    return this.editState ? this.extractHlaTypingPatch(this.editState) : {};
  }

  /**
   * Saves the current edit state.
   *
   * Prepares HLA Typing payload, including endpoint IDs and parameter changes. Handles posting new a new entry as well
   * as patching an existing entry. Registers a SaveResult indicating whether the save was successful. If successful,
   * then the SaveResult contains the HLA Typing record. Otherwise, the SaveResult contains a textual description of
   * the error(s) encountered as well as any field-specific validation errors that were raised.
   */
  public savePatch(): void {
    if (this.livingDonor) {
      this.savePatchLivingDonor();
    } else if (this.enableDonor) {
      this.savePatchDonor();
    } else {
      this.savePatchRecipient();
    }
  }

  public disableForm() {
    this.isFormDisabled = true;
  }

  public enableForm() {
    this.isFormDisabled = false;
  }

  private savePatchRecipient(): void {
    // Refer to the save provider that handles this form area
    const saveProvider = this.$refs.saveHlaTyping as unknown as SaveProvider;
    // Report to parent that saving has began
    this.$emit('save', 'hlaTyping');
    // Generate payload based on current edit state
    const hlaTypingPatch = this.extractPatch();
    // Setup saving payload
    const payload = {
      id: this.hlaTyping && this.hlaTyping._id && this.hlaTyping._id.$oid ? this.hlaTyping._id!.$oid : undefined,
      recipientId: this.recipientId,
      hlaTyping: hlaTypingPatch
    };
    // Dispatch save action and register the response
    this.disableForm();
    this.$store.dispatch('labs/saveHlaTyping', payload).then((success: SaveResult) => {
      // If successful, update the recipient's HLA Typing and show success notification
      const sanitizedHlaTyping: LabHLATypingRecipient = success.responseData.hla_typing || {};
      this.$store.commit('labs/setHlaTyping', sanitizedHlaTyping);
      this.initializeForm();
      saveProvider.registerSaveResult(success);
      this.enableForm();
    }).catch((error: SaveResult) => {
      // Emit event to handle errors
      this.$emit('handleErrors', error);
      // Show error notification
      this.handleErrors(error);
    });
  }

  private savePatchDonor(): void {
    // Refer to the save provider that handles this form area
    const saveProvider = this.$refs.saveHlaTyping as unknown as SaveProvider;
    // Report to parent that saving has began
    this.$emit('save', 'hlaTyping');
    // Generate payload based on current edit state
    const hlaTypingPatch = this.extractPatch();
    // Setup saving payload
    const payload = {
      id: this.hlaTyping && this.hlaTyping._id && this.hlaTyping._id.$oid ? this.hlaTyping._id!.$oid : undefined,
      donorId: this.donorClientId,
      hlaTyping: hlaTypingPatch,
    };
    // Dispatch save action and register the response
    this.disableForm();
    this.$store.dispatch('labs/saveHlaTypingDonor', payload).then((success: SaveResult) => {
      // If successful, update the donor's HLA Typing and show success notification
      const sanitizedHlaTyping: LabHlaTypingDonor = success.responseData.hla_typing || {};
      this.$store.commit('labs/setHlaTyping', sanitizedHlaTyping);
      this.initializeForm();
      saveProvider.registerSaveResult(success);
      this.enableForm();
      // Request donor page reload data that might be affected by this form changing
      this.$emit('reload');
    }).catch((error: SaveResult) => {
      // Emit event to handle errors
      this.$emit('handleErrors', error);
      // Show error notification
      this.handleErrors(error);
    });
  }

  private savePatchLivingDonor(): void {
    // Refer to the save provider that handles this form area
    const saveProvider = this.$refs.saveHlaTyping as unknown as SaveProvider;
    // Report to parent that saving has began
    this.$emit('save', 'hlaTyping');
    // Generate payload based on current edit state
    const hlaTypingPatch = this.extractPatch();
    // Setup saving payload
    const payload = {
      id: this.hlaTyping && this.hlaTyping._id && this.hlaTyping._id.$oid ? this.hlaTyping._id!.$oid : undefined,
      livingDonorId: this.livingDonorId,
      hlaTyping: hlaTypingPatch,
    };
    // Dispatch save action and register the response
    this.disableForm();
    this.$store.dispatch('labs/saveHlaTypingLivingDonor', payload).then((success: SaveResult) => {
      // If successful, update the living donor's HLA Typing and show success notification
      const sanitizedHlaTyping: LabHlaTypingDonor = success.responseData.hla_typing || {};
      this.$store.commit('labs/setHlaTyping', sanitizedHlaTyping);
      this.initializeForm();
      saveProvider.registerSaveResult(success);
      this.enableForm();
    }).catch((error: SaveResult) => {
      // Emit event to handle errors
      this.$emit('handleErrors', error);
      // Show error notification
      this.handleErrors(error);
    });
  }

  private handleErrors(error: SaveResult): void {
    // Check for exception flow warnings
    if (error.warning) {
      const overrideMappingModal = this.$refs.overrideMappingModal as OverrideMappingModal;
      overrideMappingModal.showException(error);
    }
    // Show error notification
    const saveProvider = this.$refs.saveHlaTyping as unknown as SaveProvider;
    saveProvider.registerSaveResult(error);
    this.enableForm();
  }

  private handleOverrideMapping(error: SaveResult): void {
    // Get exceptions from stored error
    const exceptions = (error || {}).warningExceptions || [];
    if (exceptions.length === 0 || !this.editState.hla_typing_details) {
      return;
    }
    // This assumes that handling the first exception is sufficient
    const firstException: { rule: string, incidents: { molecular_locus: string, sequence_number: number }[] } = exceptions[0];
    // Get all HLA typing detail row incidents from the exception
    const incidents = firstException.incidents || [];
    // Get current detail row data from the edit state
    const details: HlaTypingDetailsForm = this.editState.hla_typing_details;
    // Update 'override_mapping' on each mismatched antigen based on exception details
    incidents.forEach((incident: { molecular_locus: string, sequence_number: number }) => {
      const detail: HlaTypingDetailRow = details[incident.molecular_locus][incident.sequence_number];
      details[incident.molecular_locus][incident.sequence_number] = {
        ...detail,
        override_mapping: true,
      };
    });
    // Update the form state all at once
    Vue.set(this.editState, 'hla_typing_details', details);
    // Try the original save action again
    this.savePatch();
  }

  /**
   * Clears all save notifications shown by the form.
   *
   * Gets the Save Provider associated with the form, and requests that it reset its own Save Toolbar
   */
  public resetSaveToolbar(): void {
    const saveProvider = this.$refs.saveHlaTyping as unknown as SaveProvider;
    saveProvider.resetSaveToolbar();
  }

  // Private methods

  /**
   * Fetch and store HLA Typing associated with the selected recipient, deceased donor, or living donor
   *
   * @emits loaded
   */
  private loadHlaTyping(): void {
    // Skip if underlying patient record has not been created yet
    if (this.newRecord) {
      this.$store.commit('labs/setHlaTyping', {});
      this.isFinishedLoadingHlaTypingLab = true;
      this.checkIfLoadingComplete();
      return;
    }

    // Load the HLA typing lab result
    if (this.livingDonor) {
      this.loadHlaTypingLivingDonor();
    } else if (this.enableDonor) {
      this.loadHlaTypingDonor();
    } else {
      this.loadHlaTypingRecipient();
    }
  }

  private loadHlaTypingRecipient(): void {
    this.$store.dispatch('labs/loadHlaTyping', this.recipientId).then(() => {
      this.isFinishedLoadingHlaTypingLab = true;
      this.checkIfLoadingComplete();
    }).catch(() => {
      // 404 error expected if patient does not yet have an HLA Typing lab result
      this.isFinishedLoadingHlaTypingLab = true;
      this.checkIfLoadingComplete();
    });
  }

  private loadHlaTypingDonor(): void {
    this.$store.dispatch('labs/loadHlaTypingDonor', this.donorClientId).then(() => {
      this.isFinishedLoadingHlaTypingLab = true;
      this.checkIfLoadingComplete();
    }).catch(() => {
      // 404 error expected if patient does not yet have an HLA Typing lab result
      this.isFinishedLoadingHlaTypingLab = true;
      this.checkIfLoadingComplete();
    });
  }

  private loadHlaTypingLivingDonor(): void {
    this.$store.dispatch('labs/loadHlaTypingLivingDonor', this.livingDonorId).then(() => {
      this.isFinishedLoadingHlaTypingLab = true;
      this.checkIfLoadingComplete();
    }).catch(() => {
      // 404 error expected if patient does not yet have an HLA Typing lab result
      this.isFinishedLoadingHlaTypingLab = true;
      this.checkIfLoadingComplete();
    });
  }

  // Initialize the form after all necessary data has finished loading
  private checkIfLoadingComplete(): void {
    if (this.isFinishedLoading) {
      this.$emit('loaded', 'hlaTyping');
      this.initializeForm();
      this.loadValidationRules();
    }
  }

  // Load the 'new' or 'edit' validation rules based on patient type
  private loadValidationRules(): void {
    const isNewHlaTyping = !this.hlaTyping?._id;

    if (this.livingDonor) {
      this.loadValidationRulesLivingDonor(isNewHlaTyping);
    } else if (this.enableDonor) {
      this.loadValidationRulesDonor(isNewHlaTyping);
    } else {
      this.loadValidationRulesRecipient(isNewHlaTyping);
    }
  }

  // Load 'new' or 'edit' validation rules for Living Donor HLA typing
  private loadValidationRulesLivingDonor(isNewHlaTyping: boolean): void {
    if (isNewHlaTyping) {
      // Load 'new' validation rules
      this.$store.dispatch('validations/loadNew', { view: `living_donors/${this.livingDonorId}/hla_typing`, action: 'new' });
    } else {
      // Load 'edit' validation rules
      // NOTE: this is using 'loadNew' action because HLA typing 'edit' has no lab ID in the route
      this.$store.dispatch('validations/loadNew', { view: `living_donors/${this.livingDonorId}/hla_typing`, action: 'edit' });
    }
  }

  // Load 'new' or 'edit' validation rules for Deceased Donor HLA typing
  private loadValidationRulesDonor(isNewHlaTyping: boolean): void {
    if (isNewHlaTyping) {
      // Load 'new' validation rules
      this.$store.dispatch('validations/loadNew', { view: `donors/${this.donorClientId}/hla_typing`, action: 'new' });
    } else {
      // Load 'edit' validation rules
      // NOTE: this is using 'loadNew' action because HLA typing 'edit' has no lab ID in the route
      this.$store.dispatch('validations/loadNew', { view: `donors/${this.donorClientId}/hla_typing`, action: 'edit' });
    }
  }

  // Load 'new' or 'edit' validation rules for Recipient HLA typing
  private loadValidationRulesRecipient(isNewHlaTyping: boolean): void {
    if (isNewHlaTyping) {
      // Load 'new' validation rules
      this.$store.dispatch('validations/loadNew', { view: `recipients/${this.recipientId}/hla_typing`, action: 'new' });
    } else {
      // Load 'edit' validation rules
      // NOTE: this is using 'loadNew' action because HLA typing 'edit' has no lab ID in the route
      this.$store.dispatch('validations/loadNew', { view: `recipients/${this.recipientId}/hla_typing`, action: 'edit' });
    }
  }

  /**
   * Populates form state with the HLA Typing associated with the selected recipient.
   */
  private initializeForm(): void {
    this.$store.commit('pageState/set', {
      pageKey: 'hlaTyping',
      value: this.buildHlaTypingForm(this.hlaTyping)
    });
    // Populate epitope dictionary results based on extracted details
    this.updateEpitopeDictionaryResults();
  }

  /**
   * Extracts HLA Typing data from the selected Typing lab based on the form structure.
   *
   * @param hlaTypingData HLA Typing data fetched from the API
   * @returns {HlaTypingForm} HLA Typing data formatted for the form
   */
  private buildHlaTypingForm(hlaTypingData?: LabHLATypingRecipient|LabHlaTypingDonor|null): HlaTypingForm {
    if (!hlaTypingData) {
      return {};
    }
    // Defaults to first user's lab id if one present, otherwise show select
    const userLabDefault = this.laboratoryLookup.length == 1 ? this.laboratoryLookup[0].code : undefined;

    const updatedByUserLab = this.hlaLaboratoryLookup.find((lab : Laboratory) => {
      return lab._id?.$oid == hlaTypingData.updated_by_lab_id?.$oid && lab.name;
    });

    return {
      hla_lab: hlaTypingData.lab_id ? hlaTypingData.lab_id.$oid : userLabDefault,
      comments: hlaTypingData.comments,
      last_update: this.parseDateUiFromDateTime(hlaTypingData.updated_at),
      last_updated_by: hlaTypingData.updated_by,
      last_updated_user_lab: updatedByUserLab?.name || undefined,
      typing_incomplete: hlaTypingData.typing_incomplete,
      hla_typing_details: this.buildHlaTypingDetailsForm(hlaTypingData),
      submitDate: this.parseDateUi(hlaTypingData.test_date || undefined),
    };
  }

  // Fetch read-only HLA Stem Cell Therapy Date from recipient procedure record
  get hsctDate(): string {
    const procedure: ProcedureStemCellTherapy = this.selectedStemCellTherapy || {};
    const hsctDate = procedure?.date || undefined;
    return this.parseDateUi(hsctDate) || '-';
  }

  // Generate locus group, which is an object containing two antigen 'sequence' rows
  private buildLocusGroup(molecularLocus: string, serologicLocus: string): HlaTypingLocusGroup {
    return {
      1: {
        sequence_number: 1,
        molecular_locus: molecularLocus,
        serologic_locus: serologicLocus,
      },
      2: {
        sequence_number: 2,
        molecular_locus: molecularLocus,
        serologic_locus: serologicLocus,
      },
    };
  }

  /**
   * Extracts HLA Typing details from the selected Typing lab based on the form structure.
   *
   * @param hlaTypingData HLA Typing data fetched from the API
   * @returns {HlaTypingDetailsForm} HLA Typing Details formatted for the form
   */
  private buildHlaTypingDetailsForm(hlaTypingData?: LabHLATypingRecipient): HlaTypingDetailsForm {
    // Setup empty form structure
    const result: HlaTypingDetailsForm = {};
    DETAILS_TABLE_LOCUS_SEQUENCE.forEach((loci: HlaTypingLoci) => {
      result[loci.molecular_locus] = this.buildLocusGroup(loci.molecular_locus, loci.serologic_locus);
    });
    // Build HLA Typing form based on HLA Typing Lab results
    const antigens = hlaTypingData && hlaTypingData.antigens ? hlaTypingData.antigens : [];
    // Build antigen details
    antigens.forEach((hlaDetail: LabHlaTypingAntigen) => {
      const locusKey = hlaDetail && hlaDetail.molecular_locus ? hlaDetail.molecular_locus : UNKNOWN_LOCUS;
      if (OBSOLETE_LOCI.includes(locusKey)) {
        // Ignore obsolete antigen loci. E.g. "BW" data is now stored as epitopes
        return;
      }

      const sequenceNumberKey = hlaDetail && hlaDetail.sequence_number ? hlaDetail.sequence_number : null;

      if (!!locusKey && !!sequenceNumberKey) {
        // Antigen row
        const detail = this.buildHlaTypingDetail(hlaDetail);
        let antigenLocusGroup = result[locusKey];

        // NOTE: fallback, if we encounter an unexpected locus then initialize a new locus group so we can continue loading
        if (!antigenLocusGroup) antigenLocusGroup = this.buildLocusGroup(locusKey, locusKey);
        antigenLocusGroup[sequenceNumberKey] = detail;
      }
    });
    // Build epitope details
    const labEpitopes = hlaTypingData && hlaTypingData.epitopes ? hlaTypingData.epitopes : [];
    this.possibleEpitopes.forEach((possibleEpitope: HlaEpitope) => {
      const epitopeValue = possibleEpitope.code || UNKNOWN_LOCUS;
      result[epitopeValue] = { 1: this.buildEpitopeDetail(possibleEpitope, labEpitopes) };
    });
    return result;
  }

  private buildEpitopeDetail(possibleEpitope: HlaEpitope, labEpitopes: LabHlaTypingEpitope[]): HlaTypingDetailRow {
    const matchingEpitope = labEpitopes.find((labEpitope: LabHlaTypingEpitope) => {
      const epitopeValue = labEpitope.epitope_value || UNKNOWN_LOCUS;
      return possibleEpitope.code === epitopeValue || possibleEpitope.ui_aliases.includes(epitopeValue);
    }) || {};
    const isOverride = !!matchingEpitope.override_date;
    const result = {
      isEpitope: true,
      molecular_locus: possibleEpitope.value,
      serologic_locus: possibleEpitope.code,
      epitopeDictionaryResult: this.buildEpitopeResult(matchingEpitope.present_dictionary),
      epitopeOverride: isOverride,
      epitopeOverrideResult: this.buildEpitopeResult(matchingEpitope.present),
      comments: matchingEpitope.override_comment,
    };
    return result;
  }

  private updateEpitopeDictionaryResults(): void {
    if (!this.editState || !this.editState.hla_typing_details) {
      return;
    }
    // Prepare dictionary query
    let queryAntigens: HlaTypingTag[] = [];
    // Pull antigens from rows that may contain the epitopes
    const rowDetails = this.editState.hla_typing_details;
    let parsedAntigens: HlaTypingTag[] = [];
    LOCI_THAT_MAY_CONTAIN_EPITOPES.forEach((locus: string) => {
      parsedAntigens = this.chooseAntigensForQuery(rowDetails[locus][1]);
      if (parsedAntigens && parsedAntigens.length > 0) {
        queryAntigens = queryAntigens.concat(parsedAntigens);
      }
      parsedAntigens = this.chooseAntigensForQuery(rowDetails[locus][2]);
      if (parsedAntigens && parsedAntigens.length > 0) {
        queryAntigens = queryAntigens.concat(parsedAntigens);
      }
    });
    // Get epitopes from the dictionary
    const dictionaryResults = this.findEpitopes(queryAntigens);
    // Populate epitope dictionary results
    this.possibleEpitopes.forEach((possibleEpitope: HlaEpitope) => {
      const epitopeValue = possibleEpitope.code;
      const epitopeDictionaryResult = dictionaryResults.includes(epitopeValue);
      const epitopeDictionaryResultCode = this.buildEpitopeResult(epitopeDictionaryResult);
      const epitopeOption = this.epitopeOptions.find((option: { code: any; value: string }) => {
        return option.code === epitopeDictionaryResultCode;
      });
      const epitopeDictionaryResultValue = epitopeOption ? epitopeOption.value : '-';
      Vue.set(this.editState.hla_typing_details![epitopeValue][1], 'epitopeDictionaryResult', epitopeDictionaryResultCode);
      Vue.set(this.editState.hla_typing_details![epitopeValue][1], 'epitopeDictionaryResultValue', epitopeDictionaryResultValue);
    });
  }

  /**
   * Returns an array of HLA Typing tags prefixed with gene loci to support HLA Dictionary epitope queries
   * For a single detail row, there may be 0, 1, or many antigens for the query.
   */
  private chooseAntigensForQuery(detail: HlaTypingDetailRow): HlaTypingTag[] {
    let antigen: HlaTypingTag|null;
    // First try Molecular Locus with Most Likely Allele
    const molecularLocus = detail.molecular_locus;
    const mostLikelyAllele = detail.most_likely_allele && detail.most_likely_allele.length > 0 ? detail.most_likely_allele[0] : null;
    if (mostLikelyAllele !== null) {
      antigen = this.parseHlaTypingTag(`${molecularLocus}*${mostLikelyAllele}`) || null;
      if (antigen !== null) {
        return [antigen];
      }
    }
    // Second try Molecular Locus with Molecular Value
    const molecularValue = detail.molecular_value && detail.molecular_value.length > 0 ? detail.molecular_value[0] : null;
    if (molecularValue !== null) {
      antigen = this.parseHlaTypingTag(`${molecularLocus}*${molecularValue}`) || null;
      if (antigen !== null) {
        return [antigen];
      }
    }
    // Third try Molecular Locus with all of the Serological Values
    // This is counter-intuitive, because usually the Serological Locus and Value are used together to read the antigen
    // However the HLA Dictionary entries are all listed by Molecular Locus so we will use that here as well
    // We will also return all of the possibly multiple serologic antigens, in case they have different epitopes
    const serologicalValues = detail.serologic_value && detail.serologic_value.length > 0 ? detail.serologic_value : null;
    if (serologicalValues !== null) {
      const serologicalAntigens: HlaTypingTag[] = [];
      serologicalValues.forEach((serologic: string) => {
        antigen = this.parseHlaTypingTag(`${molecularLocus}*${serologic}`) || null;
        if (antigen !== null) {
          serologicalAntigens.push(antigen);
        }
      });
      if (serologicalAntigens && serologicalAntigens.length > 0) {
        return serologicalAntigens;
      }
    }
    // Return empty array if no meaningful antigens could be extracted from the detail row
    return [];
  }

  /**
   * Fills an individual HLA Typing detail Molecular and Serologic values based on whatever data is present
   *
   * @param hlaTypingDetail one HLA Typing detail row fetched from API
   * @returns {HlaTypingDetailRow} HLA Typing detail with extra information filled in
   */
  private buildHlaTypingDetail(hlaTypingDetail: LabHlaTypingAntigen): HlaTypingDetailRow {
    // Extract antigens
    const molecular_value = hlaTypingDetail.molecular_value || null;
    const most_likely_allele = hlaTypingDetail.most_likely_allele || undefined;
    const serological_values = hlaTypingDetail.serological_values || null;

    const molecularValue = this.parseMolecularValue(molecular_value, most_likely_allele);
    const molecularOverrideValue = this.parseMolecularOverrideValue(molecular_value);
    const mostLikelyAlleleValue = this.parseMostLikelyAllele(molecular_value, most_likely_allele);
    const serologicalValues = this.parseSerologicalValues(serological_values);

    // Setup row fields
    const literalDetail: HlaTypingDetailRow = {
      _id: hlaTypingDetail._id,
      sequence_number: hlaTypingDetail.sequence_number || undefined,
      molecular_locus: hlaTypingDetail.molecular_locus || undefined,
      molecular_value: !!molecularValue ? [molecularValue] : [],
      molecular_override: !!molecularOverrideValue ? [molecularOverrideValue] : [],
      most_likely_allele: !!mostLikelyAlleleValue ? [mostLikelyAlleleValue] : [],
      serologic_locus: hlaTypingDetail.serological_locus || undefined,
      serologic_value: serologicalValues || [],
      testing_technique: hlaTypingDetail.typing_technique_code || undefined,
      comments: hlaTypingDetail.comments || undefined,
      override_mapping: hlaTypingDetail.override_mapping || false,
    };
    return literalDetail;
  }

  private buildEpitopeResult(present?: boolean|null): string|null {
    if (present === null || present === undefined) {
      return null;
    } else if (present) {
      return EPITOPE_PRESENT;
    } else {
      return EPITOPE_NOT_PRESENT;
    }
  }

  private extractEpitopeResult(resultCode: string|null): boolean|null {
    if (resultCode === EPITOPE_PRESENT) {
      return true;
    } else if (resultCode === EPITOPE_NOT_PRESENT) {
      return false;
    } else {
      return null;
    }
  }

  /**
   * Extract the gene from an antibody string that may have allele-specific information.
   *
   * E.g. returns "01" if the input is "01:02"
   *
   * @param rawMolecular string representation of an antibody that may or may not have allele-specific information
   */
  private parseMolecularValue(rawMolecular?: string|null, isMostLikelyAllele?: boolean): string|undefined {
    // If there is no data, molecular is blank
    if (!rawMolecular) {
      return undefined;
    }
    // If we cannot parse, then we check the boolean most_likely_allele value. If false, we return raw input
    const antigen = this.parseHlaTypingTag(rawMolecular);
    if (!antigen) {
      return !isMostLikelyAllele ? rawMolecular : undefined;
    }
    // Return standard Molecular representation, which is the allele group only e.g. 01
    return antigen.standardAlleleGroupOnly;
  }

  /**
   * Extract Molecular Override value from the molecular_value of an antigen sub-document
   *
   * @param rawMolecular string representation of antigen antibody that may or may not have allele-specific information
   */
  private parseMolecularOverrideValue(rawMolecular?: string|null): string|undefined {
    // If there is no data, molecular override is blank
    if (!rawMolecular) {
      return undefined;
    }
    // If we cannot parse, then we return raw input
    const antigen = this.parseHlaTypingTag(rawMolecular);
    if (!antigen) {
      return rawMolecular;
    }
    // Return standard Molecular Override representation, which is the raw allele-specific format e.g. 01:XX
    return antigen.standardAlleleSpecific;
  }

  /**
   * Extract just the allele from an antibody string that may have allele-specific information.
   *
   * E.g. returns "01:02" if the input is "01:02", but undefined for "01" on its own
   *
   * @param rawMolecular string representation of an antibody that may or may not have allele-specific information
   */
  private parseMostLikelyAllele(rawMolecular?: string|null, isMostLikelyAllele?: boolean): string|undefined {
    // If there is no data or the most_likely_allele boolean is false, then most likely allele is blank
    if (!rawMolecular || !isMostLikelyAllele) {
      return undefined;
    }
    // If we cannot parse, then return raw input
    const antigen = this.parseHlaTypingTag(rawMolecular);
    if (!antigen) {
      return rawMolecular;
    }
    // Return standard Most Likely Allele representation or raw input if unexpected standardization error
    return antigen.standardMostLikelyAllele || rawMolecular;
  }

  private parseSerologicalValues(serologicalValues?: HlaSerologicalValue[]|null): string[] {
    if (serologicalValues == undefined)  {
      return [];
    }
    const mappedValues = serologicalValues.map((serologic: HlaSerologicalValue): string => {
      return `${serologic.value || ''}${serologic.tbc ? '-tbc' : ''}`;
    });
    return mappedValues;
  }

  /**
   * Generates a patch representing changes to the recipient's HLA Typing.
   *
   * @return {LabHLATyping} patch or post request payload
   */
  private extractHlaTypingPatch(hlaTyping: HlaTypingForm): LabHLATypingRecipient {
    const result: LabHLATypingRecipient = {
      lab_id: this.editState.hla_lab ? { $oid: this.editState.hla_lab } : null,
      comments: this.editState.comments,
      typing_incomplete: this.editState.typing_incomplete,
      antigens: this.extractHlaTypingAntigensPatch(this.editState.hla_typing_details),
      epitopes: this.extractHlaTypingEpitopesPatch(this.editState.hla_typing_details),
    };

    // Only include the 'test_date' property if Submit Date Editable is enabled (ATQ-694)
    if (this.isHlaTypingSubmitDateEditable) {
      result.test_date = this.sanitizeDateApi(this.editState.submitDate || undefined) || null;
    }
    return result;
  }

  /**
   * Extracts information about a recipient's HLA Typing Details for generating a patch.
   *
   * The details are contained within a keyed object for the form, but the API expects an array of detail elements.
   *
   * @param hlaTypingDetails HLA Typing Details as an object from the current form edit state
   * @return {LabHlaTypingAntigen[]} HLA Typing Detail for patch extraction
   */
  private extractHlaTypingAntigensPatch(hlaTypingDetails?: HlaTypingDetailsForm): LabHlaTypingAntigen[] {
    if (!hlaTypingDetails) {
      return [];
    }
    // Iterate through all of the HLA Typing loci
    const details: LabHlaTypingAntigen[] = [];
    DETAILS_TABLE_LOCUS_SEQUENCE.forEach((loci: HlaTypingLoci) => {
      const locusDetails = hlaTypingDetails[loci.molecular_locus];
      if (!!locusDetails) {
        const detail1 = this.extractAntigenPatch(locusDetails[1], loci, 1);
        const detail2 = this.extractAntigenPatch(locusDetails[2], loci, 2);
        if (!!detail1) {
          details.push(detail1);
        }
        if (!!detail2) {
          details.push(detail2);
        }
      } else {
        console.warn('Could not find HLA Typing details for locus', loci.molecular_locus);
      }
    });
    return details;
  }

  private extractHlaTypingEpitopesPatch(hlaTypingDetails?: HlaTypingDetailsForm): LabHlaTypingEpitope[] {
    if (!hlaTypingDetails) {
      return [];
    }
    // Iterate through possible epitopes
    const epitopes: LabHlaTypingEpitope[] = [];
    this.possibleEpitopes.forEach((epitope: HlaEpitope) => {
      const epitopeValue = epitope.code || UNKNOWN_LOCUS;
      const epitopeSequences = hlaTypingDetails[epitopeValue] || {};
      const epitopeRow: HlaTypingDetailRow = epitopeSequences[1] || {};
      const dictionaryResult = this.extractEpitopeResult(epitopeRow.epitopeDictionaryResult || null);
      const overrideResult = this.extractEpitopeResult(epitopeRow.epitopeOverrideResult || null);
      const overrideDate = epitopeRow.epitopeOverride ? this.currentDateTimeApi() : null;
      const epitopePatch: LabHlaTypingEpitope = {
        epitope_value: epitopeValue,
        present_dictionary: dictionaryResult,
        present: overrideDate ? overrideResult : dictionaryResult,
        override_date: overrideDate,
        override_comment: epitopeRow.comments,
        // Current epitopes are all class 1
        class_code: 1,
      };
      epitopes.push(epitopePatch);
    });
    return epitopes;
  }

  private extractSerologicValues(serologicTags?: string[]): HlaSerologicalValue[]|null {
    if (serologicTags == undefined || serologicTags.length === 0) {
      return null;
    }
    const sanitizedForApi: HlaSerologicalValue[] = serologicTags.map((serologic: string): HlaSerologicalValue => {
      const antigen: HlaTypingTag|undefined = this.parseHlaTypingTag(serologic);
      if (antigen == undefined) {
        return {
          value: serologic,
          tbc: false,
        };
      }
      const mapped = {
        value: antigen.standardWithoutTbc || serologic,
        tbc: antigen.tbc || false,
      };
      return mapped;
    });
    return sanitizedForApi;
  }

  /**
   * Generate API format object for an individual HLA Typing detail row
   *
   * @param detailRow HLA Typing row from edit state form
   * @returns {LabHlaTypingAntigen} HLA Typing detail in API format, or undefined if no meaningful data provided
   */
  private extractAntigenPatch(detailRow: HlaTypingDetailRow, locus: HlaTypingLoci, sequenceNumber: number): LabHlaTypingAntigen|undefined {
    // Extract data from provided form detail row
    const serologicalValues = this.extractSerologicValues(detailRow.serologic_value);
    const rawMolecularValue = detailRow.molecular_value && detailRow.molecular_value.length > 0 ? detailRow.molecular_value[0] : null;
    const rawMostLikelyAllele = detailRow.most_likely_allele && detailRow.most_likely_allele.length > 0 ? detailRow.most_likely_allele[0] : null;
    const rawMolecularOverride = detailRow.molecular_override && detailRow.molecular_override.length > 0 ? detailRow.molecular_override[0] : null;
    const typingTechniqueCode = detailRow.testing_technique;
    const comments = detailRow.comments;
    // Determine parameter values based on API format
    const hasMostLikelyAllele = !!rawMostLikelyAllele;
    const molecularOrMostLikelyAllele = hasMostLikelyAllele ? rawMostLikelyAllele : rawMolecularValue;
    const molecularAntigen: HlaTypingTag|null = molecularOrMostLikelyAllele ? this.parseHlaTypingTag(molecularOrMostLikelyAllele) || null : null;
    const sanitizedMolecular = molecularAntigen ? molecularAntigen.standardAlleleSpecific : null;
    const result: LabHlaTypingAntigen = {
      _id: detailRow._id,
      sequence_number: sequenceNumber ,
      typing_technique_code: typingTechniqueCode || null,
      comments: comments || null,
      molecular_locus: locus.molecular_locus,
      molecular_value: sanitizedMolecular || molecularOrMostLikelyAllele || rawMolecularOverride,
      serological_locus: locus.serologic_locus,
      serological_values: serologicalValues || [],
      most_likely_allele: hasMostLikelyAllele || false,
      override_mapping: detailRow.override_mapping || false,
    };

    // If we are using Molecular Override column, it dictates what is saved
    if (this.isHlaTypingMolecularOverrideEnabled) {
      result.molecular_value = rawMolecularOverride;
    }
    return result;
  }

  get serologicLabel(): (molecularLocus: string) => string|null {
    return (molecularLocus: string): string|null => {
      const locusDetails = DETAILS_TABLE_LOCUS_SEQUENCE.find((locus: HlaTypingLoci) => {
        return locus.molecular_locus === molecularLocus;
      });
      const locusLabel = locusDetails ? locusDetails.serologic_label : undefined;
      const result = locusLabel || null;
      return result;
    };
  }

  get epitopeOptions(): { code: any; value: string }[] {
    return EPITOPE_OPTIONS || [];
  }

  private onOverrideChanged(rowChanged: any, newOverrideValue: any): void {
    // If unchecking override checkbox, clear corresponding override result
    if (!newOverrideValue) {
      const locus = rowChanged.molecular_locus;
      const sequenceNumber = rowChanged.sequence_number;
      const details = this.editState.hla_typing_details;
      if (!details) {
        return;
      }
      Vue.set(details[locus][sequenceNumber], 'epitopeOverrideResult', null);
    }
  }

  // API response keys on the left, id for our UI on the right
  public idLookup(): IdLookup {
    // Constant mapping
    const result: IdLookup = {
      // Recipient HLA Typing Metadata
      'lab_hla_typing_recipient.lab_id'                         : 'typing-entries-hla_lab',
      'lab_hla_typing_recipient.comments'                       : 'typing-entries-comments',
      'lab_hla_typing_recipient.antigens.typing_technique_code' : 'typing-entries-technique_all_rows',
      'lab_hla_typing_recipient.test_date'                      : 'typing-entries-submit-date',
      // Donor HLA Typing Metadata
      'lab_hla_typing_donor.lab_id'                             : 'typing-entries-hla_lab',
      'lab_hla_typing_donor.comments'                           : 'typing-entries-comments',
      'lab_hla_typing_donor.antigens.typing_technique_code'     : 'typing-entries-technique_all_rows',
      'lab_hla_typing_donor.test_date'                          : 'typing-entries-submit-date',
    };
    // Dynamic mapping
    // Antigen details
    const details = this.editState.hla_typing_details || {};
    DETAILS_TABLE_LOCUS_SEQUENCE.forEach((loci: HlaTypingLoci) => {
      [1, 2].forEach((sequenceNumber: number) => {
        const locus = loci.molecular_locus || 'A';
        const apiRowId = `[\"${locus}\", ${sequenceNumber}]`;
        const uiRowId = `${locus}-${sequenceNumber}`;
        const currentDetails = details[locus] || {};
        const sequence = currentDetails[sequenceNumber] || {};
        const mostLikelyAllele = sequence.most_likely_allele || [];
        const isMostLikelyAllele = mostLikelyAllele.length > 0;
        const serologicValues = sequence.serologic_value || [];
        // Recipient HLA Typing Details
        result[`lab_hla_typing_recipient.antigens[${apiRowId}].sequence_number`] = `typing-details-${uiRowId}-molecular`;
        result[`lab_hla_typing_recipient.antigens[${apiRowId}].molecular_locus`] = `typing-details-${uiRowId}-molecular`;
        result[`lab_hla_typing_recipient.antigens[${apiRowId}].serological_locus`] = `typing-details-${uiRowId}-serologic`;
        result[`lab_hla_typing_recipient.antigens[${apiRowId}].override_mapping`] = `typing-details-${uiRowId}-molecular`;
        result[`lab_hla_typing_recipient.antigens[${apiRowId}].typing_technique_code`] = `typing-details-${uiRowId}-testing_technique`;
        result[`lab_hla_typing_recipient.antigens[${apiRowId}].typing_technique`] = `typing-details-${uiRowId}-testing_technique`;
        result[`lab_hla_typing_recipient.antigens[${apiRowId}].most_likely_allele`] = `typing-details-${uiRowId}-molecular`;
        result[`lab_hla_typing_recipient.antigens[${apiRowId}].comments`] = `typing-details-${uiRowId}-comments`;
        // Recipient HLA Typing Molecular, Most Likely Allele, and Molecular Override values
        result[`lab_hla_typing_recipient.antigens[${apiRowId}].molecular_value`] = [
          isMostLikelyAllele ? `typing-details-${uiRowId}-most_likely_allele` : `typing-details-${uiRowId}-molecular`,
          `typing-details-${uiRowId}-molecular_override`,
        ];
        // Recipient HLA Typing Serological Values
        result[`lab_hla_typing_recipient.antigens[${apiRowId}].serological_values`] = `typing-details-${uiRowId}-serologic`;
        serologicValues.forEach((serologic: string) => {
          result[`lab_hla_typing_recipient.antigens[${apiRowId}].serological_values[${serologic}].value`] = `typing-details-${uiRowId}-serologic`;
          result[`lab_hla_typing_recipient.antigens[${apiRowId}].serological_values[${serologic}].tbc`] = `typing-details-${uiRowId}-serologic`;
        });
        // Donor Typing Details
        result[`lab_hla_typing_donor.antigens[${apiRowId}].sequence_number`] = `typing-details-${uiRowId}-molecular`;
        result[`lab_hla_typing_donor.antigens[${apiRowId}].molecular_locus`] = `typing-details-${uiRowId}-molecular`;
        result[`lab_hla_typing_donor.antigens[${apiRowId}].serological_locus`] = `typing-details-${uiRowId}-serologic`;
        result[`lab_hla_typing_donor.antigens[${apiRowId}].override_mapping`] = `typing-details-${uiRowId}-molecular`;
        result[`lab_hla_typing_donor.antigens[${apiRowId}].typing_technique_code`] = `typing-details-${uiRowId}-testing_technique`;
        result[`lab_hla_typing_donor.antigens[${apiRowId}].typing_technique`] = `typing-details-${uiRowId}-testing_technique`;
        result[`lab_hla_typing_donor.antigens[${apiRowId}].most_likely_allele`] = `typing-details-${uiRowId}-molecular`;
        result[`lab_hla_typing_donor.antigens[${apiRowId}].comments`] = `typing-details-${uiRowId}-comments`;
        // Donor HLA Typing Molecular, Most Likely Allele, and Molecular Override values
        result[`lab_hla_typing_donor.antigens[${apiRowId}].molecular_value`] = [
          isMostLikelyAllele ? `typing-details-${uiRowId}-most_likely_allele` : `typing-details-${uiRowId}-molecular`,
          `typing-details-${uiRowId}-molecular_override`,
        ];
        // Donor HLA Typing Serological Values
        result[`lab_hla_typing_donor.antigens[${apiRowId}].serological_values`] = `typing-details-${uiRowId}-serologic`;
        serologicValues.forEach((serologic: string) => {
          result[`lab_hla_typing_donor.antigens[${apiRowId}].serological_values[${serologic}].value`] = `typing-details-${uiRowId}-serologic`;
          result[`lab_hla_typing_donor.antigens[${apiRowId}].serological_values[${serologic}].tbc`] = `typing-details-${uiRowId}-serologic`;
        });
      });
    });
    // Epitope details
    this.possibleEpitopes.forEach((epitope: HlaEpitope) => {
      const code = epitope.code;
      // Recipient
      result[`lab_hla_typing_recipient.epitopes[${code}].epitope_value`]      = `typing-details-${code}-epitope-dictionary-result`;
      result[`lab_hla_typing_recipient.epitopes[${code}].present_dictionary`] = `typing-details-${code}-epitope-dictionary-result`;
      result[`lab_hla_typing_recipient.epitopes[${code}].override_date`]      = `typing-details-${code}-epitope-override-checkbox`;
      result[`lab_hla_typing_recipient.epitopes[${code}].present`]            = `typing-details-${code}-epitope-override-result`;
      result[`lab_hla_typing_recipient.epitopes[${code}].override_comment`]   = `typing-details-${code}-epitope-comments`;
      // Donor
      result[`lab_hla_typing_donor.epitopes[${code}].epitope_value`]      = `typing-details-${code}-epitope-dictionary-result`;
      result[`lab_hla_typing_donor.epitopes[${code}].present_dictionary`] = `typing-details-${code}-epitope-dictionary-result`;
      result[`lab_hla_typing_donor.epitopes[${code}].override_date`]      = `typing-details-${code}-epitope-override-checkbox`;
      result[`lab_hla_typing_donor.epitopes[${code}].present`]            = `typing-details-${code}-epitope-override-result`;
      result[`lab_hla_typing_donor.epitopes[${code}].override_comment`]   = `typing-details-${code}-epitope-comments`;
    });
    return result;
  }
}
