
import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { TableConfig } from '@/types';
import { Getter, State } from 'vuex-class';
import { ObjectId, NumericCodeValue, GenericCodeValue } from '@/store/types';
import { Organ, RecipientStatus, OrganCauseOfFailure, OrganCodeValue, YesNoUnknown, SourceOfInformation, CauseOfDeathCategoryCodes, RecipientStatusPostTransplant } from '@/store/lookups/types';
import { DeathRecipient, Recipient } from '@/store/recipients/types';
import DateInput from '@/components/shared/DateInput.vue';
import BooleanRadioInput from '@/components/shared/BooleanRadioInput.vue';
import TextInput from '@/components/shared/TextInput.vue';
import NumberInput from '@/components/shared/NumberInput.vue';
import SubSection from '@/components/shared/SubSection.vue';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { IdLookup } from '@/store/validations/types';
import SelectInput from '@/components/shared/SelectInput.vue';
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import SelectOtherInput from '@/components/shared/SelectOtherInput.vue';
import TextAreaInput from '@/components/shared/TextAreaInput.vue';
import { SaveableSection, SaveProvider, SaveResult } from '@/types';
import { RecipientJourney, PostTransplantFollowUp, KidneyPostTransplantFollowUp, RecipientJourneyTransferStatus } from '@/store/recipientJourney/types';
import { PostTransplantSectionPageState } from '@/components/organs/shared/_PostTransplantSection.vue';
import { EP } from "@/api-endpoints";
import { SystemModules } from '@/store/features/types';

export interface FollowUpPageState {
  dateOfEntry?: string|null;
  recipientStatus?: number;
  sourceOfInformation?: number;
  sourceOfInformationOther?: string;
  sourceOfInformationDate?: string;
  comments?: string;
  failureDate?: string;
  failureCause?: number;
  failureCauseOther?: string;
  lostToFollowUpReason?: string;
  serumCreatinine?: number;
  serumCreatinineDate?: string;
  serumCreatinineUnknown?: boolean;
  returnedChronicDialysis?: string;
  returnedChronicDialysisDate?: string;
  causeOfDeathCategory?: string|null;
  causeOfDeathType?: number|null;
  causeOfDeathTypeOther?: string|null;
  deathDate?: string|null;
  estimated?: boolean|null;
  related_to_waittime?: boolean|null;
  graftFunctionAtDeath?: string;

  // Afflo Prototype
  followUpType: string|null;
  checklist: {
    type: string|null;
    [key: string]: any;
  };
}

// Afflo Prototype
export interface FollowUpTypeOption extends GenericCodeValue {
  hasChecklist: boolean;
  checklistReference?: string;
}

@Component({
  components: {
    TextInput,
    DateInput,
    NumberInput,
    SubSection,
    SelectInput,
    CheckboxInput,
    SelectOtherInput,
    TextAreaInput,
    BooleanRadioInput,
  }
})

export default class PostTransplantFollowUpSection extends mixins(DateUtilsMixin) implements SaveableSection {
  @State(state => state.recipients.selectedRecipient) recipient!: Recipient;
  @State(state => state.recipients.selectedDeath) private death!: DeathRecipient;
  @State(state => state.journeyState.selectedJourney) journey!: RecipientJourney;
  @State(state => state.pageState.currentPage.postTransplantSection) editState!: PostTransplantSectionPageState;
  @State(state => state.journeyState.postTransplantFollowUps) private postTransplantFollowUps!: PostTransplantFollowUp[];
  @State(state => state.journeyState.selectedPostTransplantFollowUp) selectedPostTransplantFollowUp!: PostTransplantFollowUp;
  @State(state => state.lookups.yes_no_unknown) private yesNoUnknownLookup!: GenericCodeValue[];
  @State(state => state.lookups.recipient_status_post_transplant) private recipientStatusLookup!: NumericCodeValue[];
  @State(state => state.lookups.source_of_information) private sourceOfInformationLookup!: NumericCodeValue[];
  @State(state => state.lookups.lost_to_followup_reason) private lostToFollowUpReasonLookup!: NumericCodeValue[];
  @State(state => state.lookups.cause_of_death_category) private causeOfDeathCategoryLookup!: any[];

  @Getter('causeOfFailureValue', { namespace: 'lookups' }) organFailure!: (organCode: number | string | undefined, organFailureCode: number | null | undefined) => OrganCauseOfFailure[] | undefined;
  @Getter('canSaveGetter', { namespace: 'validations' }) private canSaveGetter!: (newRecord: boolean) => boolean;
  @Getter("causeOfDeathTypeLookup", { namespace: "lookups" }) causeOfDeathType!: (deathCode: string | undefined) => NumericCodeValue[] | undefined;
  @Getter('clientId', { namespace: 'recipients' }) clientId!: string|undefined;
  @Getter('journeyId', { namespace: 'journeyState', }) journeyId!: string|undefined;
  @Getter('isLastEntry', { namespace: 'utilities' }) private isLastEntry!: (id: ObjectId, entries: any[]) => boolean;
  @Getter('checkAllowed', { namespace: 'users' }) private checkAllowed!: (url: string, method?: string) => boolean;
  @Getter('mostRecentFollowUpTransfer', { namespace: 'journeyState'}) mostRecentFollowUpTransfer!: RecipientJourneyTransferStatus|null;
  @Getter('getUserHospitalIds', { namespace: 'users' }) getUserHospitalIds!: string[];
  @Getter("moduleEnabled", { namespace: "features" }) private moduleEnabled!: (module: string) => boolean;

  // Afflo Prototype
  @Getter('optionsFor', { namespace: 'lookups' }) optionsFor!: (items: string[]) => GenericCodeValue[];

  @Prop({ default: false }) newJourney!: boolean

  /**
   * Return true if we can edit the selected measurement
   *
   * @returns {boolean} true if we can edit
   */
  get canEdit(): boolean{
    if (this.newJourney) {
      return false;
    }

    if (!!this.selectedPostTransplantFollowUp && !!this.postTransplantFollowUps) {
      if (this.selectedPostTransplantFollowUp._id) {
        return this.isLastEntry(this.selectedPostTransplantFollowUp._id, this.postTransplantFollowUps);
      }
    }
    return true;
  }

  // Is the recipient death related to waittime field enabled?
  get isRecipientRelatedToWaittimeEnabled(): boolean {
    return this.moduleEnabled(SystemModules.RECIPIENT_DEATH_RELATED_TO_WAITTIME);
  }

  /**
   * Return true if we can save a follow-ups
   *
   * @returns {boolean} true if we can edit
   */
  get canSave():boolean {
    const checkAllowed =  this.checkAllowed(EP.recipients.journeys.post_transplant.follow_ups.new_validations, 'GET');

    if(!this.mostRecentFollowUpTransfer) return checkAllowed;

    const hospital = this.mostRecentFollowUpTransfer?.hospital_id?.$oid;
     // check user belongs to selected transplant program
    const userBelongsToHospital = this.getUserHospitalIds.find((hospitalId) => {
      return hospitalId == hospital ? true :false;
    });
    if(!userBelongsToHospital) return false && checkAllowed;
    return checkAllowed;
  }

  get ifShowSourceOfInformation(): any{
    if (!this.editState.followUp || !this.editState.followUp.recipientStatus) {
      return false;
    }
    return this.editState.followUp.recipientStatus.toString() === "1" || this.editState.followUp.recipientStatus.toString() === "2";
  }


  /**
   * Used to show fields related to recipient status of alive with functional graft
   *
   * @returns {boolean} true if recipient status is set to alive with functional graft
   */
  get showAliveWithFunctionalGraft(): boolean {
    if (!this.editState.followUp) {
      return false;
    }
    return this.editState.followUp.recipientStatus == RecipientStatus.AliveWithFunctionalGraft;
  }

  /**
   * Used to show fields related to recipient status of graft failure
   *
   * @returns {boolean} true if recipient status is set to graft failure
   */
  get showFailure(): boolean {
    if (!this.editState.followUp) {
      return false;
    }
    return this.editState.followUp.recipientStatus == RecipientStatus.GraftFailure;
  }

  /**
   * Used to show fields related to recipient status of lost to follow up
   *
   * @returns {boolean} true if recipient status is set to lost to follow up
   */
  get showLostToFollowUp(): boolean {

    if (!this.editState.followUp) {
      return false;

    }
    return this.editState.followUp.recipientStatus == RecipientStatus.LostToFollowUp;
  }

   /**
   * Used to show fields related to recipient status of recipient death
   *
   * @returns {boolean} true if recipient status is set to recipient death
   */
  get showRecipientDeath(): boolean {
    if (!this.editState.followUp) {
      return false;
    }
    return this.editState.followUp.recipientStatus == RecipientStatus.RecipientDeath;
  }

  /**
   * Used to enable date returned to chronic dialysis field when returned to chronic dialysis is set to yes
   *
   * @returns {boolean} true if returned to chronic dialysis is set to yes
   */
  get enableReturnedToChronicDialysisDate(): boolean {
    if (!this.editState.followUp) {
      return false;
    }
    return this.editState.followUp.returnedChronicDialysis === YesNoUnknown.Yes;
  }

  /**
   * Get a string representation the organ_code
   *
   * @returns {string} organ_code as a string
   */
  get organCode(): string {
    if (this.newJourney) {
      return this.$route.params.organ_code.toString();
    }
    return this.journey.organ_code ? this.journey.organ_code.toString() : '';
  }

  /**
   * Check if current journey organ is kidney
   *
   * @returns {boolean} returns true if the organ code is kidney
   */
  get isKidney(): boolean {
    return parseInt(this.organCode) == OrganCodeValue.Kidney;
  }

  /**
   * Returns an array of options for Secondary Specification
   *
   * Fetches the secondary specification subtable from the appropriate organ lookup table
   *
   * @returns {OrganSpecification[]} organ-specific options for secondary specification
   */
  get organCauseOfFailureLookup(): OrganCauseOfFailure[] {
    if (!this.organCode) {
      return [];
    }
    // Fetch appropriate options
    return this.organFailure(this.organCode, undefined) || [];
  }

  /**
   * Returns an array of options for cause of death type
   *
   * @returns {NumericCodeValue[]} cause of death type lookups
   */
  get causeOfDeathTypeLookup(): NumericCodeValue[] {
    const deathCode = this.editState.followUp?.causeOfDeathCategory;
    if (!deathCode) {
      return [];
    }
    // Fetch appropriate options
    return this.causeOfDeathType(deathCode) || [];
  }

  /**
   * Returns an array of options for the recipient status lookup (filtered if the journey is completed)
   *
   * @returns {NumericCodeValue[]} recipient status lookup
   */
  get recipientStatusLookupFiltered(): NumericCodeValue[] {
    const recipientStatusLookup = this.recipientStatusLookup || [];
    if (this.newJourney) return this.recipientStatusLookup;
    // Return a filtered lookup if the journey is completed
    return this.journey.completed ? recipientStatusLookup.filter((option: any) => {
      return option.deceased_recipient;
    }) : recipientStatusLookup;
  }


  // Checks when to disable the Cause of Death Type field
  get causeOfDeathTypeDisabled(): boolean {
    if (!this.editState.followUp?.causeOfDeathCategory || this.editState.followUp?.causeOfDeathCategory == '' || this.showCauseOfDeathTypeOther) {
      Vue.set(this.editState, 'causeOfDeathType', null);
      return true;
    } else {
      return false;
    }
  }

  // Changes the undefined text to Unknown when cause of death category is unknown
  get changeDeathTypeUndefinedText(): string {
   if (this.editState.followUp?.causeOfDeathCategory == CauseOfDeathCategoryCodes.Unknown) {
     return 'UNKNOWN';
   } else {
     return this.$t('select').toString();
   }
  }

  // Checks when to show the Cause of Death Type Other field
  get showCauseOfDeathTypeOther(): boolean {
    const deathCode = this.editState.followUp?.causeOfDeathCategory;

    if (!deathCode) {
      return false;
    }

    if (!this.causeOfDeathCategoryLookup) {
      return false;
    }

    const deathCategory = this.causeOfDeathCategoryLookup.find((deathCat: any) => {
      return deathCode == deathCat.code;
    });

    if (!deathCategory) {
      return false;
    }

    return deathCategory!.other_selected || false;
  }

  //Disable recipient status on update
  get isDisabled():boolean {
    const recipient_status_code = this.selectedPostTransplantFollowUp?.recipient_status_code;
    return recipient_status_code!=null ? true : false;
  }

   /**
   * Gets a string used to populate the confirmation alert dialog when saving recipient death
   *
   * @returns {string} text for confirmation property of card section
  */
  get confirmationText(): string|undefined {
    if (this.recipient?.death?.cause_category_code) {
      // no dialog box if recipient is already dead
      return undefined;
    } else {
      if (this.editState && this.editState.followUp?.causeOfDeathCategory && this.editState.followUp.deathDate) {
        const death_date = this.correctTimeOffset(this.editState.followUp.deathDate);
        // only show dialog box when certain fields have been filled out
        return this.$t('confirmation', { death_date: this.parseDisplayDateUi(death_date) }).toString();
      }
    }
  }


  // TECH DEBT: Remove the "!" after followUp from the clear methods below and add the following line of code above
  // if (!this.editState.followUp) return;

  // clear cause of failure other when cause of failure changes
  public clearFailureCauseOther(): void {
    Vue.set(this.editState.followUp!, 'failureCauseOther', null);
  }

  // clear source of information other when source of information changes
  public clearSourceOfInformationOther(): void {
    Vue.set(this.editState.followUp!, 'sourceOfInformationOther', null);
  }

  // clear date returned to chronic dialysis when returned to chronic dialysis changes
  public clearDateReturnedToChronicDialysis(): void {
    Vue.set(this.editState.followUp!, 'returnedChronicDialysisDate', undefined);
  }

  // clear serum creatinine & date of serum creatinine when serum creatinine unknown changes
  public clearSerumCreatinine(): void {
    Vue.set(this.editState.followUp!, 'serumCreatinine', undefined);
    Vue.set(this.editState.followUp!, 'serumCreatinineDate', undefined);
  }

  /**
   * Gets text representation of Recipient Status
   *
   * @param recipientStatusCode number code from lookups representing the Recipient Status
   * @returns {string|undefined} Recipient Status as text
   */
  private recipientStatusValue(recipientStatusCode?: number): string|undefined {
    const lookupTable = this.recipientStatusLookup;
    if (!lookupTable) {
      return undefined;
    }
    const statusValue = lookupTable.find((status: NumericCodeValue) => {
      return status.code == recipientStatusCode;
    });
    if (!statusValue) {
      return undefined;
    }
    return statusValue.value;
  }

  // clears the cause of death type and cause of death type other fields when a change is made to cause of death category
  private clearCauseOfDeathType(): void {
    Vue.set(this.editState, 'causeOfDeathType', null);
    Vue.set(this.editState, 'causeOfDeathTypeOther', null);
  }

  /**
 * Gets table data for recipient liver bridging procedures
 *
 * @returns {BridgingRow[]} bridging table data
 */
  get followUpRows(): PostTransplantFollowUp[] {
    if (!this.postTransplantFollowUps) {
      return [];
    }
    return this.postTransplantFollowUps.map((followUp: PostTransplantFollowUp) => {
      return {
        _id: followUp._id,
        dateOfEntry: this.parseDisplayDateUi(followUp.date_of_entry) || '-',
        recipientStatus: this.recipientStatusValue(followUp.recipient_status_code) || '-',
        failureDate: this.parseDisplayDateUi(followUp.graft_failure_date) || '-',
      };
    });
  }

  /**
   * Gets table configuration for Consultation Details.
   *
   * @return {TableConfig} Consultation Details table configuration
   */
  get followUpTableConfig(): TableConfig {
    return {
      data: this.followUpRows,
      columns: [
        { label: this.$t('date_of_entry'),    field: 'dateOfEntry',     width: '33.33%' },
        { label: this.$t('recipient_status'), field: 'recipientStatus', width: '33.33%' },
        { label: this.$t('failure_date'),     field: 'failureDate',     width: '33.33%' }
      ],
      empty: this.$t('use_form_below').toString(),
      createButton: this.canEdit,
      createText: this.$t('create_follow_up').toString(),
      pagination: true
    };
  }


  /**
   * Clears all save notifications shown by the form.
   *
   * Gets the Save Provider associated with the form, and requests that it reset its own Save Toolbar
   */
  public resetSaveToolbar(): void {
    const saveProvider = this.$refs.saveFollowUp as unknown as SaveProvider;
    saveProvider.resetSaveToolbar();
  }

  public savePatch(): void {
    // Refer to the save provider that handles this form area
    const saveProvider = this.$refs.saveFollowUp as unknown as SaveProvider;
    // Report to parent that saving has began
    this.$emit('save', 'followUp');
    // Generate payload based on current edit state
    const followUpPatch = this.extractPatch();

    // Setup saving payload
    const payload = {
      journeyId: this.journey._id ? this.journey._id.$oid : undefined,
      recipientId: this.recipient.client_id,
      followUp: followUpPatch,
      id: !!this.selectedPostTransplantFollowUp ? this.selectedPostTransplantFollowUp._id?.$oid : undefined,
    };

    this.$store.dispatch('journeyState/savePostTransplantFollowUp', payload).then((success: SaveResult) => {
      // Reload follow ups list
      this.$store.dispatch('journeyState/loadPostTransplantFollowUps', { journeyId: this.journeyId, recipientId: this.clientId });
      // Reload the recipient and journey data
      this.$store.dispatch('recipients/get', this.clientId).then(() => {
        this.$store.dispatch('journeyState/getJourney', this.journeyId);
      });
      // Clear form state
      this.createPostTransplantFollowUp();
      // Show success notification
      saveProvider.registerSaveResult(success);
    }).catch((error: SaveResult) => {
      // Emit event to handle errors
      this.$emit('handleErrors', error);
      // Show error notification
      saveProvider.registerSaveResult(error);
    });
  }

  /**
   * Gets a patch object representing form edit state changes for this form
   *
   * Delegates the logic of building the patch to a local private method
   *
   * @returns {KidneyPostTransplantFollowUp|PostTransplantFollowUp} patch object containing field changes
   */
  public extractPatch(): KidneyPostTransplantFollowUp|PostTransplantFollowUp {
    if (!this.editState || !this.editState.followUp) {
      return {};
    }
    const followUp = this.editState.followUp;

    let result = {
      date_of_entry: this.sanitizeDateApi(followUp.dateOfEntry),
      recipient_status_code: followUp.recipientStatus,
      source_of_followup: followUp.sourceOfInformation,
      source_of_followup_other: followUp.sourceOfInformationOther,
      follow_up_date: followUp.sourceOfInformationDate ? this.sanitizeDateApi(followUp.sourceOfInformationDate) : null,
      comments: followUp.comments,
      graft_failure_date: followUp.failureDate ? this.sanitizeDateApi(followUp.failureDate) : null,
      graft_failure_cause: followUp.failureCause,
      graft_failure_cause_other: followUp.failureCauseOther,
      lost_to_followup_reason: followUp.lostToFollowUpReason,
      graft_functioning_at_death: followUp.graftFunctionAtDeath,
      death: {
        cause_category_code: followUp.causeOfDeathCategory || null,
        cause_type_code: !this.showCauseOfDeathTypeOther ? followUp.causeOfDeathType : null,
        cause_type_other: this.showCauseOfDeathTypeOther ? followUp.causeOfDeathTypeOther : null,
        death_date: this.sanitizeDateApi(followUp.deathDate),
        estimated_death_date: followUp.estimated || null,
        related_to_waittime: followUp.related_to_waittime,
      }
    };

    let kidneyDetails = {
      serum_creatinine: followUp.serumCreatinine ? followUp.serumCreatinine : null,
      date_serum_creatinine: followUp.serumCreatinineDate ? this.sanitizeDateApi(followUp.serumCreatinineDate) : null,
      serum_creatinine_unknown: followUp.serumCreatinineUnknown,
      returned_to_chronic_dialysis: followUp.recipientStatus == RecipientStatus.AliveWithFunctionalGraft ? followUp.returnedChronicDialysis : YesNoUnknown.Unknown,
      date_returned_to_chronic_dialysis: followUp.returnedChronicDialysisDate ? this.sanitizeDateApi(followUp.returnedChronicDialysisDate) : null
    };

    if (parseInt(this.organCode) == OrganCodeValue.Kidney) {
      Object.assign(result, kidneyDetails);
    }

    return result;
  }

  /**
   * Generates post transplant follow up form state based on a post transplant follow up
   *
   * @param followUp follow up document provided by API
   * @returns {FollowUpPageState} page state for post transplant follow up
   */
  public buildPostTransplantFollowUpPageState(followUp?: PostTransplantFollowUp): FollowUpPageState {
    const defaultState: FollowUpPageState  = {
      dateOfEntry: this.currentDateUi(),

      // Afflo Prototype
      followUpType: null,
      checklist: {
        type: null,
      },
    };
    const death = this.death || this.recipient.death || {};

    if (!!followUp) {
      let result: FollowUpPageState = {
        dateOfEntry: this.parseDateUi(followUp.date_of_entry),
        recipientStatus: followUp.recipient_status_code,
        sourceOfInformation: followUp.source_of_followup,
        sourceOfInformationOther: followUp.source_of_followup_other,
        sourceOfInformationDate: this.parseDateUi(followUp.follow_up_date),
        failureDate: this.parseDateUi(followUp.graft_failure_date),
        failureCause: followUp.graft_failure_cause,
        failureCauseOther: followUp.graft_failure_cause_other,
        lostToFollowUpReason: followUp.lost_to_followup_reason,
        causeOfDeathCategory: death.cause_category_code,
        causeOfDeathType: death.cause_type_code,
        causeOfDeathTypeOther: death.cause_type_other,
        deathDate: this.parseDateUi(death.death_date),
        estimated: death.estimated_death_date,
        comments: followUp.comments,
        graftFunctionAtDeath: followUp.graft_functioning_at_death,
        related_to_waittime: death.related_to_waittime,

        // Afflo Prototype
        followUpType: null,
        checklist: {
          type: null,
        },
      };

      if (parseInt(this.organCode) == OrganCodeValue.Kidney) {
        const kidneyFollowUp = followUp as unknown as KidneyPostTransplantFollowUp;

        Object.assign(result, {
          serumCreatinine: kidneyFollowUp.serum_creatinine,
          serumCreatinineDate: this.parseDateUi(kidneyFollowUp.date_serum_creatinine),
          serumCreatinineUnknown: kidneyFollowUp.serum_creatinine_unknown,
          returnedChronicDialysis: kidneyFollowUp.returned_to_chronic_dialysis,
          returnedChronicDialysisDate: this.parseDateUi(kidneyFollowUp.date_returned_to_chronic_dialysis)
        });
      }
      return result;
    }
    return defaultState;
  }


  // API response keys on the left, id for our UI on the right
  public idLookup: IdLookup = {
    // Add keys
    'date_of_entry'                     : 'journey-post-transplant-follow-up-date-of-entry',
    'recipient_status_code'             : 'journey-post-transplant-follow-up-recipient-status',
    'source_of_followup'                : 'journey-post-transplant-follow-up-source-of-information',
    'source_of_followup_other'          : 'journey-post-transplant-follow-up-source-of-information-other',
    'follow_up_date'                    : 'journey-post-transplant-follow-up-source-of-information-date',
    'comments'                          : 'journey-post-transplant-follow-up-comments',
    'graft_failure_date'                : 'journey-post-transplant-follow-up-failure-date',
    'graft_failure_cause'               : 'journey-post-transplant-follow-up-failure-cause',
    'graft_failure_cause_other'         : 'journey-post-transplant-follow-up-failure-cause-other',
    'lost_to_followup_reason'           : 'journey-post-transplant-follow-up-lost-to-follow-up-reason',

    'serum_creatinine'                  : 'journey-post-transplant-follow-up-serum-creatinine',
    'date_serum_creatinine'             : 'journey-post-transplant-follow-up-serum-creatinine-date',
    'serum_creatinine_unknown'          : 'journey-post-transplant-follow-up-serum-creatinine-unknown',
    'returned_to_chronic_dialysis'      : 'journey-post-transplant-follow-up-returned-chronic-dialysis',
    'date_returned_to_chronic_dialysis' : 'journey-post-transplant-follow-up-returned-chronic-dialysis-date',

    'cause_category_code'                    : 'journey-post-transplant-follow-up-death_category',
    'cause_type_code'                        : 'journey-post-transplant-follow-up-death_type',
    'cause_type_other'                       : 'journey-post-transplant-follow-up-death_type_other',
    'death_date'                             : 'journey-post-transplant-follow-up-death_date',
    'related_to_waittime'                    : 'journey-post-transplant-follow-up-related_to_waittime',
    'estimated_death_date'                   : 'journey-post-transplant-follow-up-estimated',
    'graft_functioning_at_death'             : 'journey-post-transplant-follow-up-graft-functioning-at-death',
  };

  /**
   * Resets editState
   *
   * @param status recipientStatus selected
   */
  private resetFollowup(status: string): void {
    const followUpType = this.editState?.followUp?.followUpType;

    Vue.set(this.editState, 'followUp', {
      causeOfDeathCategory: null,
      causeOfDeathType: null,
      causeOfDeathTypeOther: null,
      lostToFollowUpReason: null,
      deathDate: null,
      estimated: null,
      failureCause: null,
      failureCauseOther: null,
      failureDate: null,
      graftFunctionAtDeath: null,
      sourceOfInformation: null,
      sourceOfInformationOther: null,
      sourceOfInformationDate: null,
      comments: null,
      dateOfEntry: this.currentDateUi(),
      recipientStatus: status,

      // Afflo Prototype
      followUpType,
      checklist: {
        type: null,
      },
    });
  }

  // Automatically populate "Source of Information" with “Transplant Program” value but user may change value.
  private setDefaultSourceOfInformationTransplantProgram(event: any): void {
    if (this.editState.followUp) {
      this.resetFollowup(event);
      if (event == RecipientStatus.AliveWithFunctionalGraft || event == RecipientStatus.GraftFailure) {
        Vue.set(this.editState.followUp, 'sourceOfInformation', SourceOfInformation.TransplantProgram);
      } else if (event == RecipientStatus.RecipientDeath) {
        const recipientDeath = this.recipient.death;
        Vue.set(this.editState.followUp, 'causeOfDeathCategory', recipientDeath?.cause_category_code);
        Vue.set(this.editState.followUp, 'causeOfDeathType', recipientDeath?.cause_type_code);
        Vue.set(this.editState.followUp, 'causeOfDeathTypeOther', recipientDeath?.cause_type_other);
        Vue.set(this.editState.followUp, 'deathDate', recipientDeath?.death_date);
        Vue.set(this.editState.followUp, 'estimated', recipientDeath?.estimated_death_date);
      } else {
        Vue.set(this.editState.followUp, 'sourceOfInformation', undefined);
      }
    }
  }

  /**
   * Builds form edit state based on selected document
   *
   * @param event select event
   */
  private selectPostTransplantFollowUp(event: any): void {
    // Get selected ID from the table row reference in the select event
    const selectedId = event.row._id && event.row._id.$oid ? event.row._id!.$oid : undefined;
    if (!selectedId || !this.postTransplantFollowUps) {
      return;
    }
    // Find the selected source document
    const found = this.postTransplantFollowUps.find((each: PostTransplantFollowUp) => {
      return each._id && each._id.$oid === selectedId;
    });
    if (!found) {
      return;
    }
    this.$store.commit('journeyState/selectPostTransplantFollowUp', found);
    // Build form state based on selected document
    const postTransplantFollowUpForm: FollowUpPageState = this.buildPostTransplantFollowUpPageState(this.selectedPostTransplantFollowUp);
    Vue.set(this.editState, 'followUp', postTransplantFollowUpForm);
    this.resetSaveToolbar();
  }

  /**
   * Resets edit state to prepare for entering a new document
   */
  public createPostTransplantFollowUp(): void {
    // Clear stored selection
    this.$store.commit('journeyState/clearPostTransplantFollowUp');
    // Build empty form state
    const postTransplantFollowUpForm: FollowUpPageState = this.buildPostTransplantFollowUpPageState();
    Vue.set(this.editState, 'followUp', postTransplantFollowUpForm);
    this.resetSaveToolbar();
  }

  // Returns whether or not the follow up edit state is empty
  public isFollowUpEmpty(): boolean {
    if (!this.editState.followUp) {
      return true;
    }
    return (this.editState.followUp.recipientStatus == null || this.editState.followUp.recipientStatus == undefined);
  }

  // Afflo Prototype

  get followUpTypeOptions(): FollowUpTypeOption[] {
    return [
      {
        code: 'three-month-follow-up',
        value: "Three-Month Follow-Up",
        hasChecklist: true,
        checklistReference: 'threeMonth',
      },
      {
        code: 'one-year-follow-up',
        value: "One-Year Follow-Up",
        hasChecklist: true,
        checklistReference: 'oneYear',
      },
      {
        code: 'ad-hoc',
        value: "Ad hoc (no checklist)",
        hasChecklist: false,
      },
    ];
  }

  get followUpCareOptions(): GenericCodeValue[] {
    return this.optionsFor([
      "TxC",
      "Non-TxP specialty",
      "PCP",
    ]);
  }

  get reasonForLossOfContactOptions(): GenericCodeValue[] {
    return this.optionsFor([
      'Change TxC',
      'Contact info outdated',
      'Death',
      'Moved',
      'Unresponsive',
    ]);
  }

  get patientStatusOptions(): GenericCodeValue[] {
    return this.optionsFor([
      'Living',
      'Re-Transplant',
      'Deceased',
    ]);
  }

  get diseaseRecurranceOptions(): GenericCodeValue[] {
    return this.optionsFor([
      'No recurrence',
      'Suspected recurrence',
      'Biopsy confirmed recurrence',
      'Unknown',
    ]);
  }

  get graftStatusOptions(): GenericCodeValue[] {
    return this.optionsFor([
      'Failed',
      'Functioning',
    ]);
  }

  get dialysisChronicTemporaryOptions(): GenericCodeValue[] {
    return this.optionsFor([
      'Chronic',
      'Temporary',
    ]);
  }

  get antiRejectionOptions(): GenericCodeValue[] {
    return this.optionsFor([
      'Yes-Treated w/ Anti Rejection',
      'Yes-Not treated w/ Anti Rejection',
      'No',
      'Unknown',
    ]);
  }

  get dsaAtTimeOfRejectionOptions(): GenericCodeValue[] {
    return this.optionsFor([
      'Absent',
      'Present (de novo)',
      'Present (Pre-existing)',
      'Test not done',
      'Undetermined',
    ]);
  }

  get infectionTypeOptions(): GenericCodeValue[] {
    return this.optionsFor([
      'Bacterial',
      'Fungal',
      'Viral',
      'Unknown',
    ]);
  }

  get infectionSourceOptions(): GenericCodeValue[] {
    return this.optionsFor([
      'Reactivation of Latent Infection',
      'Donor Derived Infection',
      'Newly Acquired',
      'Unknown',
    ]);
  }

  get malignancyTypeOptions(): GenericCodeValue[] {
    return this.optionsFor([
      'Bladder CA',
      'Breast CA',
      'Cervical CA',
      'CNS Neoplasm',
      'Colorectal CA',
      'Gastric CA',
      'Genitourinary CA',
      'Esophageal CA',
      'Hepatocellular CA',
      'Leukemia',
      'Liver CA',
      'Multiple Myeloma',
      'Oropharynx CA',
      'Prostate CA',
      'Renal Cell CA',
      'Sarcoma',
      'Melanoma',
      'Skin Non-Melanoma',
      'Testis CA',
    ]);
  }

  get selectedFollowUpType(): string|null {
    if (!this.editState?.followUp) return null;

    return this.editState.followUp.followUpType;
  }

  get selectedFollowUpTypeLookup(): FollowUpTypeOption|null {
    if (!this.selectedFollowUpType) return null;

    const matching = this.followUpTypeOptions.find((option: FollowUpTypeOption) => {
      return option.code == this.selectedFollowUpType;
    });
    if (!matching) return null;

    return matching;
  }

  get selectedFollowUpTypeHasChecklist(): boolean {
    if (!this.selectedFollowUpTypeLookup) return false;

    return this.selectedFollowUpTypeLookup.hasChecklist || false;
  }

  get selectedFollowUpTypeChecklistReference(): string|null {
    if (!this.selectedFollowUpTypeLookup) return null;

    return this.selectedFollowUpTypeLookup.checklistReference || null;
  }

  private handleFollowUpTypeChanged(): void {
    if (!this.editState.followUp?.checklist) return;

    Vue.set(this.editState.followUp, 'checklist', {
      type: this.selectedFollowUpTypeChecklistReference,
    });
  }



}

