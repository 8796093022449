
  import {
    Getter,
    State,
  } from 'vuex-class';
  import {
    mixins
  } from "vue-class-component";
  import {
    Component,
    Vue,
    Prop
  } from 'vue-property-decorator';
  import CardSection from "@/components/shared/CardSection.vue";
  import PostDonationTransferHistory from '@/components/prototypes/living_donor/organs/_PostDonationTransferHistory.vue';
  import PostDonationHistoryRowsJson from '@/components/prototypes/json/LivingDonors/LivingDonorPostDonationData.json';
  import PostDonationHistoryColumnsJson from '@/components/prototypes/json/LivingDonors/LivingDonorPostDonationColumns.json';
  import PostDonationFollowUp from '@/components/prototypes/living_donor/organs/_PostDonationFollowUp.vue';
  import PostDonationFollowUpRowsJson from '@/components/prototypes/json/LivingDonors/LivingDonorPostDonationFollowUpData.json';
  import PostDonationFollowUpColumnsJson from '@/components/prototypes/json/LivingDonors/LivingDonorPostDonationFollowUpColumns.json';
  import {
    TableConfig
  } from '@/types';
  import {
    Recipient
  } from "@/store/recipients/types";
  import {
    UserDetails
  } from '@/store/userAccounts/types';
  import {
    DateUtilsMixin
  } from "@/mixins/date-utils-mixin";

  interface TableRow {
    id: string;
    category ? : string;

    date ? : string;
    fileName ? : string;

    fileType ? : string;
    description ? : string;
    uploadedBy ? : string;
  }

  @Component({
    components: {
      CardSection,
      PostDonationTransferHistory,
      PostDonationFollowUp
    }
  })
  export default class RecipientDocuments extends mixins(DateUtilsMixin) {

    @State(state => state.recipients.selectedRecipient) recipient!: Recipient;
    @State(state => state.userAccounts.selected) private selected!: UserDetails;
    @State(state => state.pageState.currentPage.journeyStatus) editState!: any;
    @Getter('prototypeFeatureEnabled', {
      namespace: 'features'
    }) private prototypeFeatureEnabled!: (featureName: string) => boolean;
    @Prop({ default: null }) title!: string;
    @Prop({default: ''}) currentHospital!: any;

    getTableData(type: any) {
      switch (type) {
        case 'history':
          return PostDonationHistoryRowsJson;
        case 'follow':
          return PostDonationFollowUpRowsJson;
        default:
          // code block
      }
    }

    getColumns(type: any) {
      switch (type) {
        case 'history':
          return PostDonationHistoryColumnsJson;
        case 'follow':
          return PostDonationFollowUpColumnsJson;

        default:
          // code block
      }
    }
  }

